import { getApi, APIS, postApi, S3_METHODS, uploadFile, putApi } from "../utils/api-utils";
import { getFileExtension } from "../utils/common-utils";
import { ulid } from 'ulid';
import moment from "moment";

export const uploadImage = async (image: File, spaceId: string, isThumbnail: boolean) => {
    const ext = getFileExtension(image.name);
    let fileName = isThumbnail ? `thumbnail.${ext}` : `${ulid()}.${ext}`
    const key = `${spaceId}/images/${fileName}`;
    const url = await getSignedUrl(key, image.type, S3_METHODS.PUT, 900);
    await uploadFile(url, image, image.type);
    return fileName;
}

export const getSignedUrl = async (key: string, contentType: string | undefined, method: string, expires: number) => {
    const params = {
        key: key,
        contentType: contentType,
        method: method,
        expires: expires
    }
    const { url } = await getApi(APIS.BUILDING_SPACES_API, '/signedurl', { queryStringParameters: params });
    return url;
}

export const createSpace = async (spaceData: Object) => {
    return await postApi(APIS.BUILDING_SPACES_API, '/space', spaceData);
}

export const updateSpace = async (spaceData: Object) => {
    return await putApi(APIS.BUILDING_SPACES_API, '/space', spaceData);
}

export const getSpaceList = async (buildingId: string) => {
    let from = moment().startOf('day');
    const spaceList = await getApi(APIS.BUILDING_SPACES_API, `/spaces/${buildingId}`, { queryStringParameters: { forAdmin: true, availabilityFrom: from.toISOString(), availabilityTo: from.add(1, 'day').startOf('day').toISOString() } });
    return spaceList;
}

export const getBookingRequests = async (buildingId: string, bookingStatus: string, isHistory?: boolean, from?: any, to?: any) => {
    let params: any = {};
    if (from) params.from = from.toISOString();
    if (to) params.to = to.endOf('day').toISOString();
    if (isHistory) params.isHistory = true;
    const requestList = await getApi(APIS.BUILDING_SPACES_API, `/booking-requests/${buildingId}/${bookingStatus}`, { queryStringParameters: params });
    return requestList;
}

export const getSpaceBookingRequests = async (buildingId: string, spaceId: string, forCalendarView: boolean = false) => {
    return await getApi(APIS.BUILDING_SPACES_API, `/space-booking-requests/${buildingId}/${spaceId}`, { queryStringParameters: { forCalendarView } });
}

export const approveRequest = async (buildingId: string, requestId?: string, requestIds?: any) => {
    const params = {
        buildingId: buildingId,
        requestId: requestId,
        requestIds: requestIds
    }
    const res = await postApi(APIS.BUILDING_SPACES_API, '/approve-booking-request', params);
    return res;
}

export const declineRequest = async (buildingId: string, reason: string, requestId?: string, requestIds?: any) => {
    const params = {
        buildingId: buildingId,
        requestId: requestId,
        declineReason: reason,
        requestIds:requestIds
    }
    const res = await postApi(APIS.BUILDING_SPACES_API, '/decline-booking-request', params);
    return res;
}

export const getMaskedBookingRequests = async (buildingId: string, spaceId: string) => {
    const requestList = await getApi(APIS.BUILDING_SPACES_API, `/masked-booking-requests/${buildingId}/${spaceId}`);
    return requestList;
}

export const addBookingRequest = async (bookingRequest: any) => {
    const res = await postApi(APIS.BUILDING_SPACES_API, '/booking-request', bookingRequest);
    return res;
}

export const getSpace = async (buildingId: string, spaceId: string) => {
    const space = await getApi(APIS.BUILDING_SPACES_API, `/space/${buildingId}/${spaceId}`);
    return space;
}

export const deleteImage = async (spaceId: string, fileName: string) => {
    const res = await putApi(APIS.BUILDING_SPACES_API, '/delete-img', { spaceId, fileName });
    return res;
}

export const deleteSpace = async (buildingId: string, spaceId: string) => {
    return await putApi(APIS.BUILDING_SPACES_API, '/delete-space', { spaceId, buildingId });
}