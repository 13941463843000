import { useContext, useState } from "react";
import { Row, Button, Card, Modal, Input, Radio, Space } from 'antd';
import { notifiableAPICall, notifyWarning } from "../utils/notification";
import { declineRequest } from "./actions";
import { RECURRENCES, RECURRING_APPROVES } from "../bookingSpace/constants";
import { sessionStore, SESSION_ATTRS } from '../store/session';
const { TextArea } = Input;

export const DeclinePopup = ({ isVisible, onCancel, request, onOk  }: any) => {
    const [declineReason, setDeclineReason] = useState<string>("");
    const isOneTime = !request.recurrence || request.recurrence === RECURRENCES.ONETIME;
    const [recurringApproveType, setRecurringApproveType] = useState(RECURRING_APPROVES.THIS);
    const { state } = useContext(sessionStore);
    const user: any = state.get(SESSION_ATTRS.LOGGED_USER);

    const declineReq = async () => {
        if (!declineReason) {
            notifyWarning("Decline Reason can't be empty.")
            return;
        }
        if (isOneTime) {
            await notifiableAPICall(() => declineRequest(user.buildingId, declineReason, request?.requestId),
                'decline_req',
                "Please wait...",
                "Space Booking Request Declined.",
                "Something went wrong.",
                true);
        } else {
            let requestIds: Array<string> = [];
            switch (recurringApproveType) {
                case RECURRING_APPROVES.THIS:
                    requestIds.push(request.requestId);
                    break;
                case RECURRING_APPROVES.UPCOMING:
                    let reqIndex = request.requestIds.findIndex((reqId: string) => reqId === request.requestId)
                    requestIds = request.requestIds.splice(reqIndex);
                    break;
                case RECURRING_APPROVES.ALL:
                    requestIds = request.requestIds;
                    break;
            }
            await notifiableAPICall(() => declineRequest(user.buildingId, declineReason, undefined, requestIds),
                'decline_req',
                "Please wait...",
                "Desk Booking Request(s) Declined.",
                "Something went wrong.",
                true);
        }
        onOk();
    }
    return (
        <Modal
            visible={isVisible}
            footer={null}
            onCancel={onCancel}
            closable={false}
            width={460}
            centered
            maskStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
            maskClosable={false}
        >
            <Card bordered={false} bodyStyle={{ paddingBottom: 0 }}>
                <Row className='bs-dcln-rw'>
                    <h2 className='bs-dcln-mdl-ttl'>Reason for decline booking</h2>
                </Row>
                {!isOneTime && <Row>
                    <Radio.Group
                        onChange={e => setRecurringApproveType(e.target.value)} value={recurringApproveType}
                    >
                        <Space direction="vertical">
                            <Radio value={RECURRING_APPROVES.THIS}>This event</Radio>
                            <Radio value={RECURRING_APPROVES.UPCOMING}>This and following events</Radio>
                            <Radio value={RECURRING_APPROVES.ALL}>All events</Radio>
                        </Space>
                    </Radio.Group>
                </Row>}
                <br />
                <Row>
                    <TextArea
                        onChange={txt => setDeclineReason(txt.target.value)}
                        value={declineReason}
                        rows={6}
                        placeholder="Eg: Clean floor for New year Eve"
                        className='bs-dcln-txt'
                    />
                </Row>
                <Row className='bs-dcln-btn-rw'>
                    <div>
                        <Button className='btn btn--o-primary' onClick={onCancel}>Cancel</Button>
                        <Button className='btn btn--primary' onClick={async () => await declineReq()}>Confirm</Button>
                    </div>
                </Row>
            </Card>
        </Modal>
    )
}