import { Button, Modal, Radio, Row, Space } from 'antd';
import { useContext, useState } from 'react';
import { notifiableAPICall } from '../utils/notification';
import { RECURRING_APPROVES } from './constants';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { approveRequest as deskBookingApprove } from '../deskBooking/actions';
import { approveRequest } from './actions';

export const RecurringApprovalModal = ({ onClose, request, onOk, isDeskBooking }: any) => {
    const [recurringApproveType, setRecurringApproveType] = useState(RECURRING_APPROVES.THIS);
    const { state } = useContext(sessionStore);
    const user: any = state.get(SESSION_ATTRS.LOGGED_USER);
    const onApprove = async () => {
        let requestIds: Array<string> = [];
        switch (recurringApproveType) {
            case RECURRING_APPROVES.THIS:
                requestIds.push(request.requestId);
                break;
            case RECURRING_APPROVES.UPCOMING:
                let reqIndex = request.requestIds.findIndex((reqId: string) => reqId === request.requestId)
                requestIds = request.requestIds.splice(reqIndex);
                break;
            case RECURRING_APPROVES.ALL:
                requestIds = request.requestIds;
                break;
        }
        await notifiableAPICall(() => isDeskBooking ? deskBookingApprove(user.buildingId, undefined, requestIds) : approveRequest(user.buildingId, undefined, requestIds),
            'approve_req',
            "Please wait...",
            `${isDeskBooking ? 'Desk' : 'Space'} Booking Request(s) Approved.`,
            "Something went wrong.",
            true);
        onOk();
    };

    return (
        <Modal
            visible={true}
            onCancel={onClose}
            maskClosable={false}
            title="Approve Recurring Booking"
            okText="Approve"
            footer={null}
            closable={false}
            width={460}
            centered
            maskStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
        >
            Are you sure you want to approve the booking(s)?<br /><br />
            <Radio.Group
                onChange={e => setRecurringApproveType(e.target.value)} value={recurringApproveType}
            >
                <Space direction="vertical">
                    <Radio value={RECURRING_APPROVES.THIS}>This event</Radio>
                    <Radio value={RECURRING_APPROVES.UPCOMING}>This and following events</Radio>
                    <Radio value={RECURRING_APPROVES.ALL}>All events</Radio>
                </Space>
            </Radio.Group>
            <Row className='bs-dcln-btn-rw'>
                <div>
                    <Button className='btn btn--o-primary' onClick={onClose}>Cancel</Button>
                    <Button className='btn btn--primary' onClick={async () => await onApprove()}>Approve</Button>
                </div>
            </Row>
        </Modal >
    );
};