import React, { useContext, useEffect, useState } from 'react';
import './App.scss';
import { Spin } from 'antd';
import { Switch, Route, Redirect, BrowserRouter as Router } from "react-router-dom";
import { LoginPage } from './login/LoginPage';
import { DashBoardPage } from './dashboard/DashBoardPage';
import { sessionStore } from './store/session';
import { isSessionAuthenticated } from './login/actions';

const App = () => {
  const [initialDataLoading, setInitialDataLoading] = useState(true);
  const [isAuthenticatedUser, setIsAuthenticatedUser] = useState(false);
  const { dispatch } = useContext(sessionStore);

  useEffect(() => {
    setInitialDataLoading(true);
    isSessionAuthenticated(dispatch).then(authenticated => {
      setIsAuthenticatedUser(authenticated);
      setInitialDataLoading(false);
    });
  }, [dispatch]);

  if (initialDataLoading) {
    return <div className="loading-wrapper"><Spin tip="Loading..." /></div>
  }

  return (
    <Router>
      <Switch>
        {!isAuthenticatedUser && <Route exact path="/login"> <LoginPage setIsAuthenticatedUser={setIsAuthenticatedUser} /> </Route>}
		{!isAuthenticatedUser && <Redirect to="/login" />}
        {isAuthenticatedUser && <Route path="/:mainMenu/:subMenu/:first_param/:second_param"> <DashBoardPage /> </Route>}
        {isAuthenticatedUser && <Route path="/:mainMenu/:subMenu"> <DashBoardPage /> </Route>}
        {isAuthenticatedUser && <Route path="/:mainMenu"> <DashBoardPage /> </Route>}
        <Redirect from="/" to="/users" />
        <Redirect to={{ pathname: "/login" }}/>
      </Switch>
    </Router>
  );
}

export default App;
