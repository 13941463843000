import { BarChart, Bar, PieChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Pie, Cell, ResponsiveContainer } from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10, } from 'd3-scale-chromatic';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row, Card, Select } from 'antd';

const MONTHLY = 'monthly';
const DAILY = 'daily';

const colors = scaleOrdinal(schemeCategory10).range();

export const DeskAnalyticsView = ({analyticsDateRange, deskBookings, floorList}: any) => {
    const [barChartData, setBarChartData] = useState<any>([]);
    const [bookingsByFloorChartData, setBookingsFloorChartData] = useState<any>([]);
    const [bookingsByDeskChartData, setBookingsDeskChartData] = useState<any>([]);
    const [pieChartData, setPieChartData] = useState<any>([]);
    const [bookingsChart, setBookingChartData] = useState<string>(MONTHLY);

    useEffect(() => {
      if (deskBookings.length) {
          const from = analyticsDateRange[0];
          const to = analyticsDateRange[1];
          let barChartData: any = [];
          let floorBookingsData: any = [];
          let deskBookingsData: any = [];
          let pcData: any = [];
          let processingDt = from.clone();

          const filteredBooings = deskBookings.filter((i: any) => moment(i.createdAt).isAfter(from.startOf('day')) && moment(i.createdAt).isBefore(to.endOf('day')));
          if (bookingsChart === MONTHLY) {
              while (!processingDt.isSame(to, 'month')) {
                  processMonthBookings(processingDt, barChartData, filteredBooings);
                  processingDt.set('month', (processingDt.month() + 1));
              };
              processMonthBookings(to, barChartData, filteredBooings);
          } else if (bookingsChart === DAILY) {
              while (!processingDt.isSame(to, 'day')) {
                  processDayBookings(processingDt, barChartData, filteredBooings);
                  processingDt.add(1, 'day');
              };
              processDayBookings(to, barChartData, filteredBooings);
          }

          // set data for bookings status chart
          filteredBooings.forEach((booking: any) => {
              let pcIndex = pcData.findIndex((item: any) => item.name === booking.statusName);
              if (pcIndex >= 0) {
                  pcData[pcIndex].value++;
              } else {
                  pcData.push({ name: booking.statusName, value: 1 })
              }
          });

          // set data for bookings by floor
          filteredBooings.forEach((booking: any) => {
            const category = floorBookingsData.filter((bk:any) => bk.category === booking.floorName);
            if (!category.length) {
              floorBookingsData.push({'category': booking.floorName, 'Booking count': 1});
            } else {
              category[0]['Booking count']++;
            }
          });

          // set data for bookings by desk
          filteredBooings.forEach((booking: any) => {
            const category = deskBookingsData.filter((bk:any) => bk.category === booking.deskName);
            if (!category.length) {
                deskBookingsData.push({'category': booking.deskName, 'Booking count': 1});
            } else {
              category[0]['Booking count']++;
            }
          });

          setBarChartData(barChartData);
          setPieChartData(pcData);
          setBookingsFloorChartData(floorBookingsData);
          setBookingsDeskChartData(deskBookingsData);
      }
  }, [analyticsDateRange, bookingsChart, deskBookings]);

  const processMonthBookings = (processingDt: any, barChartData: any, deskBookings: any) => {
    let length = barChartData.push({ name: processingDt.format('MMM YYYY') });
    let index = length - 1;
    let monthIssues = deskBookings.filter((issue: any) => moment(issue.createdAt).isSame(processingDt, 'month'));
    monthIssues.forEach((booking: any) => {
        if (!barChartData[index][booking.floorName]) {
            barChartData[index][booking.floorName] = 1;
        } else {
            barChartData[index][booking.floorName]++;
        }
    });
  }

  const processDayBookings = (processingDt: any, barChartData: any, deskBookings: any) => {
      let length = barChartData.push({ name: processingDt.format('MMM DD') });
      let index = length - 1;
      let monthIssues = deskBookings.filter((issue: any) => moment(issue.createdAt).isSame(processingDt, 'day'));
      monthIssues.forEach((booking: any) => {
          if (!barChartData[index][booking.floorName]) {
              barChartData[index][booking.floorName] = 1;
          } else {
              barChartData[index][booking.floorName]++;
          }
      });
  }

  const viewCharts = () => {
      return (
        <div>
            <Row>
                <Col span={11}>
                    <Card title="Bookings by status" style={{ paddingBottom: '20px' }}>
                        <div style={{ width: '100%', height: '50vh', paddingBottom: '30px' }}>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie dataKey="value" isAnimationActive={false} data={pieChartData} label>
                                        {pieChartData.map((entry: any, index: number) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Card>
                </Col>
                <Col span={12} offset={1}>
                    <Card title="Desk bookings" style={{ paddingBottom: '20px' }}
                        extra={<Select value={bookingsChart} onChange={setBookingChartData}>
                            <Select.Option value={MONTHLY}>Monthly</Select.Option>
                            <Select.Option value={DAILY}>Daily</Select.Option>
                        </Select>}>
                        <div style={{ width: '100%', height: '50vh', paddingBottom: '30px' }}>
                            <ResponsiveContainer>
                                <BarChart
                                    data={barChartData}
                                    margin={{ bottom: 5}}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis allowDecimals={false} />
                                    <Tooltip />
                                    <Legend style={{marginTop:20}}/>
                                    {floorList?.map(({ name }: any, index: number) => <Bar dataKey={name} fill={colors[index % colors.length]} stackId="a" />)}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Card>
                </Col>
            </Row >
            <Row>
              <Col span={11}>
                <Card title="Bookings by floor" style={{ paddingBottom: '20px' }}>
                    <div style={{ width: '100%', height: '50vh', paddingBottom: '30px' }}>
                        <ResponsiveContainer>
                            <BarChart
                                data={bookingsByFloorChartData}
                                margin={{ bottom: 50, }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="category" angle={-45} textAnchor="end" tick={{fontSize: 10}} interval={0}/>
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                                <Legend wrapperStyle={{ position: 'unset' }} />
                                <Bar dataKey="Booking count" fill="#82ca9d" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
              </Col>
              <Col span={11} offset={1}>
                <Card title="Bookings by desk" style={{ paddingBottom: '20px' }}>
                    <div style={{ width: '100%', height: '50vh', paddingBottom: '30px' }}>
                        <ResponsiveContainer>
                            <BarChart
                                data={bookingsByDeskChartData}
                                margin={{ bottom: 50 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="category" angle={-45} textAnchor="end" tick={{fontSize: 10}} interval={0}/>
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                                <Legend wrapperStyle={{ position: 'unset' }} />
                                <Bar dataKey="Booking count" fill="#82ca9d" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
              </Col>
            </Row>
        </div>
      )
  }

  return (
      <div>
          {deskBookings.length ? viewCharts() : 
          <Row style = {{display: 'inline-flex'}}>
            No data
        </Row>}
      </div>
  )
};