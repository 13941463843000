import ReactQuill, { Quill } from 'react-quill';

var Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

type Props = {
    content: any;
    setContent: any
}

export const HtmlEditor = ({ content, setContent }: Props) => {
    return <ReactQuill
        theme="snow"
        defaultValue={content}
        onChange={setContent}
        style={{ background: 'white', height: "500px" }}
        modules={{
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['clean']
            ],
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            }
        }}
        formats={[
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
        ]}
        placeholder="Type your content here"
    />
}