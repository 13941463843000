export const INDUCTION_STATUS_TYPE = {
    PUBLISH: 1,
    DRAFT: 0,
    TRASH: -1
}

export const DISCARD_TYPE = {
    ALL: 'all',
    OBJECT: 'object'
}

export const INDUCTION_MODAL_TYPE = {
    CREATE: 'create',
    EDIT: 'edit'
}

export const INDUCTION_STATUS_ARRAY = [
    { name: "Post", state: INDUCTION_STATUS_TYPE.PUBLISH },
    { name: "Draft", state: INDUCTION_STATUS_TYPE.DRAFT },
    { name: "Trash", state: INDUCTION_STATUS_TYPE.TRASH }
];

export const VIDEO_EXTENTION_STRING = 'mp4_mov_avi';
export const IMAGE_EXTENTION_STRING = 'jpg_png_jpeg';
export const CSV_EXTENTION_STRING = 'csv';