import { useContext, useEffect, useState } from 'react';

import { Layout, Row, Select, Space, Input, Button, Table, Typography, Dropdown, Menu, Radio, DatePicker } from 'antd';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { IssueModal } from './IssueModal/IssueModal';
import { CategoriesModal } from './CategoriesModal';
import { StatusListModal } from './StatusListModal';
import { IssueDetailsModal } from './IssueDetailsModal';
import { Link } from 'react-router-dom';
import { getCategories, getIssues, getStatusList } from './actions';
import { toDateString } from '../utils/date-time';
import { getBuildingTenants, numberSorter } from '../utils/common-utils';
import { ExportOutlined } from '@ant-design/icons';
import { Parser } from 'json2csv';
import download from 'downloadjs';
import { VIEWS } from './constants';
import { AnalyticsView, CUSTOM, LAST30DAYS, LAST60DAYS } from './AnalyticsView';
import moment from 'moment';

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;
const { Title } = Typography;

export const ALL = "all";

export const IssuesView = () => {
    const [addNewIssue, setAddNewIssue] = useState<Boolean>(false);
    const [categoryModalVisible, setCategoryModalVisible] = useState<Boolean>(false);
    const [statusListModalVisible, setStatusListModalVisible] = useState<Boolean>(false);
    const [detailIssue, setDetailIssue] = useState<Boolean>(false);
    const [issues, setIssues] = useState<any[]>();
    const [statusList, setStatusList] = useState<any[]>();
    const [categories, setCategories] = useState<any[]>();
    const [selectedView, setSelectedView] = useState(VIEWS.ISSUES);

    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const tenants: any = state.get(SESSION_ATTRS.TENANTS)
    const buildingIdNameMapping: any = state.get(SESSION_ATTRS.BUILDING_ID_NAMES);

    const [selectedBuildingId, setSelectedBuildingId] = useState(ALL);
    const [selectedTenantId, setSelectedTenantId] = useState(ALL);
    const [selectedStatus, setSelectedStatus] = useState<string>();
    const [filteredList, setFilteredList] = useState<Array<any>>([]);
    const [searchText, setSearchText] = useState<string>();
    const [analyticsRange, setAnalyticsRange] = useState<string>(LAST30DAYS);
    const [analyticsDateRange, setAnalyticsDateRange] = useState<any>([moment().subtract(30, 'days'), moment()]);

    useEffect(() => {
        let buildingId = selectedBuildingId === ALL ? buildings[0].buildingId : selectedBuildingId;
        getIssues(buildingId).then(setIssues);
        loadStatusList();
        loadCategoryList();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBuildingId]);

    useEffect(() => {
        if (statusList?.length) {
            setSelectedStatus(statusList.filter(({ isArchived }) => !isArchived)[0].statusId);
        }
    }, [statusList]);

    useEffect(() => {
        if (analyticsRange === LAST30DAYS) {
            setAnalyticsDateRange([moment().subtract(30, 'days'), moment()]);
        } else if (analyticsRange === LAST60DAYS) {
            setAnalyticsDateRange([moment().subtract(60, 'days'), moment()]);
        }
    }, [analyticsRange]);

    useEffect(() => {
        if (selectedStatus && issues && selectedTenantId) {
            let fList = issues.filter(i => i.currentStatus === selectedStatus && (selectedTenantId === ALL || i.tenant === selectedTenantId))
            if (searchText) {
                fList = fList.filter(i => `${i.description}_${i.createdBy.name}`.toLowerCase().includes(searchText.trim().toLowerCase()));
            }
            setFilteredList(fList);
        }
    }, [selectedStatus, issues, searchText, selectedTenantId]);

    const loadStatusList = () => {
        let buildingId = selectedBuildingId === ALL ? buildings[0].buildingId : selectedBuildingId;
        getStatusList(buildingId).then(lst => {
            lst.sort((s1: any, s2: any) => numberSorter(s1.index, s2.index));
            setStatusList(lst);
        });
    }

    const loadCategoryList = () => {
        let buildingId = selectedBuildingId === ALL ? buildings[0].buildingId : selectedBuildingId;
        getCategories(buildingId).then(setCategories);
    }

    const exportToCsv = () => {
        try {
            const parser = new Parser({ fields: ['Building', 'Tenant', 'Raised By', 'Created At', 'Category', 'Description', 'Notes', 'Status'] });
            const exportableIssues: Array<any> = (issues || []).map(item => ({
                Building: buildings.find((b: any) => b.buildingId === item.buildingId)?.name,
                Tenant: tenants.find((b: any) => b.tenantId === item.tenant)?.name,
                'Raised By': item.createdBy.name,
                'Created At': toDateString(item.createdAt),
                'Category': item.categoryName,
                'Description': item.description,
                'Notes': item.notes,
                'Status': item.currentStatusName,
            }));
            const csv = parser.parse(exportableIssues);
            const file = new Blob([csv], { type: 'text/csv' });
            download(file, 'Issues.csv');
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            {addNewIssue && <IssueModal onClose={() => setAddNewIssue(false)} categories={categories?.filter(({ isArchived }) => !isArchived)}
                onAdd={() => { setAddNewIssue(false); getIssues(selectedBuildingId === ALL ? buildings[0].buildingId : selectedBuildingId).then(setIssues); }} />}
            {categoryModalVisible && <CategoriesModal onClose={() => setCategoryModalVisible(false)} categoryList={categories} onUpdate={() => loadCategoryList()}
                buildingId={selectedBuildingId === ALL ? buildings[0].buildingId : selectedBuildingId} />}
            {statusListModalVisible && <StatusListModal onClose={() => setStatusListModalVisible(false)} statusList={statusList}
                buildingId={selectedBuildingId === ALL ? buildings[0].buildingId : selectedBuildingId}
                onUpdate={() => loadStatusList()} />}
            {detailIssue && <IssueDetailsModal onClose={() => setDetailIssue(false)} issue={detailIssue} statusList={statusList} categories={categories}
                onUpdate={(issueId: String) => getIssues(selectedBuildingId === ALL ? buildings[0].buildingId : selectedBuildingId).then(list => {
                    setIssues(list);
                    setDetailIssue(list.find((i: any) => i.issueId === issueId))
                })} />}
            <Space direction='vertical'>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Row style={{ marginTop: 15, justifyContent: 'space-between' }}>
                        <Title level={4}>
                            Issues
                        </Title>
                        <Button type='link' style={{ color: '#104EA1', fontSize: 12, fontWeight: 600 }} icon={<ExportOutlined style={{ color: '#104EA1', fontWeight: "bold" }} />} onClick={() => exportToCsv()}>Export .csv</Button>
                    </Row>
                    <Row className="grid-options">
                        <Space style={{ marginBottom: 16, flexWrap: "wrap" }}>
                            <Radio.Group value={selectedView} onChange={e => setSelectedView(e.target.value)} className='bs-tab-selector'>
                                <Radio.Button value={VIEWS.ISSUES} className='left-button'>Issues</Radio.Button>
                                <Radio.Button value={VIEWS.ANALYTICS} className='right-button'>Analytics</Radio.Button>
                            </Radio.Group>
                            <Select defaultValue={ALL} onChange={setSelectedBuildingId} style={{ width: 150 }}>
                                <Option value={ALL}>All Buildings</Option>
                                {buildings.map((building: any) => (
                                    <Option key={building.buildingId} value={building.buildingId}>{building.name}</Option>
                                ))}
                            </Select>
                            <Select defaultValue={ALL} onChange={setSelectedTenantId}>
                                <Option key={ALL} value={ALL}>All Tenants</Option>
                                {(selectedBuildingId !== ALL ? getBuildingTenants(tenants, selectedBuildingId) : tenants).map((tenant: any) => (
                                    <Option key={tenant.tenantId} value={tenant.tenantId}>{tenant.name}</Option>
                                ))}
                            </Select>
                            {statusList && selectedView === VIEWS.ISSUES && <Select value={selectedStatus} onChange={(s: string) => setSelectedStatus(s)} style={{ width: 150 }}>
                                <Select.OptGroup label="Active">
                                    {statusList.filter(({ isArchived }) => !isArchived).map((st: any) => <Option value={st.statusId}>{st.statusName}</Option>)}
                                </Select.OptGroup>
                                <Select.OptGroup label="Archived">
                                    {statusList.filter(({ isArchived }) => isArchived).map((st: any) => <Option value={st.statusId}>{st.statusName}</Option>)}
                                </Select.OptGroup>

                            </Select>}
                        </Space>
                        {selectedView === VIEWS.ISSUES && <Space className="grid-options--search">
                            <Search placeholder="Search" allowClear onChange={(e) => setSearchText(e.target.value)} style={{ width: 200 }} />
                            <Button className="btn btn--primary" icon={<PlusOutlined />} onClick={() => setAddNewIssue(true)}>
                                Add New
                            </Button>
                            <Dropdown overlay={<Menu>
                                <Menu.Item onClick={() => setCategoryModalVisible(true)}>Issue Categories</Menu.Item>
                                <Menu.Item onClick={() => setStatusListModalVisible(true)}>Issue Status</Menu.Item>
                            </Menu>}>
                                <Button className="btn btn--primary" icon={<SettingOutlined />} style={{ minWidth: 50 }} />
                            </Dropdown>

                        </Space>}
                        {selectedView === VIEWS.ANALYTICS && <Space className="grid-options--search">
                            <Select style={{ width: 180 }} value={analyticsRange} onChange={setAnalyticsRange}>
                                <Select.Option value={LAST30DAYS}>Last 30 Days</Select.Option>
                                <Select.Option value={LAST60DAYS}>Last 60 Days</Select.Option>
                                <Select.Option value={CUSTOM}>Custom Date Range</Select.Option>
                            </Select>
                            {analyticsRange === CUSTOM && <DatePicker.RangePicker allowClear={false}
                                value={analyticsDateRange} onChange={setAnalyticsDateRange} style={{ width: 250 }} format={'YYYY-MM-DD'} />}
                        </Space>}
                    </Row>
                </Space>
                <Content className="table-wrapper">
                    {selectedView === VIEWS.ISSUES && <Table
                        columns={getColumns(tenants, setDetailIssue, buildingIdNameMapping)}
                        dataSource={filteredList === undefined || categories === undefined || statusList === undefined ? [] : filteredList}
                        loading={filteredList === undefined || categories === undefined || statusList === undefined || selectedStatus === undefined}
                        rowKey='contactId'
                        pagination={{
                            total: filteredList.length,
                            showSizeChanger: true,
                            showTotal: (total, range) => `showing ${range[0]}-${range[1]} out of ${total} contacts`
                        }} />}
                    {selectedView === VIEWS.ANALYTICS && <AnalyticsView
                        categoryList={categories} issues={issues} statusList={statusList} tenantId={selectedTenantId}
                        buildingId={selectedBuildingId === ALL ? buildings[0].buildingId : selectedBuildingId} analyticsDateRange={analyticsDateRange}
                    />}
                </Content>
            </Space>
        </>
    )
};

const getColumns = (tenants: any, setDetailIssue: Function, buildingIdNameMapping: any): Array<any> => {
    return [
        {
            title: 'Description',
            dataIndex: 'description',
            render: (issue: string, item: any) => (
                <div className="bs-title-label">
                    <Row className='bs-rw-sngl'>
                        <p><Link to="#" onClick={() => setDetailIssue(item)}>{issue}</Link></p>
                    </Row>
                </div>),
            sorter: (a: any, b: any) => (a.description || '').localeCompare(b.description || '')
        },
        {
            title: 'Category',
            dataIndex: 'categoryName',
            render: (categoryName: string) => (
                <div className="bs-title-label">
                    <Row className='bs-rw-sngl'>
                        <p>{categoryName}</p>
                    </Row>
                </div>
            ),
            sorter: (a: any, b: any) => (a.categoryName || '').localeCompare(b.categoryName || '')
        },
        {
            title: 'Status',
            dataIndex: 'currentStatusName',
            render: (currentStatusName: string) => (
                <div className="bs-title-label">
                    <Row className='bs-rw-sngl'>
                        <p>{currentStatusName}</p>
                    </Row>
                </div>
            )
        },
        {
            title: 'Raised By',
            dataIndex: 'createdBy',
            render: (createdBy: any, row: any) => (
                <div className="bs-title-label">
                    <Row className='bs-rw-sngl'>
                        <p>{createdBy.name}</p>
                    </Row>
                </div>
            ),
            sorter: (a: any, b: any) => (a.createdBy.name || '').localeCompare(b.createdBy.name || '')
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            render: (createdAt: string) => (
                <div className="bs-title-label">
                    <Row className='bs-rw-sngl'>
                        <p>{toDateString(createdAt)}</p>
                    </Row>
                </div>),
            sorter: (a: any, b: any) => (a.createdAt || '').localeCompare(b.createdAt || '')
        },
        {
            title: 'Building',
            dataIndex: 'buildingId',
            render: (buildingId: string) => (
                <div className="bs-title-label">
                    <Row className='bs-rw-sngl'>
                        <p>{buildingIdNameMapping[buildingId]}</p>
                    </Row>
                </div>),
            sorter: (a: any, b: any) => (buildingIdNameMapping[a.buildingId] || '').localeCompare(buildingIdNameMapping[b.buildingId] || '')
        },
        {
            title: 'Tenant',
            dataIndex: 'tenant',
            render: (tenant: string) => (
                <div className="bs-title-label">
                    <Row className='bs-rw-sngl'>
                        <p>{tenants.find((b: any) => b.tenantId === tenant)?.name}</p>
                    </Row>
                </div>
            ),
            sorter: (a: any, b: any) => (tenants.find((t: any) => t.tenantId === a.tenant)?.name || '').localeCompare(tenants.find((t: any) => t.tenantId === b.tenant)?.name || '')
        }
    ]
};