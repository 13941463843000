import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Layout, Row, Select, Space, Input, Button, Table, Typography, Popover } from 'antd';
import { PlusOutlined, DeleteOutlined, MoreOutlined, FormOutlined, UndoOutlined, UploadOutlined, EditFilled } from '@ant-design/icons';
import { Link } from "react-router-dom";

import { changeState, getNewsList } from './actions';
import { MENU } from '../dashboard/constants';
import { ACTIONS, sessionStore, SESSION_ATTRS } from '../store/session';
import { NEWS_CATEGORIES, NEWS_CATEGORIES_ARRAY, NEWS_FEEDS_STATUS, NEWS_FEEDS_TYPES } from './constants';
import { toDateString } from '../utils/date-time';
import { confirm, notifiableAPICall, notifyConfirmation } from '../utils/notification';
import { getUserObj } from '../utils/common-utils';

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;
const { Title } = Typography;

const ALL = "all";

export const NewsFeedView = () => {
    const [newsList, setNewsList] = useState<Array<any>>([]);
    const [selectedNews, setSelectedNews] = useState<any[]>([]);
    const [searchingPhrase, setSearchingPhrase] = useState<string | undefined>();
    const [selectedCategory, setSelectedCategory] = useState<string>(ALL);
    const [selectedBuilding, setSelectedBuilding] = useState<string>(ALL);
    const [filteredNewsList, setFilteredNewsList] = useState<Array<any>>([]);
    const [isLoading, setLoading] = useState<boolean>(false);

    const { state, dispatch } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const tenantIdNameMapping: any = state.get(SESSION_ATTRS.TENANT_ID_NAMES);
    const buildingIdNameMapping: any = state.get(SESSION_ATTRS.BUILDING_ID_NAMES);
    const user: any = state.get(SESSION_ATTRS.LOGGED_USER);

    const sessionSelectedState: any = state.get(SESSION_ATTRS.SELECTED_NEWSFEED_TYPE);

    const [selectedState, setSelectedState] = useState<number>(sessionSelectedState !== undefined ? sessionSelectedState : NEWS_FEEDS_TYPES.PUBLISH);

    const prevStateRef = useRef<number | undefined>();

    const tenantsArrayFilter = (selectTenants: Array<string> = []) => {
        return selectTenants.map((t: string) => tenantIdNameMapping[t]).filter(t => !!t).join(', ');
    }

    const getBuildingName = (buildingId: string) => {
        return buildingIdNameMapping[buildingId];
    }

    const filterNews = (news: Array<any>, category: string, building: string, searchString: string | undefined) => {
        let filtered = news;
        if (searchString) {
            const search = searchString.toLowerCase();
            filtered = filtered.filter(f => f.title.toLowerCase().includes(search));
        }
        if (category !== ALL) {
            filtered = filtered.filter(f => f.categories.includes(category)); //this will change
        }
        if (building !== ALL) {
            filtered = filtered.filter(f => f.buildingId === building);
        }
        return filtered;
    }

    const loadNewsList = useCallback(async (state: number) => {
        try {
            setLoading(true);
            let nwsList = await getNewsList(state, user.buildingId);
            nwsList.sort((n1: any, n2: any) => (new Date(n2.createdAt)).getTime() - (new Date(n1.createdAt)).getTime());
            setNewsList(nwsList);
            setFilteredNewsList(filterNews(nwsList, selectedCategory, selectedBuilding, searchingPhrase))
            setLoading(false);
        } catch (e) {
            console.error(e);
            setNewsList([]);
            setFilteredNewsList([]);
            setLoading(false);
        }
    }, [selectedCategory, selectedBuilding, searchingPhrase, user]);

    useEffect(() => {
        if (prevStateRef.current !== selectedState) {
            prevStateRef.current = selectedState;
            loadNewsList(selectedState);
        }
    }, [loadNewsList, selectedState]);

    useEffect(() => {
        // setLoading(true);
        setFilteredNewsList(filterNews(newsList, selectedCategory, selectedBuilding, searchingPhrase));
        // setLoading(false);
    }, [searchingPhrase, newsList, selectedCategory, selectedBuilding]);

    const onSearch = (value: string | undefined) => {
        setSearchingPhrase(value);
        setSelectedNews([]);
    };

    const onStateChange = (value: number) => {
        setSelectedState(value);
        setSelectedNews([]);
        dispatch({ type: ACTIONS.SELECTED_NEWSFEED_TYPE_CHANGED, payload: value });
    }

    const onCategoryChange = (value: string) => {
        setSelectedCategory(value);
        if (value !== ALL) setSelectedNews([]);
    }

    const onBuildingChange = (value: string) => {
        setSelectedBuilding(value);
        if (value !== ALL) setSelectedNews([]);
    }

    const statusMapper = (isPublished: boolean) => {
        return isPublished ? <span className="status status--published"> Published</span> : <span className="status status--scheduled"> Scheduled</span>;
    }

    const onConvertToDraft = async (newsId: string, buildingId: string, currState: number) => {
        await notifiableAPICall(async () => {
            await changeState(newsId, buildingId, NEWS_FEEDS_TYPES.DRAFT, currState, getUserObj(user));
            await loadNewsList(selectedState);
        },
            "news-state-change-draft",
            "Please wait...",
            "News was converted to draft successfully.",
            "Something went wrong in converting the news to draft. Please retry."
        );
    }

    const onMoveToTrash = async (newsId: string, buildingId: string, currState: number) => {
        confirm("Move Newsfeed to Trash",
            "Are you sure you want to move this newsfeed to trash?",
            async () => {
                await notifiableAPICall(async () => {
                    await changeState(newsId, buildingId, NEWS_FEEDS_TYPES.TRASH, currState, getUserObj(user));
                    await loadNewsList(selectedState);
                },
                    "news-state-change-trash",
                    "Please wait...",
                    "News was moved to trash successfully.",
                    "Something went wrong in moving the news to trash. Please retry."
                );
            }, () => { });
    }

    const onConvertToPublish = async (newsId: string, buildingId: string, currState: number) => {
        await notifiableAPICall(async () => {
            await changeState(newsId, buildingId, NEWS_FEEDS_TYPES.PUBLISH, currState, getUserObj(user));
            await loadNewsList(selectedState);
        },
            "news-state-change-publish",
            "Please wait...",
            "News was converted to publish successfully.",
            "Something went wrong in converting the news to publish. Please retry."
        );
    }

    const onRestore = async (newsId: string, buildingId: string, prevState: number, state: number) => {
        await notifiableAPICall(async () => {
            await changeState(newsId, buildingId, prevState, state, getUserObj(user));
            await loadNewsList(selectedState);
        },
            "news-state-change-restore",
            "Please wait...",
            "News was restored successfully.",
            "Something went wrong in restoring the news. Please retry."
        );
    }

    const onBulkConvertToDraft = async () => {
        await notifiableAPICall(async () => {
            const proms = selectedNews.map(async ({ newsId, buildingId, state }) =>
                await changeState(newsId, buildingId, NEWS_FEEDS_TYPES.DRAFT, state, getUserObj(user)));
            await Promise.all(proms);
            setSelectedNews([]);
            await loadNewsList(selectedState);
        },
            "news-state-change-draft-bulk",
            "Please wait...",
            "Newsfeeds were converted to draft successfully.",
            "Something went wrong in converting one or more news to draft. Please retry."
        );
    }

    const convertToPublishBulk = async (news: Array<any>) => {
        await notifiableAPICall(async () => {
            const proms = news.map(async ({ newsId, buildingId, state }) =>
                await changeState(newsId, buildingId, NEWS_FEEDS_TYPES.PUBLISH, state, getUserObj(user)));
            await Promise.all(proms);
            setSelectedNews([]);
            await loadNewsList(selectedState);
        },
            "news-state-change-publish-bulk",
            "Please wait...",
            "Newsfeeds were converted to publish successfully.",
            "Something went wrong in converting one or more news to publish. Please retry."
        );
    }

    const onBulkConvertToPublish = async () => {
        const publishableList = selectedNews.filter(i => i.isPublishable);
        if (publishableList.length !== selectedNews.length) {
            notifyConfirmation("There are some news which are not be able to be converted to publish. Do you wish to convert convertiable news to publish?",
                "Yes", () => convertToPublishBulk(publishableList), "No");
        } else {
            await convertToPublishBulk(publishableList);
        }
    }

    const onBulkMoveToTrash = async () => {
        confirm("Move Newsfeeds to Trash",
            "Are you sure you want to move selected newsfeeds to trash?",
            async () => {
                await notifiableAPICall(async () => {
                    const proms = selectedNews.map(async ({ newsId, buildingId, state }) =>
                        await changeState(newsId, buildingId, NEWS_FEEDS_TYPES.TRASH, state, getUserObj(user)));
                    await Promise.all(proms);
                    setSelectedNews([]);
                    await loadNewsList(selectedState);
                },
                    "news-state-change-trash-bulk",
                    "Please wait...",
                    "Newsfeeds were moved to trash successfully.",
                    "Something went wrong in moving one or more news to trash. Please retry."
                );
            }, () => { });
    }

    const restoreBulk = async (news: Array<any>) => {
        await notifiableAPICall(async () => {
            const proms = news.map(async ({ newsId, buildingId, state, prevState }) =>
                await changeState(newsId, buildingId, prevState, state, getUserObj(user)));
            await Promise.all(proms);
            setSelectedNews([]);
            await loadNewsList(selectedState);
        },
            "news-state-change-restore-bulk",
            "Please wait...",
            "Newsfeeds were restored successfully.",
            "Something went wrong in restoring one or more news. Please retry."
        );
    }

    const onBulkRestore = async () => {
        const restorableList = selectedNews.filter(i => i.prevState !== NEWS_FEEDS_TYPES.PUBLISH || i.isPublishable);
        if (restorableList.length !== selectedNews.length) {
            notifyConfirmation("There are some news which are not be able to be restored. Do you wish to restore restorable news",
                "Yes", () => restoreBulk(restorableList), "No");
        } else {
            await restoreBulk(restorableList);
        }
    }

    const isInBulkArray = (news: any) => {
        return selectedNews.includes(news);
    }

    const rowActions = (newsId: string, row: any) => {
        let content;
        switch (selectedState) {
            case NEWS_FEEDS_TYPES.PUBLISH:
                content = (
                    <>
                        <Link to={`/${MENU.NEWS_FEED.ROOT}/${MENU.NEWS_FEED.SUB.EDIT}/${row.buildingId}/${newsId}`} className='intnnt-ppvr-btn'><Button className='intnnt-ppvr-btn' type='text' icon={<EditFilled />}>Edit</Button></Link>
                        <Button className='intnnt-ppvr-btn' type='text' icon={<FormOutlined />} onClick={() => onConvertToDraft(newsId, row.buildingId, row.state)}>Convert to draft</Button>
                        <Button className='intnnt-ppvr-btn' type='text' danger icon={<DeleteOutlined />} onClick={() => onMoveToTrash(newsId, row.buildingId, row.state)}>Move to trash</Button>
                    </>
                );
                break;
            case NEWS_FEEDS_TYPES.DRAFT:
                content = (
                    <>
                        <Link to={`/${MENU.NEWS_FEED.ROOT}/${MENU.NEWS_FEED.SUB.EDIT}/${row.buildingId}/${newsId}`} className='intnnt-ppvr-btn'><Button className='intnnt-ppvr-btn' type='text' icon={<EditFilled />}>Edit</Button></Link>
                        <Button className='intnnt-ppvr-btn' type='text' disabled={!row.isPublishable} icon={<FormOutlined />} onClick={() => onConvertToPublish(newsId, row.buildingId, row.state)}>Convert to publish</Button>
                        <Button className='intnnt-ppvr-btn' type='text' danger icon={<DeleteOutlined />} onClick={() => onMoveToTrash(newsId, row.buildingId, row.state)}>Move to trash</Button>
                    </>
                );
                break;
            case NEWS_FEEDS_TYPES.TRASH:
                content = (
                    <>
                        <Button className='intnnt-ppvr-btn' type='text' disabled={row.prevState === NEWS_FEEDS_TYPES.PUBLISH && !row.isPublishable} icon={<UndoOutlined />} onClick={() => onRestore(newsId, row.buildingId, row.prevState, row.state)}>Restore</Button>
                    </>
                );
                break;
            default:
                content = null;
        }

        return (
            <div className='intnnt-ppvr'>
                {content}
            </div>
        )
    };

    const moreAction = (newsId: string, row: any) => isInBulkArray(row) ?
        <div className="ind-more"><MoreOutlined style={{ color: '#cecece' }} className="more-settings" /></div>
        :
        <Popover trigger='hover' placement='left' content={rowActions(newsId, row)}>
            <div className="ind-more"><MoreOutlined className="more-settings" /></div>
        </Popover>

    const columns: Array<any> = [
        {
            title: 'Title',
            dataIndex: 'title',
            sorter: (a: any, b: any) => (a.title || '').localeCompare(b.title || ''),
            render: (title: string, row: any) => (<div className="news-feed-title-wrapper">
                {row.state === NEWS_FEEDS_TYPES.DRAFT && <span className="news-feed-title-draft">[Draft]</span>}
                <span className="news-feed-title">{title}</span>
            </div>)
        },
        {
            title: 'Author',
            dataIndex: 'createdBy',
            sorter: (a: any, b: any) => (a.createdBy.name || '').localeCompare(b.createdBy.name || ''),
            render: (userId: any, row: any) => ((userId.name) ? userId.name : userId)
        },
        {
            title: 'Category',
            dataIndex: 'categories',
            sorter: (a: any, b: any) => (a.categories.length > 0 && b.categories.length > 0) ? (NEWS_CATEGORIES[a.categories[0]]).localeCompare(NEWS_CATEGORIES[b.categories[0]]) : 0,
            render: (categories: Array<any>) => NEWS_CATEGORIES[categories[0]]
        },
        {
            title: 'Tenants',
            dataIndex: 'tenants',
            sorter: (a: any, b: any) => (tenantsArrayFilter(a.tenants)).localeCompare(tenantsArrayFilter(b.tenants)),
            render: (tenants: Array<string>) => <span className="tenant-col">{tenantsArrayFilter(tenants)}</span>,
        },
        {
            title: 'Building',
            dataIndex: 'buildingId',
            sorter: (a: any, b: any) => (getBuildingName(a.buildingId)).localeCompare(getBuildingName(b.buildingId)),
            render: (buildingId: string) => getBuildingName(buildingId),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            render: (createdAt: string) => <span>{toDateString(createdAt)}</span>
        },
        {
            title: '',
            dataIndex: 'newsId',
            key: 'newsId',
            fixed: 'right',
            render: ((newsId: string, row: any) => (
                moreAction(newsId, row)
            ))
        }
    ];
    if (selectedState === NEWS_FEEDS_TYPES.PUBLISH) {
        columns.splice(5, 0, {
            title: 'Status',
            dataIndex: 'isPublished',
            sorter: (a: any, b: any) => a.isPublished === b.isPublished ? 0 : (a.isPublished) ? 1 : -1,
            render: (isPublished: boolean) => statusMapper(isPublished),
        });
    }

    const rowSelection = {
        selectedRowKeys: selectedNews.map(i => i.newsId),
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectedNews(selectedRows);
        }
    };

    const tableHeader = () => {
        if (selectedNews.length === 0) return null;
        let content = null;
        switch (selectedState) {
            case NEWS_FEEDS_TYPES.PUBLISH:
                content = (<>
                    <Button type="text" className="link-btn link-btn--default" icon={<FormOutlined />} onClick={onBulkConvertToDraft}>Convert to Draft</Button>
                    <Button type="text" className="link-btn link-btn--danger" danger icon={<DeleteOutlined />} onClick={onBulkMoveToTrash}>Move to Trash</Button>
                </>);
                break;
            case NEWS_FEEDS_TYPES.DRAFT:
                content = (<>
                    <Button type="text" className="link-btn link-btn--default" icon={<UploadOutlined />} onClick={onBulkConvertToPublish}>Publish</Button>
                    <Button type="text" className="link-btn link-btn--danger" danger icon={<DeleteOutlined />} onClick={onBulkMoveToTrash}>Move to Trash</Button>
                </>);
                break;

            case NEWS_FEEDS_TYPES.TRASH:
                content = (<>
                    <Button type="text" className="link-btn link-btn--default" icon={<UndoOutlined />} onClick={onBulkRestore}>Restore</Button>
                </>);
                break;
            default:
                content = null;
        }
        return (<Space>
            <span className="select-text">{selectedNews.length} selected</span>
            <span className="divider-vertical"></span>
            {content}
        </Space>);
    }

    return (
        <Space direction='vertical'>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Row style={{ marginTop: 15 }}>
                    <Title level={4}>
                        Newsfeed
                    </Title>
                </Row>
                <Row className="grid-options">
                    <Space>
                        <Select defaultValue={NEWS_FEEDS_TYPES.PUBLISH} value={selectedState} onChange={onStateChange} >
                            {NEWS_FEEDS_STATUS.map(({ name, state }) => <Option key={state} value={state}>{name}</Option>)}
                        </Select>
                        <Select defaultValue={ALL} onChange={onCategoryChange}>
                            <Option value={ALL}>All Categories</Option>
                            {NEWS_CATEGORIES_ARRAY.map(category => <Option key={category.value} value={category.value}>{category.name}</Option>)}
                        </Select>
                        <Select defaultValue={ALL} onChange={onBuildingChange} >
                            <Option value={ALL}>All Buildings</Option>
                            {buildings.map((building: any) => (
                                <Option key={building.buildingId} value={building.buildingId}>{building.name}</Option>
                            ))}
                        </Select>
                    </Space>
                    <Space className="grid-options--search">
                        <Search placeholder="Search" allowClear onChange={(e) => onSearch(e.target.value)} className="grid-search-box" />
                        <Link to={`/${MENU.NEWS_FEED.ROOT}/${MENU.NEWS_FEED.SUB.ADD_NEW}`}>
                            <Button
                                className="btn btn--primary"
                                icon={<PlusOutlined />}
                            >
                                Add New
                        </Button>
                        </Link>
                    </Space>
                </Row>
            </Space>
            <Content className="table-wrapper">
                <Table rowSelection={rowSelection}
                    columns={columns}
                    dataSource={filteredNewsList}
                    loading={isLoading}
                    rowKey='newsId'
                    title={tableHeader}
                    pagination={{
                        total: filteredNewsList.length,
                        showSizeChanger: true,
                        showTotal: (total, range) => `showing ${range[0]}-${range[1]} out of ${total} news`
                    }} />
            </Content>
        </Space>
    )
};
