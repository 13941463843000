import { DateTime } from "luxon";

export const DATE_TIME_DISPLAY_FORMAT = 'hh:mm A - MMM DD, YYYY';

export const toDateString = (dateString: string) => DateTime.fromISO(dateString).toFormat('hh:mm a - LLL dd, yyyy');
export const toDateOnlyString = (dateString: string) => DateTime.fromISO(dateString).toFormat('LLL dd, yyyy');

export const nth = (date: number) => {
    if (date > 3 && date < 21) return 'th';
    switch (date % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}
