import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Layout, Row, Select, Space, Input, Button, Table, Typography, Popover } from 'antd';
import { UserDeleteOutlined, UserAddOutlined, PlusOutlined, EditFilled, MoreOutlined, ExportOutlined } from '@ant-design/icons';
import download from 'downloadjs';

import { getBuildingContacts, addBuildingContact, changeState } from './actions';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { BUILDING_CONTACTS_MODEL_TYPE, BUILDING_CONTACT_MODAL_TYPE, BUILDING_CONTACT_STATE, BUILDING_CONTACT_STATUS, CSV_EXPORT_FIELDS } from './constants';
import { toDateString } from '../utils/date-time';
import { confirm, notifiableAPICall } from '../utils/notification';
import BuildingContactModal from './BuildingContactModal';
import Icon from '../components/Icon';

const { Parser } = require('json2csv');

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;
const { Title } = Typography;

const ALL = "all";

export const BuildingContactsView = () => {

    const [buildingContacts, setBuildingContacts] = useState<Array<any>>([]);
    const [selectedBuildingContacts, setSelectedBuildingContacts] = useState<any[]>([]);
    const [searchingPhrase, setSearchingPhrase] = useState<string | undefined>();
    const [contactModalVisible, setContactModalVisible] = useState<boolean>(false);
    const [selectedState, setSelectedState] = useState<number>(BUILDING_CONTACT_STATE.ACTIVE);
    const [selectedRole, setSelectedRole] = useState<string>(ALL);
    const [selectedBuilding, setSelectedBuilding] = useState<string>(ALL);
    const [filteredBuildingContactList, setFilteredBuildingContactList] = useState<Array<any>>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [mode, setMode] = useState<string>(BUILDING_CONTACTS_MODEL_TYPE.CREATE);
    const [editRecordData, setEditRecordData] = useState({});

    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const buildingIdNameMapping: any = state.get(SESSION_ATTRS.BUILDING_ID_NAMES);
    const currentUser: any = state.get(SESSION_ATTRS.LOGGED_USER);

    const getBuildingName = (buildingId: string) => {
        return buildingIdNameMapping[buildingId];
    }
    const opts = { fields: CSV_EXPORT_FIELDS }

    const filterBuildingContact = (contacts: Array<any>, state: number, building: string, searchString: string | undefined, role: string) => {
        let filtered = contacts;
        if (searchString) {
            const search = searchString.toLowerCase();
            filtered = filtered.filter(f => f.role.toLowerCase().includes(search));
        }

        if (state) {
            filtered = filtered.filter(f => f.state === state); //this will change
        }

        if (building !== ALL) {
            filtered = filtered.filter(f => f.buildingId === building);
        }

        if (role !== ALL) {
            filtered = filtered.filter(f => f.role === role);
        }
        return filtered;
    }

    const newBuildingContactsModalVisible = () => {
        setMode(BUILDING_CONTACTS_MODEL_TYPE.CREATE);
        setEditRecordData({});
        setContactModalVisible(true);
    }

    const editModalOpen = (row: any) => {
        row.buildingContactId = row.contactId;
        setEditRecordData(row);
        setMode(BUILDING_CONTACTS_MODEL_TYPE.EDIT);
        setContactModalVisible(true);
    }

    const loadBuildingContacts = useCallback(async (buildingId: string) => {
        try {
            setLoading(true);

            let contactsList: any = [];
            if (buildingId === ALL) {
                const proms: any = buildings.map(async (building: any) =>
                    getBuildingContacts(building.buildingId)
                );
                let contacts: any = await Promise.all(proms);
                contactsList = contacts.reduce((all: any, curr: any) => [...all, ...curr], []);
            } else {
                contactsList = await getBuildingContacts(buildingId);
            }

            let updatedContactsList = contactsList.map((contact: any) => ({ ...contact, displayContactNo: contact.countryDialingCode + ' ' + contact.contactNumber }));
            updatedContactsList.sort((n1: any, n2: any) => (new Date(n2.createdAt)).getTime() - (new Date(n1.createdAt)).getTime());
            setBuildingContacts(updatedContactsList);
            setFilteredBuildingContactList(filterBuildingContact(updatedContactsList, selectedState, selectedBuilding, searchingPhrase, selectedRole))
            setLoading(false);
        } catch (e) {
            console.error(e);
            setBuildingContacts([]);
            setFilteredBuildingContactList([]);
            setLoading(false);
        }
    }, [selectedState, selectedBuilding, searchingPhrase, selectedRole, buildings]);

    useEffect(() => {
        if (!contactModalVisible) {
            loadBuildingContacts(selectedBuilding);
        }
    }, [contactModalVisible, selectedBuilding, loadBuildingContacts]);

    useEffect(() => {
        setFilteredBuildingContactList(filterBuildingContact(buildingContacts, selectedState, selectedBuilding, searchingPhrase, selectedRole));
    }, [searchingPhrase, buildingContacts, selectedState, selectedBuilding, selectedRole]);

    const onSearch = (value: string | undefined) => {
        setSearchingPhrase(value);
        setSelectedBuildingContacts([]);
    };

    const onStateChange = (value: number) => {
        setSelectedState(value);
        setSelectedBuildingContacts([]);
    }

    const onBuildingChange = (value: string) => {
        setSelectedBuilding(value);
        if (value !== ALL) setSelectedBuildingContacts([]);
    }

    const onRoleChange = (value: string) => {
        setSelectedRole(value);
        if (value !== ALL) setSelectedBuildingContacts([]);
    }

    const onBuildingContactCreation = async (data: any, mode: string) => {
        let msg = mode === BUILDING_CONTACT_MODAL_TYPE.CREATE ? "Building Contact added successfully." : "Building Contact updated successfully";
        await notifiableAPICall(async () => {
            await addBuildingContact(data);
            setContactModalVisible(false);
        },
            "create-building-contact",
            "Please wait...",
            msg,
            "Something went wrong while adding building contact. Please retry."
        );
    }

    const onReactive = async (contactId: string, buildingId: string, currState: number) => {
        await notifiableAPICall(async () => {
            await changeState(contactId, buildingId, BUILDING_CONTACT_STATE.ACTIVE, currentUser.userId);
            await loadBuildingContacts(selectedBuilding);
        },
            "contact-state-change-active",
            "Please wait...",
            "Building Contact was converted to Active successfully.",
            "Something went wrong in converting the Contact to Active. Please retry."
        );
    }

    const onDeactive = async (contactId: string, buildingId: string, currState: number) => {
        confirm("Deactivate Building Contact",
            "Are you sure you want to deactivate this building contact?",
            async () => {
                await notifiableAPICall(async () => {
                    await changeState(contactId, buildingId, BUILDING_CONTACT_STATE.INACTIVE, currentUser.userId);
                    await loadBuildingContacts(selectedBuilding);
                },
                    "contact-state-change-inactive",
                    "Please wait...",
                    "Building contact was deactivated successfully",
                    "Something went wrong in Deactivating the contact. Please retry."
                );
            }, () => { });
    }

    const onBulkConvertToActive = async () => {
        await notifiableAPICall(async () => {
            const proms = selectedBuildingContacts.map(async ({ contactId, buildingId, state }) =>
                await changeState(contactId, buildingId, BUILDING_CONTACT_STATE.ACTIVE, currentUser.userId));

            await Promise.all(proms);
            setSelectedBuildingContacts([]);
            await loadBuildingContacts(selectedBuilding);
        },
            "contact-state-change-active-bulk",
            "Please wait...",
            "Building Contacts were converted to Active successfully.",
            "Something went wrong in converting one or more Contact to active. Please retry."
        );
    }

    const onBulkConvertToDeactive = async () => {
        confirm("Deactivate Building Contacts",
            "Are you sure you want to deactivate selected building contacts?",
            async () => {
                await notifiableAPICall(async () => {
                    const proms = selectedBuildingContacts.map(async ({ contactId, buildingId, state }) =>
                        await changeState(contactId, buildingId, BUILDING_CONTACT_STATE.INACTIVE, currentUser.userId));
                    await Promise.all(proms);
                    setSelectedBuildingContacts([]);
                    await loadBuildingContacts(selectedBuilding);
                },
                    "contact-state-change-deactive-bulk",
                    "Please wait...",
                    "Building contacts were deactivated successfully",
                    "Something went wrong in converting one or more Contact to Deactive. Please retry."
                );
            }, () => { });
    }

    const rowActions = (contactId: string, row: any) => {
        let content;
        switch (selectedState) {
            case BUILDING_CONTACT_STATE.ACTIVE:
                content = (
                    <>
                        <Button className='intnnt-ppvr-btn' type='text' icon={<EditFilled />} onClick={() => editModalOpen(row)}>Edit</Button>
                        <Button className='intnnt-ppvr-btn' danger type='text' icon={<UserDeleteOutlined />} onClick={() => onDeactive(contactId, row.buildingId, row.state)}>Deactivate</Button>
                    </>
                );
                break;
            case BUILDING_CONTACT_STATE.INACTIVE:
                content = (
                    <>
                        <Button className='intnnt-ppvr-btn' type='text' icon={<EditFilled />} onClick={() => editModalOpen(row)}>Edit</Button>
                        <Button className='intnnt-ppvr-btn' type='text' icon={<UserAddOutlined />} onClick={() => onReactive(contactId, row.buildingId, row.state)}>Reactivate</Button>
                    </>
                );
                break;
            default:
                content = null;
        }

        return (
            <div className='intnnt-ppvr'>
                {content}
            </div>
        )
    };

    const statusMapper = (state: number) => {
        return state === 1 ? <span className="status status--active"> Active </span> : <span className="status status--inactive"> Inactive </span>;
    }

    const roleMapper = (role: string) => {
        let matchingRole = ROLES_ARRAY.find(element => element.value === role);
        if (matchingRole) {
            return matchingRole.html;
        } else {
            return null;
        }

    }

    const isInBulkArray = (contact: any) => {
        return selectedBuildingContacts.includes(contact);
    }

    const moreAction = (contactId: string, row: any) => isInBulkArray(row) ?
        <div className="ind-more"><MoreOutlined style={{ color: '#cecece' }} className="more-settings" /></div>
        :
        <Popover trigger='hover' placement='left' content={rowActions(contactId, row)}>
            <div className="ind-more"><MoreOutlined className="more-settings" /></div>
        </Popover>

    const ROLES_ARRAY = [
        {
            value: 'cleaning',
            name: 'Cleaning',
            html: <span><b><Icon icon="cleaning" /> &nbsp;&nbsp;Cleaning</b></span>
        },
        {
            value: 'maintenance',
            name: 'Maintenance',
            html: <span><b><Icon icon="settings" /> &nbsp;&nbsp;Maintenance</b></span>
        },
        {
            value: 'dockmaster',
            name: 'Dockmaster',
            html: <span><b><Icon icon="anchor" /> &nbsp;&nbsp;Dockmaster</b></span>

        },
        {
            value: 'taxi',
            name: 'Taxi',
            html: <span><b><Icon icon="taxi" /> &nbsp;&nbsp;Taxi</b></span>

        },
        {
            value: 'security',
            name: 'Security',
            html: <span><b><Icon icon="security" /> &nbsp;&nbsp;Security</b></span>
        },
        {
            value: 'facilities_manager',
            name: 'Facilities Manager',
            html: <span><b><Icon icon="manager" /> &nbsp;&nbsp;Facilities Manager</b></span>
        }
    ];

    const columns: Array<any> = [
        {
            title: 'Role',
            dataIndex: 'role',
            sorter: (a: any, b: any) => (a.role || '').localeCompare(b.role || ''),
            render: (role: string) => roleMapper(role)
        },
        {
            title: 'Status',
            dataIndex: 'state',
            render: (state: number) => statusMapper(state)
        },
        {
            title: 'Building',
            dataIndex: 'buildingId',
            sorter: (a: any, b: any) => getBuildingName(a.buildingId).localeCompare(getBuildingName(b.buildingId)),
            render: (buildingId: string) => getBuildingName(buildingId)
        },
        {
            title: 'Contact No.',
            dataIndex: 'displayContactNo'
        },
        {
            title: 'Last Updated',
            dataIndex: 'updatedAt',
            render: (updatedAt: string) => toDateString(updatedAt)
        },
        {
            title: '',
            dataIndex: 'contactId',
            key: 'contactId',
            fixed: 'right',
            render: ((contactId: string, row: any) => (
                moreAction(contactId, row)
            ))
        }
    ];

    const rowSelection = {
        selectedRowKeys: selectedBuildingContacts.map(i => i.contactId),
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectedBuildingContacts(selectedRows);
        }
    };

    const tableHeader = () => {
        if (selectedBuildingContacts.length === 0) return null;
        let content = null;
        switch (selectedState) {
            case BUILDING_CONTACT_STATE.ACTIVE:
                content = (<>
                    <Button type="text" className="link-btn link-btn--danger" icon={<UserDeleteOutlined />} onClick={onBulkConvertToDeactive}>Deactivate</Button>                </>);
                break;
            case BUILDING_CONTACT_STATE.INACTIVE:
                content = (<>
                    <Button type="text" className="link-btn link-btn--default" icon={<UserAddOutlined />} onClick={onBulkConvertToActive}>Activate</Button>
                </>);
                break;
            default:
                content = null;
        }
        return (<Space>
            <span className="select-text">{selectedBuildingContacts.length} selected</span>
            <span className="divider-vertical"></span>
            {content}
        </Space>);
    }

    const getExportableContactsList = () => {
        let exportContactsList = [];
        exportContactsList = filteredBuildingContactList.map(item => {
            let ecl = {
                Role: ROLES_ARRAY.find(element => element.value === item.role)?.name,
                Access: getBuildingName(item.buildingId),
                ContactNo: item.displayContactNo,
                Description: item.description,
                Status: item.state === 1 ? 'ACTIVE' : 'INACTIVE',
                LastUpdatedAt: toDateString(item.updatedAt)

            }
            return ecl;
        });

        return exportContactsList;
    }

    const convertToCsv = () => {
        try {
            const parser = new Parser(opts);
            const csv = parser.parse(getExportableContactsList());
            const file = new Blob([csv], { type: 'text/csv' });
            download(file, 'Contacts.csv');
        } catch (err) {
            console.error(err);
        }
    }


    return (

        <>
            {
                contactModalVisible && <BuildingContactModal
                    onClose={() => setContactModalVisible(false)}
                    onSubmit={onBuildingContactCreation}
                    mode={mode}
                    editRecordData={editRecordData}
                />
            }
            <Space direction='vertical'>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Row style={{ marginTop: 15, justifyContent: "space-between" }}>
                        <Space style={{ marginBottom: 16 }}>
                            <Title level={4}>
                                {filteredBuildingContactList.length} Contacts
                    </Title>
                        </Space>
                        <Space style={{ marginBottom: 16 }}>
                            <Button type='link' style={{ color: '#104EA1', fontSize: 12, fontWeight: 600 }} icon={<ExportOutlined style={{ color: '#104EA1', fontWeight: 600 }} />} onClick={convertToCsv}>Export .CSV</Button>
                        </Space>

                    </Row>
                    <Row className="grid-options">
                        <Space style={{ marginBottom: 16, flexWrap: "wrap" }}>
                            <Select defaultValue={BUILDING_CONTACT_STATE.ACTIVE} onChange={onStateChange}> sadsad
                                {BUILDING_CONTACT_STATUS.map(({ name, state }) => <Option key={state} value={state}>{name}</Option>)}
                            </Select>
                            <Select defaultValue={ALL} onChange={onRoleChange} style={{ width: 150 }}>
                                <Option value={ALL}>All Roles</Option>
                                {ROLES_ARRAY.map((role: any) => (
                                    <Option key={role.value} value={role.value}>{role.name}</Option>
                                ))}
                            </Select>
                            <Select defaultValue={ALL} onChange={onBuildingChange} style={{ width: 150 }}>
                                <Option value={ALL}>All Buildings</Option>
                                {buildings.map((building: any) => (
                                    <Option key={building.buildingId} value={building.buildingId}>{building.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Space className="grid-options--search">
                            <Search placeholder="Search" allowClear onChange={(e) => onSearch(e.target.value)} style={{ width: 400 }} />

                            <Button
                                className="btn btn--primary"
                                icon={<PlusOutlined />}
                                onClick={newBuildingContactsModalVisible}
                            >
                                Add New
                        </Button>

                        </Space>
                    </Row>
                </Space>
                <Content className="table-wrapper">
                    <Table rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredBuildingContactList}
                        loading={isLoading}
                        rowKey='contactId'
                        title={tableHeader}
                        pagination={{
                            total: filteredBuildingContactList.length,
                            showSizeChanger: true,
                            showTotal: (total, range) => `showing ${range[0]}-${range[1]} out of ${total} contacts`
                        }} />
                </Content>
            </Space>
        </>
    )
};
