import { Row, Select, Space, Input, Button, Typography, Radio } from 'antd';
import { useState, useContext, useEffect } from 'react';
import { BOOKING_STATUS, BOOKING_STATUS_ARRAY, BOOKING_TIME_FORMAT, FUTURE_BOOKING_CSV_EXPORT_FIELDS, DESK_VIEWS } from './constants';
import { PlusOutlined, RedoOutlined, ExportOutlined, BarChartOutlined } from '@ant-design/icons';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { BookingRequests } from './BookingRequests';
import { DatePopup } from './DatePopup';
import { BookingDesks } from './bookingDesks/BookingDesks';
import {getBookingRequests, getDeskList, getFloorList} from './actions';
import { convertToCsv, stringSorter } from '../utils/common-utils';
import { BookingHistory } from './BookingHistory';
import { DeskAnalytics } from './DeskAnalytics';
import { notifiableAPICall } from '../utils/notification';
import moment from 'moment';
import { FloorManagement } from './bookingDesks/FloorManagement';

const { Option } = Select;
const { Search } = Input;
const { Title } = Typography;
const opts = { fields: FUTURE_BOOKING_CSV_EXPORT_FIELDS }

export const ALL = "all";

export const DeskBookingView = () => {
    const [selectedView, setSelectedView] = useState(DESK_VIEWS.BOOKINGS);
    const [prevSelectedView, setPrevSelectedView] = useState(DESK_VIEWS.BOOKINGS);
    const [selectedBuilding, setSelectedBuilding] = useState<string>(ALL);

    //bookings
    const [bookingStatusFilter, setBookingStatusFilter] = useState(BOOKING_STATUS_ARRAY[0].value);
    const [bookingSearchText, setBookingSearchText] = useState<string>();
    const [exportModalVisible, setExportModalVisible] = useState<boolean>(false);

    //desks
    const [deskSearchText, setDeskSearchText] = useState<string>();
    const [newDeskModalVisible, setNewDeskModalVisible] = useState(false);
    const [floorList, setFloorList] = useState<any[] | undefined>();
    const [deskList, setDeskList] = useState<any[] | undefined>();

    const { state } = useContext(sessionStore);
    const user: any = state.get(SESSION_ATTRS.LOGGED_USER);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const buildingIdNameMapping: any = state.get(SESSION_ATTRS.BUILDING_ID_NAMES);

    useEffect(() => {
        loadFloors();
        loadDesks();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadFloors = async () => {
        try {
            let floors = await getFloorList(user.buildingId);
            floors.sort((s1: any, s2: any) => stringSorter(s1.name, s2.name));
            setFloorList(floors);
        } catch (e) {
            setFloorList([]);
        }
    }

    const loadDesks = async () => {
        try {
            let desks = await getDeskList(user.buildingId);
            desks.sort((s1: any, s2: any) => stringSorter(s1.name, s2.name));
            setDeskList(desks);
        } catch (e) {
            setDeskList([]);
        }
    }

    const exportableRequests = (allRequests: any) => {
        let exportRequestsList = [];
        exportRequestsList = allRequests.filter((item: any) => item.reqStatus === BOOKING_STATUS.APPROVED || item.reqStatus === BOOKING_STATUS.PENDING)
            .map((item: any) => {
                let startTime = moment(item.startTime);
                let endTime = moment(item.endTime);
                let desk = deskList?.find(desk => desk.deskId === item.deskId);
                let floor = floorList?.filter((floor: any) => floor.floorId === desk.floorId);
                let erl = {
                    'Building': buildingIdNameMapping[item.buildingId],
                    'Floor': floor && floor[0]?.name,
                    'Desk': desk?.name,
                    'Start Time':startTime.format(BOOKING_TIME_FORMAT),
                    'End Time': endTime.format(BOOKING_TIME_FORMAT),
                    'Booking Duration (hours)': moment.duration(endTime.diff(startTime)).asHours(),
                    'Booked By': item.createdBy.name,
                    'Booking Reason': item.bookingReason,
                    'Approved By': item.reqStatus === BOOKING_STATUS.APPROVED ? item.approvedBy.name : "-",
                    'Status': item.reqStatus === BOOKING_STATUS.APPROVED ? "Approved" : "Pending",
                    'Per Hour Cost($)': desk.hourlyPrice
                }
                return erl;
            });

        return exportRequestsList;
    }

    const exportBookings = async (to: any) => {
        const bookingRequests = await notifiableAPICall(() => getBookingRequests(user.buildingId, '', undefined, undefined, to),
            'get_all_req',
            "Please wait...",
            "Booking requests loaded",
            "Something went wrong.",
            true);
        const data = exportableRequests(bookingRequests);
        convertToCsv(opts, data, "Future_Booking_Requests.csv");
        setExportModalVisible(false);
    }

    const changeToHistoryView = () => {
        setPrevSelectedView(selectedView);
        setSelectedView(DESK_VIEWS.HISTORY);
    }

    const changeToAnalyticsView = () => {
        setPrevSelectedView(selectedView);
        setSelectedView(DESK_VIEWS.ANALYTICS);
    }

    const getFloorByFloorId = (floorId: string) => {
        if(!floorList) return;
        const floor = floorList.filter((floor: any) => floor.floorId === floorId);
        return floor[0].name;
    }

    const onSelectView = (value: any) => {
        setSelectedView(value);
        setDeskSearchText('');
    }

    if (selectedView === DESK_VIEWS.HISTORY) {
        return <BookingHistory prevSelectedView={prevSelectedView} deskList={deskList} setSelectedView={setSelectedView} setSelectedBuilding={setSelectedBuilding} buildings={buildings} getFloorByFloorId = {getFloorByFloorId}/>;
    }
    if (selectedView === DESK_VIEWS.ANALYTICS) {
        return <DeskAnalytics prevSelectedView={prevSelectedView} deskList={deskList} setSelectedView={setSelectedView} buildings={buildings} floorList = {floorList}/>;
    }

    return (
        <>
            {exportModalVisible &&
                <DatePopup
                    title="Export Upcoming Boookings"
                    okButtonText="Export"
                    onCancel={() => setExportModalVisible(false)}
                    onConfirm={exportBookings}
                    defaultDate={moment().add('1', 'month')}
                    label="Up To" />}
            <Space direction='vertical'>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Row style={{ marginTop: 15, justifyContent: 'space-between' }}>
                        <Title level={4}>
                            Desk Bookings
                        </Title>
                        <div>
                            <Button type='link' style={{ color: '#104EA1', fontSize: 12, fontWeight: 600 }} icon={<BarChartOutlined style={{ color: '#104EA1', fontWeight: "bold" }} />} onClick={changeToAnalyticsView}>Analytics</Button>
                            <Button type='link' style={{ color: '#104EA1', fontSize: 12, fontWeight: 600 }} icon={<RedoOutlined rotate={-90} style={{ color: '#104EA1', fontWeight: "bold" }} />} onClick={changeToHistoryView}>Booking history</Button>
                            {selectedView === DESK_VIEWS.BOOKINGS ? <Button type='link' style={{ color: '#104EA1', fontSize: 12, fontWeight: 600 }} icon={<ExportOutlined style={{ color: '#104EA1', fontWeight: "bold" }} />} onClick={() => setExportModalVisible(true)}>Export .csv</Button> : null}
                        </div>
                    </Row>
                    <Row className="grid-options">
                        <Space>
                            <Radio.Group value={selectedView} onChange={e => onSelectView(e.target.value)} className='bs-tab-selector'>
                                <Radio.Button value={DESK_VIEWS.BOOKINGS} className='left-button'>Booking requests</Radio.Button>
                                <Radio.Button value={DESK_VIEWS.DESKS} className='right-button'>Desk management</Radio.Button>
                                <Radio.Button value={DESK_VIEWS.FLOOR} className='right-button'>Floor management</Radio.Button>
                            </Radio.Group>
                            <Select defaultValue={ALL} style={{ width: 160 }} onChange={setSelectedBuilding}>
                                <Option value={ALL}>All Buildings</Option>
                                {buildings.map((building: any) => (
                                    <Option key={building.buildingId} value={building.buildingId}>{building.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Space className="grid-options--search">
                            {selectedView === DESK_VIEWS.BOOKINGS &&
                                <>
                                    <Select value={bookingStatusFilter} onChange={setBookingStatusFilter} >
                                        {BOOKING_STATUS_ARRAY.map(({ filterName, value }: any) => (
                                            <Option key={value} value={value}>{filterName}</Option>
                                        ))}
                                    </Select>
                                    <Search placeholder="Search" allowClear onChange={(e) => setBookingSearchText(e.target.value)} className="grid-search-box-md" />
                                </>
                            }
                            {selectedView === DESK_VIEWS.DESKS &&
                                <>
                                    <Search placeholder="Search" allowClear onChange={(e) => setDeskSearchText(e.target.value)} className="grid-search-box bs-grid-search-box" />
                                    <Button
                                        onClick={() => setNewDeskModalVisible(true)}
                                        className="btn btn--primary"
                                    >
                                        Create new Desk
                                        <PlusOutlined />
                                    </Button>
                                </>
                            }
                            {selectedView === DESK_VIEWS.FLOOR &&
                                <>
                                    <Button
                                        onClick={() => setNewDeskModalVisible(true)}
                                        className="btn btn--primary"
                                    >
                                        Create new floor
                                        <PlusOutlined />
                                    </Button>
                                </>
                            }
                        </Space>
                    </Row>
                </Space>
                {selectedView === DESK_VIEWS.BOOKINGS &&
                    <BookingRequests
                        deskList={deskList}
                        bookingStatus={bookingStatusFilter}
                        bookingSearchText={bookingSearchText}
                        buildings={buildings}
                        getFloorByFloorId = {getFloorByFloorId}
                    />}
                {selectedView === DESK_VIEWS.DESKS &&
                    <BookingDesks
                        newDeskModalVisible={newDeskModalVisible}
                        setNewDeskModalVisible={setNewDeskModalVisible}
                        selectedBuilding={selectedBuilding}
                        searchPhrase={deskSearchText}
                        deskList={deskList}
                        floorList={floorList}
                        buildings={buildings}
                        loadDesks={loadDesks}
                        getFloorByFloorId = {getFloorByFloorId}
                    />
                }
                {selectedView === DESK_VIEWS.FLOOR &&
                    <FloorManagement
                        newDeskModalVisible={newDeskModalVisible}
                        setNewDeskModalVisible={setNewDeskModalVisible}
                        selectedBuilding={selectedBuilding}
                        searchPhrase={deskSearchText}
                        floorList={floorList}
                        deskList={deskList}
                        buildings={buildings}
                        loadFloors={loadFloors}
                        loadDesks={loadDesks}
                    />
                }
            </Space >
        </>
    )
};
