import { getApi, APIS, postApi, putApi, S3_METHODS, uploadFile } from "../utils/api-utils";
import { getFileExtension } from "../utils/common-utils";

export const getNewsList = async (state: number, buildingId: string) => {
    const { newsList } = await getApi(APIS.NEWS_API, '/news', { queryStringParameters: { state, buildingId } });
    return newsList;
}

export const addNewPost = async (post: object) => {
    const { newsId } = await postApi(APIS.NEWS_API, '/news', post);
    return newsId;
}

export const getSignedUrl = async (key: string, contentType: string | undefined, method: string, expires: number) => {
    const params = {
        key: key,
        contentType: contentType,
        method: method,
        expires: expires
    }
    const { url } = await getApi(APIS.INDUCTION_API, '/inductions/signedurl', { queryStringParameters: params });
    return url;
}

export const getNewsSignedUrl = async (key: string, contentType: string | undefined, method: string, expires: number) => {
    const params = {
        key: key,
        contentType: contentType,
        method: method,
        expires: expires
    }
    const { url } = await getApi(APIS.NEWS_API, '/news/signedurl', { queryStringParameters: params });
    return url;
}

export const changeState = async (newsId: string, buildingId: string, state: number, prevState: number, updatedBy: any) => {
    return await putApi(APIS.NEWS_API, '/news/state', { newsId, buildingId, state, prevState, updatedBy: updatedBy });
}

export const discardNews = async (params: any) => {
    return await postApi(APIS.NEWS_API, '/news/discard', params);
}

export const getSingleNews = async (buildingId:string, newsId:string) => {
    const params = {
        buildingId,
        newsId
    }
    const news = await getApi(APIS.NEWS_API, '/singlenews', { queryStringParameters: params });
    return news;
}

export const downloadHtml = async (key: string) => {
    const url = await getHtmlFile(key);
    const res = await fetch(url);
    const data = await res.text();
    return data;
}

export const getHtmlFile = async (key: string) => {
    return await getNewsSignedUrl(key, undefined, S3_METHODS.GET, 1800);
}

export const uploadImage = async (image: File, newsId: string, isThumbnail: boolean) => {
    const ext = getFileExtension(image.name);
    let fileName = isThumbnail ? `thumbnail.${ext}` : `featureImage.${ext}`
    const key = `${newsId}/${fileName}`;
    const url = await getNewsSignedUrl(key, image.type, S3_METHODS.PUT, 900);
    await uploadFile(url, image, image.type);
    return key;
}