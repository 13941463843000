

type PropType = {
    icon: string,
    width?: string,
    height?: string,
    hovered?: boolean,
    color?: string
}

const Icon = ({ icon, width = '20px', height = '20px', hovered = false, color = ''} : PropType) => {
    return <span className={`icon icon-${icon.toLocaleLowerCase()} ${hovered ? 'icon-hovered' : ''}`} style={{backgroundImage:`${'url(/icons/icons.png)'}`, width:`${width}`, height:`${height}`, color: color}}></span>
}

export default Icon;