
const NonAdminUserView = () => {

    return (
        <div className="banner-body">
            <div className="banner">
                Sorry, you are not an admin user. Please contact an admin.
            </div>
        </div>
    );
}

export default NonAdminUserView;