import { useCallback, useContext, useEffect, useState } from 'react';
import { Layout, Table, Row, Tooltip, Col } from 'antd';
import moment from 'moment';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { getBookingRequests, approveRequest } from './actions';
import { notifiableAPICall, confirm } from '../utils/notification';
import { BOOKING_STATUS_ARRAY, RECURRENCES } from './constants';
import { clockIcon, locationPinIcon } from './svgIcons';
import { DeclinePopup } from './DeclinePopup';
import { RecurringApprovalModal } from './RecurringApprovalModal';
import { SyncOutlined } from '@ant-design/icons'

const { Content } = Layout;

export const BookingRequests = ({ bookingStatus, bookingSearchText, spaceList, buildings }: any) => {

    const { state } = useContext(sessionStore);
    const user: any = state.get(SESSION_ATTRS.LOGGED_USER);

    const [bookingRequestList, setBookigRequestList] = useState<Array<any>>([]);
    const [filteredList, setFilteredList] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [aciveDeclineRequest, setActiveDeclinerequest] = useState<any>();
    const [recurringApprovalRequest, setRecurringApprovalRequest] = useState<undefined | Object>();

    const loadRequests = useCallback(async () => {
        try {
            const requestList = await getBookingRequests(user.buildingId, bookingStatus);
            setBookigRequestList(requestList);
            setFilteredList(requestList);
        }
        catch (err) {
            setBookigRequestList([]);
            setFilteredList([]);
        }
    }, [bookingStatus, user]);

    const loadBookingRequests = useCallback(async () => {
        setIsLoading(true);
        await loadRequests();
        setIsLoading(false);
    }, [loadRequests]);

    useEffect(() => {
        if (spaceList !== undefined) {
            loadBookingRequests();
        }
    }, [loadBookingRequests, spaceList]);

    const filterBookingrequests = useCallback((BookingRequests: Array<any>, searching: (string | undefined)) => {
        let filtered;
        if (searching) {
            const searchingText = searching.toLowerCase().trim();
            filtered = BookingRequests.filter(i => {
                const space = spaceList.find((space: any) => space.spaceId === i.spaceId)
                const phrase = space.name + i.createdBy.name;
                return phrase.toLowerCase().includes(searchingText)
            });
        } else {
            filtered = BookingRequests;
        }
        // if (buildingId === ALL) return filtered;
        // return filtered.filter(i => i.buildingId === buildingId);
        return filtered;
    }, [spaceList]);

    useEffect(() => {
        if (bookingRequestList.length !== 0) {
            setIsLoading(true);
            setFilteredList(filterBookingrequests(bookingRequestList, bookingSearchText));
            setIsLoading(false);
        }
    }, [bookingSearchText, bookingRequestList, filterBookingrequests]);

    const approveReq = async (requestId: string, req: any) => {
        const isOneTime = !req.recurrence || req.recurrence === RECURRENCES.ONETIME;
        if (!isOneTime) {
            setRecurringApprovalRequest(req);
            return;
        }
        confirm("Approve Booking", `Are you sure you want to approve the booking?`,
            async () => {
                await notifiableAPICall(() => approveRequest(user.buildingId, requestId),
                    'approve_req',
                    "Please wait...",
                    "Space Booking Request Approved.",
                    "Something went wrong.",
                    true);
                loadBookingRequests();
            }, () => { });
    }

    const onDeclineReq = async (requestId: string, req: any) => {
        setActiveDeclinerequest(req);
    }

    const requestRoom = (spaceId: string) => {
        const space = spaceList.find((space: any) => space.spaceId === spaceId);
        return (
            <>
                <div className="bs-title">
                    <div className="bs-title-img-wrapper fill">
                        <img className="bs-title-img" src={space.thumbnailLink} alt={space.name} />
                    </div>
                    <div className="bs-title-label">
                        <Row gutter={5} style={{ flexFlow: 'nowrap' }} className="bs-title-text">
                            <Col flex={4 / 5} style={{ maxWidth: 150, width: 'max-content' }}>{space.name}</Col>
                            <Col flex={1 / 5} >{space?.approvalRequired && <div style={{ marginLeft: 5 }}>{clockIcon}</div>}</Col>
                        </Row>
                        <Row gutter={5} className='bs-sub-title-text'>
                            <Col>
                                {locationPinIcon}
                            </Col>
                            <Col>
                                {space.floor}
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
    
    const dateTimeFormat = (row: any) => {
        if (moment(row.startTime).isSame(moment(row.endTime), 'day')) {
            return (
                <>
                    <Row>{moment(row.startTime).format("dddd, DD MMMM")}{row.recurrence && row.recurrence !== RECURRENCES.ONETIME && <SyncOutlined style={{ alignSelf: 'center', marginLeft: '10px', color: 'darkred' }} />}</Row>
                    <Row style={{ textAlign: 'left' }} className='bs-time-text'>{moment(row.startTime).format("hh:mm A") + ' - ' + moment(row.endTime).format("hh:mm A")}</Row>
                </>
            );
        }
        else {
            return (
                <>
                    <Row gutter={5}>
                        <Col style={{ marginRight: 3 }}>{moment(row.startTime).format("dddd, DD MMMM")}</Col>
                        <Col className='bs-time-text'>{moment(row.startTime).format("hh:mm A") + ' - '}</Col>
                    </Row>
                    <Row>
                        <Col style={{ marginRight: 3 }}>{moment(row.endTime).format("dddd, DD MMMM")}</Col>
                        <Col className='bs-time-text'>{moment(row.endTime).format("hh:mm A")}</Col>
                    </Row>
                </>
            );
        }
    }

    const columns: Array<any> = [
        {
            title: 'Rooms',
            dataIndex: 'spaceId',
            render: (spaceId: string, row: any) => requestRoom(spaceId)
        },
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            render: (requestId: string, booking: any) => (
                <div className="bs-title-label" style={{ maxWidth: 200 }}>
                    {dateTimeFormat(booking)}
                    {booking.recurrence === RECURRENCES.ONETIME ? 'Does not Repeat' : booking.recurrence === RECURRENCES.DAILY ? 'Daily' : booking.recurrence === RECURRENCES.WEEKLY ? 'Weekly' : booking.recurrence === RECURRENCES.MONTHLY ? 'Monthly' : 'Does not Repeat'} {booking.recurrence && booking.recurrence !== RECURRENCES.ONETIME ? `(${booking.occurrence}/${booking.occurrences})` : ''}
                </div>
            )
        },
        {
            title: 'Building',
            dataIndex: 'buildingId',
            render: (buildingId: string) => (
                <div className="bs-title-label">
                    <Row className='bs-rw-sngl'>
                        <p>{buildings.find((b: any) => b.buildingId === buildingId)?.name}</p>
                    </Row>
                </div>)
        },
        {
            title: 'Booker',
            dataIndex: 'createdBy',
            render: (createdBy: any, row: any) => (
                <div className="bs-title-label">
                    <Row className='bs-rw-sngl'>
                        <p>{createdBy.name}</p>
                    </Row>
                </div>
            )
        },
        {
            title: 'Reason for booking',
            dataIndex: 'bookingReason',
            render: (bookingReason: any, row: any) => (
                <div className="bs-title-label">
                    <Row className='bs-rw'>
                        <Tooltip title={bookingReason ? bookingReason : "N/A"} trigger='hover'>
                            <p className='bs-txt-wrppr'>{bookingReason ? bookingReason : "N/A"}</p>
                        </Tooltip>
                    </Row>
                </div>
            )
        },
        {
            title: '',
            dataIndex: 'requestId',
            render: (requestId: string, req: any) => (
                bookingStatus !== BOOKING_STATUS_ARRAY[2].value && bookingStatus !== BOOKING_STATUS_ARRAY[3].value ?
                    <div className="bs-title-label">
                        <Row className='bs-rw' style={{ flexFlow: 'nowrap' }}>
                            {
                                // eslint-disable-next-line
                                <a className='link-btn link-btn--danger bs-lnk' onClick={() => onDeclineReq(requestId, req)} href="#">Decline</a>
                            }
                            {
                                bookingStatus !== BOOKING_STATUS_ARRAY[1].value ?
                                    // eslint-disable-next-line
                                    <a className='bs-br-btn' onClick={() => approveReq(requestId, req)} href="#">Approve</a>
                                    : null
                            }
                        </Row>
                    </div>
                    :
                    null
            )
        }
    ];

    return (
        <Content className="table-wrapper">
            {aciveDeclineRequest && <DeclinePopup isVisible={aciveDeclineRequest} onCancel={() => { setActiveDeclinerequest(undefined); }} request={aciveDeclineRequest} onOk={() => {
                setActiveDeclinerequest(undefined);
                loadBookingRequests();
            }} />}
            {recurringApprovalRequest && <RecurringApprovalModal onClose={() => setRecurringApprovalRequest(undefined)} request={recurringApprovalRequest} onOk={() => {
                setRecurringApprovalRequest(undefined);
                loadBookingRequests();
            }} />}
            <Table rowKey="requestId"
                columns={columns}
                dataSource={filteredList}
                loading={isLoading}
                pagination={{
                    total: filteredList.length,
                    showSizeChanger: true,
                    showTotal: (total, range) => `showing ${range[0]}-${range[1]} out of ${total} requests`
                }} />
        </Content>
    )
};
