export const MENU = {
    USERS: 'users',
    NEWS_FEED: {
        ROOT: 'news-feed',
        SUB: {
            ADD_NEW: 'add-new',
            EDIT: 'edit'
        }
    },
    BOOKING_SPACE: {
        ROOT: 'booking-space'
    },
    DESK_BOOKING: {
        ROOT: 'desk-booking'
    },
    INDUCTION_VIDEOS: {
        ROOT: 'inductions',
        SUB: {
            ADD_NEW: 'add-new'
        }
    },
    BUILDING_CONTACTS: 'building-contacts',
    ISSUES: 'issues',
}
