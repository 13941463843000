import React, { useContext, useState } from "react";
import { Button, Layout, Popover } from 'antd';
import { SideMenu } from "./SideMenu";
import { Redirect, useParams } from "react-router-dom";
import { MENU } from "./constants";
import { NewsFeedView } from "../newsFeed/NewsFeedView";
import { NewPost } from "../newsFeed/NewPost";
import { InductionsView } from "../inductionVideos/InductionsView";
import { BuildingContactsView } from "../buildingContacts/BuildingContactsView";
import { Users } from "../users/Users";
import { logout } from "../login/actions";
import { sessionStore, SESSION_ATTRS } from "../store/session";
import NonAdminUserView from "./NonAdminUserView";
import InactiveUserView from "./InactiveUserView";
import { isDesktop } from '../utils/view'
import { MenuOutlined } from '@ant-design/icons';
import { BookingSpaceView } from "../bookingSpace/BookingSpaceView";
import { IssuesView } from "../issues/IssuesView";
import { DeskBookingView } from "../deskBooking/DeskBookingView";

const { Header } = Layout;

export const DashBoardPage = () => {
    let { mainMenu, subMenu, first_param, second_param } = useParams<{ mainMenu: string, subMenu: string, first_param?: string, second_param?: string }>();

    const { state } = useContext(sessionStore);
    const [collapsed, setCollapsed] = useState(false);
    const user: any = state.get(SESSION_ATTRS.LOGGED_USER);
    const loggedTenant: any = state.get(SESSION_ATTRS.LOGGED_TENANT);

    let getView = () => {
        if (user.isInvalid || !user.isActive || user.isFederatedUser) {
            return <InactiveUserView />
        }
        if (!user.isAdmin) {
            return <NonAdminUserView />
        }
        switch (mainMenu) {
            case MENU.USERS:
                return <Users />;
            case MENU.NEWS_FEED.ROOT:
                if (subMenu) {
                    if (subMenu === MENU.NEWS_FEED.SUB.ADD_NEW)
                        return <NewPost />;
                    if (subMenu === MENU.NEWS_FEED.SUB.EDIT)
                        return <NewPost buildingId={first_param} newsId={second_param} />;
                }
                return <NewsFeedView />;
            case MENU.BOOKING_SPACE.ROOT:
                return <BookingSpaceView />;
            case MENU.DESK_BOOKING.ROOT:
                return <DeskBookingView />;
            case MENU.INDUCTION_VIDEOS.ROOT:
                return <InductionsView />;
            case MENU.BUILDING_CONTACTS:
                return <BuildingContactsView />;
                case MENU.ISSUES:
                    return <IssuesView />;
            default:
                return <Redirect to={`/${MENU.USERS}`} />;
        }
    };

    const onLogoutClick = async () => {
        await logout();
        window.location.reload();
    }

    const userIcon = () => {
        const getFirstChar = (name: string) => name ? name.substr(0, 1) : "";
        return `${getFirstChar(user.firstName)}${getFirstChar(user.lastName)}`;
    }

    const toggle = () => {
        setCollapsed(collapsed => !collapsed);
    }

    const sideMenu = (!user.isInvalid && user.isActive && !user.isFederatedUser && user.isAdmin) ?
        <SideMenu
            menuCollapsed={isDesktop() ? false : collapsed}
            selectedMenu={mainMenu}
            isDesktop={isDesktop()}
            toggle={toggle}
            logoUrl={loggedTenant.logoUrl} />
        : null;


    const mobileMenuToggler = !isDesktop() ? <div className="menu-toggler">
        <Button className="menu-toggle-button" icon={<MenuOutlined className="menu-toggle-icon" />} onClick={toggle} />
    </div> : null;

    return (
        <Layout className={!isDesktop() ? "layout--mobile" : ""}>
            {sideMenu}
            <Layout>
                <Header style={{ background: 'white' }}>
                    <div>{mobileMenuToggler}</div>
                    <div className="login-info">
                        <a href="/" className="profile-link">
                            {`${user.firstName} ${user.lastName}`}
                            <Popover placement="bottomRight" content={
                                <div>
                                    <Button type="link" onClick={onLogoutClick}>Logout</Button>
                                </div>
                            } trigger="click">
                                <span>{userIcon()}</span>
                            </Popover>
                        </a>
                    </div>
                </Header>
                <Layout className="main-content-wrapper">
                    {getView()}
                </Layout>
                {/* <Footer>Footer</Footer> */}
            </Layout>
        </Layout>
    );
}
