import { Layout, Row, Table, Col, Popover, Button } from 'antd';
import { useEffect, useState } from 'react';
import { BookingSpaceModal } from './spaceModal/BookingSpaceModal';
import { SpaceBookingModal } from './SpaceBookingModal';
import { SpaceCalendarModal } from './SpaceCalendarModal';
import { stringSorter } from '../../utils/common-utils';
import moment from 'moment';
import { bookIcon, clockIcon, deleteIcon, editIcon, locationPinIcon, peopleIcon, priceTagIcon } from '../svgIcons';
import { MoreOutlined } from '@ant-design/icons';
import { SpaceDetailsModal } from './SpaceDetailsModal';
import { Link } from 'react-router-dom';
import { confirm, notifiableAPICall, notifyError, notifySuccess } from '../../utils/notification';
import { deleteSpace } from '../actions';


const { Content } = Layout;
const ALL = "all";
let onBookSpaceAction: Function;
let onViewSpaceAction: Function;
let onEditSpaceAction: Function;
let onDeleteSpaceAction: Function;
let buildingList: Array<any>;

export const BookingSpaces = ({ newSpaceModalVisible, setNewSpaceModalVisible, selectedBuilding, searchPhrase, spaceList, buildings, loadSpaces }: any) => {
    buildingList = buildings;

    const [filteredList, setFilteredList] = useState<any[] | undefined>(undefined);
    const [bookingSpace, setBookingSpace] = useState<Object | undefined>();
    const [viewingSpace, setViewingSpace] = useState<Object | undefined>();
    const [editingSpace, setEditingSpace] = useState<Object | undefined>();
    const [calendarSpace, setCalendarSpace] = useState<Object | undefined>();

    onBookSpaceAction = (space: Object) => setBookingSpace(space);
    onViewSpaceAction = (space: any) => setViewingSpace({ ...space, buildingName: buildings.find((b: any) => b.buildingId === space.buildingId)?.name });
    onEditSpaceAction = (space: Object) => setEditingSpace(space);
    onDeleteSpaceAction = (space: any) => {
        confirm("Delete Space", `Are you sure you want to delete this space (${space.name})?`,
            async () => {
                let { success, hasBookings } = await notifiableAPICall(async () => await deleteSpace(space.buildingId, space.spaceId),
                    "delete-space",
                    "Deleting space...",
                    undefined,
                    "Something went wrong in deleting space. Please retry."
                );
                if (success) {
                    notifySuccess('Space deleted successfully');
                    loadSpaces();
                }
                else if (hasBookings) notifyError("Can't delete a space which has bookings");
            }, () => { });
    }

    const filterSpaces = () => {
        console.log({ searchPhrase });
        let filtered;
        if (searchPhrase) {
            const searchingText = searchPhrase.toLowerCase();
            filtered = spaceList.filter((i: any) => i.name.toLowerCase().includes(searchingText));
        } else {
            filtered = spaceList;
        }
        if (selectedBuilding === ALL) return filtered;
        return filtered.filter((i: any) => i.buildingId === selectedBuilding);
    };

    useEffect(() => {
        if (spaceList !== undefined) {
            setFilteredList(undefined);
            setFilteredList(filterSpaces());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchPhrase, spaceList, selectedBuilding]);

    return (
        <>
            {newSpaceModalVisible && <BookingSpaceModal onClose={() => setNewSpaceModalVisible(false)} onAdd={() => { loadSpaces(); setNewSpaceModalVisible(false); }} />}
            {bookingSpace && <SpaceBookingModal space={bookingSpace} onClose={() => setBookingSpace(undefined)} />}
            {viewingSpace && <SpaceDetailsModal space={viewingSpace} onClose={() => setViewingSpace(undefined)} onBookSpace={setBookingSpace} onEditSpace={(space: any) => { setViewingSpace(undefined); setEditingSpace(space); }} onViewCalendar={setCalendarSpace} />}
            {editingSpace && <BookingSpaceModal space={editingSpace} onClose={() => {setEditingSpace(undefined); loadSpaces();}} onSave={() => { loadSpaces(); setEditingSpace(undefined); }} />}
            {calendarSpace && <SpaceCalendarModal space={calendarSpace} onClose={() => setCalendarSpace(undefined)} />}
            <Content className="table-wrapper">
                <Table rowKey="spaceId"
                    columns={columns}
                    dataSource={filteredList || []}
                    loading={filteredList === undefined}
                    pagination={{
                        total: filteredList?.length,
                        showSizeChanger: true,
                        showTotal: (total, range) => `showing ${range[0]}-${range[1]} out of ${total} booking spaces`
                    }} />
            </Content>
        </>
    )
};

const columns: Array<any> = [
    {
        title: 'Rooms',
        dataIndex: 'name',
        sorter: (a: any, b: any) => stringSorter(a.name, b.name),
        render: (name: string, space: any) => (
            <Link onClick={() => onViewSpaceAction(space)} to='#'>
                <div className="bs-title">
                    <div className="bs-title-img-wrapper fill">
                        <img className="bs-title-img" src={space.thumbnailLink} alt={name} />
                    </div>
                    <div className="bs-title-label">
                        <Row className="bs-title-text" gutter={5} style={{flexFlow:'nowrap'}}>
                            <Col style={{maxWidth: 150, width:'max-content'}}>{space.name}</Col>
                            <Col >{space?.approvalRequired && <div style={{ marginLeft: 5 }}>{clockIcon}</div>}</Col>
                        </Row>
                        <Row gutter={5} className='bs-sub-title-text'>
                            <Col>
                                {locationPinIcon}
                            </Col>
                            <Col>{space.floor}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Link>
        )
    },
    {
        title: 'Today calendar (hour)',
        dataIndex: 'calendarSlots',
        render: (calendarSlots: Array<any>, row: any) => {
            let availabilityFrom = moment(row.availabilityFrom);
            let slotData = calendarSlots.map((item, index) => {
                return getSlotImg(index === 0 ? availabilityFrom.hour() : index % 2 === 0 ? availabilityFrom.add(1, 'hour').hour() : undefined, item === 0, (index + 1) % 24 === 0 ? availabilityFrom.hour() + 1 : undefined)
            });
            return (
                <div style={{minWidth: 375}}>
                    <Row>
                        {slotData.slice(0, 24)}
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        {slotData.slice(24, 48)}
                    </Row>
                </div>
            );
        }
    },
    {
        title: 'Building',
        dataIndex: 'buildingId',
        render: (buildingId: any) => buildingList.find(b => b.buildingId === buildingId)?.name
    },
    {
        title: 'People',
        dataIndex: 'capacity',
        render: (capacity: any) => (
            <Row gutter={5} style={{flexFlow:'nowrap', minWidth: 75}}>
                <Col>
                    {peopleIcon}
                </Col>
                <Col>
                    {capacity}
                </Col>
            </Row>
        )
    },
    {
        title: 'Pricing per hour',
        dataIndex: 'hourlyPrice',
        render: (hourlyPrice: string) => (
            <Row gutter={6} style={{flexFlow:'nowrap'}}>
                <Col>
                    {priceTagIcon}
                </Col>
                <Col>
                    ${hourlyPrice}
                </Col>
            </Row>
        )
    },
    {
        title: "",
        dataIndex: 'spaceId',
        key: 'spaceId',
        fixed: 'right',
        render: (spaceId: string, space: any) => (
            <Row style={{flexFlow:'nowrap'}}>
                <Button icon={editIcon} type='text' onClick={() => onEditSpaceAction(space)} />
                <Popover trigger='hover' placement='left' content={
                    <div className='intnnt-ppvr'>
                        <Button className='intnnt-ppvr-btn' type='text' icon={bookIcon} onClick={() => onBookSpaceAction(space)}>Book space</Button>
                        <Button className='intnnt-ppvr-btn' type='text' danger icon={deleteIcon} onClick={() => onDeleteSpaceAction(space)}>Delete space</Button>
                    </div>}>
                    <div className="ind-more"><MoreOutlined className="more-settings" /></div>
                </Popover>
            </Row>
        )
    }
];

const getSlotImg = (startHour: number | undefined, isAvailable: boolean, endHour: number | undefined) => {
    return (
        <svg width="15" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.6" x="2" width="17" height="5" fill={!isAvailable ? "#E2E8F0" : "#1DC14B"} />
            {startHour !== undefined && <text x="-1" y="23" fill="#718096" style={{ fontSize: '10px' }}>{startHour}</text>}
            {startHour !== undefined && <line x1="2" y1="7" x2="2" y2="12" stroke="#CBD5E0" />}
            {endHour && <line x1="18" y1="7" x2="18" y2="12" stroke="#CBD5E0" />}
            {endHour && <text x="8" y="23" fill="#718096" style={{ fontSize: '10px' }}>{endHour}</text>}
        </svg>
    )
}