import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, FormInstance, Input, Row, Select, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { INDUCTION_MODAL_TYPE, INDUCTION_STATUS_TYPE } from './constants';
import { notifyConfirmation, notifyError } from '../utils/notification';
import { getBuildingTenants } from '../utils/common-utils';

const { TextArea } = Input;
const { Option } = Select;

type PropsType = {
    title?: string;
    description?: string;
    buildingId?: string;
    setBuildingId: Function;
    tenants?: Array<string>;
    setTenants: Function;
    onChange: Function;
    addNewInduction: Function;
    btnDisable: boolean;
    discard: Function;
    form: FormInstance;
    mode: string;
}

const InductionFormView = (props: PropsType) => {
    const {
        title,
        description,
        buildingId,
        setBuildingId,
        tenants,
        setTenants,
        onChange,
        addNewInduction,
        btnDisable,
        discard,
        form,
        mode
    } = props

    const [options, setOptions] = useState<Array<any>>([]);
    const [allTenantIds, setAllTenantIds] = useState<Array<any>>();
    const [isValidate, setIsValidate] = useState(true);

    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const allTenants: any = state.get(SESSION_ATTRS.TENANTS);

    const onInductionPost = (state: any) => {
        if (state === INDUCTION_STATUS_TYPE.DRAFT) {
            setIsValidate(false);
        }
        else {
            setIsValidate(true);
        }
        form.validateFields()
            .then(() => {
                addNewInduction(state);
            })
            .catch(() => {
                notifyError('Something went wrong!');
            })
    }

    useEffect(() => {
        const loadOptions = (allTenants: Array<{ tenantId: any, name: string }>) => {
            const tenantsIds: any = allTenants.map((tenant: any) => tenant.tenantId);
            setAllTenantIds(tenantsIds)
            const options: Array<{ label: string, value: any }> = allTenants.map(tenant => { return { label: tenant.name, value: tenant.tenantId } });
            setOptions([{ label: 'All companies', value: 'all' }, ...options]);
        }

        if (!!buildingId) {
            loadOptions(getBuildingTenants(allTenants, buildingId));
        }
    }, [buildingId, allTenants]);

    const tenatSelectProps = {
        mode: 'multiple' as const,
        style: { width: '100%' },
        value: tenants,
        options,
        onChange: (value: string[]) => {
            if (value[value.length - 1] === 'all') {
                onChange(setTenants, allTenantIds);
            }
            else {
                onChange(setTenants, value);
            }
        },
        placeholder: 'Select companies',
        maxTagCount: 'responsive' as const,
    }

    return (
        <Space direction='vertical' align='baseline' className='intnnt-spc intnnt-spc-wdth'>
            <Row className='intnnt-mrgn-tp-40'>
                <p className='intnnt-sbttl'>Information</p>
            </Row>
            <Form
                name='induction-information-form'
                layout={'vertical'}
                initialValues={{ title: title, description: description, buildingId: buildingId, tenats: tenants }}
                requiredMark={false}
                form={form}
            >
                <Form.Item
                    name='title'
                    label='Title'
                    rules={[{ required: isValidate, message: 'Please input the title!' }]}
                >
                    <Input
                        placeholder='Add a title that describe your video'
                        className='intnnt-npt'
                    />
                </Form.Item>
                <Form.Item
                    name='description'
                    label='Description'
                    rules={[{ required: isValidate, message: 'Please input the description!' }]}
                >
                    <TextArea
                        rows={4}
                        placeholder='Tell viewers about your video'
                        className='intnnt-npt'
                    />
                </Form.Item>
                <Form.Item
                    name='buildingId'
                    label='Access'
                    rules={[{ required: true, message: 'Please select a building!' }]}
                >
                    <Select
                        placeholder='Select a building'
                        className='intnnt-npt'
                        onChange={(value) => { setTenants(); onChange(setBuildingId, value); }}
                        disabled={mode === INDUCTION_MODAL_TYPE.EDIT}
                    >
                        {buildings.map((building: any) => {
                            return (
                                <Option key={building.buildingId} value={building.buildingId}>
                                    <div>
                                        <p className='intnnt-bldng-nm'>{building.name}</p>
                                        <p className='intnnt-bldng-lctn'>{building.geolocation}</p>
                                    </div>
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    name='tenants'
                    label='Tenant'
                    rules={[{ required: isValidate, message: 'Please select companies!' }]}
                    valuePropName={'tenants'}
                    initialValue={tenants}
                >
                    <Select
                        showSearch
                        className='intnnt-npt'
                        disabled={!buildingId}
                        {...tenatSelectProps}
                    />
                </Form.Item>
            </Form>
            <Row className='intnnt-btn-rw'>
                <Col className='link-btn-wrapper'>
                    <Button
                        type='text'
                        icon={<DeleteOutlined className='intnnt-btn-dngr' />}
                        className='link-btn link-btn--danger'
                        disabled={btnDisable}
                        onClick={() => notifyConfirmation('Continuing this action will discard your data', 'Discard', discard)}
                    >
                        Discard
                    </Button>
                </Col>
                <Col>
                    <Button
                        className='btn btn--o-primary'
                        onClick={() => onInductionPost(INDUCTION_STATUS_TYPE.DRAFT)}
                        disabled={btnDisable}
                    >
                        Draft
                    </Button>
                    <Button
                        className='btn btn--primary'
                        onClick={() => onInductionPost(INDUCTION_STATUS_TYPE.PUBLISH)}
                        disabled={btnDisable}
                    >
                        Publish
                    </Button>
                </Col>
            </Row>
        </Space>
    );
}

export default InductionFormView;