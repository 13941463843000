import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { awsAmplifyApiInit } from './utils/aws-configure';
import { SessionStateProvider } from './store/session';
import Amplify from '@aws-amplify/core';
import { AWS_CONFIG } from './config/app-config';

Amplify.configure(AWS_CONFIG);
awsAmplifyApiInit();

ReactDOM.render(
  <SessionStateProvider>
    <App />
  </SessionStateProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
