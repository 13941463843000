
import Resizer from "react-image-file-resizer";
import { getFileExtension } from "./common-utils";
import { notifyWarning } from "./notification";

export const IMAGE_EXTENTION_STRING = 'jpg_png_jpeg';
export const THUMBNAIL_MAX_WIDTH = 400;
export const THUMBNAIL_MAX_HEIGHT = 400;
export const THUMBNAIL_ASPECT_RATIO = 2 / 1;

export const resizeImageFile = (file: File, maxWidth = 300, maxHeight = 300) => new Promise((resolve) => {
    Resizer.imageFileResizer(file, maxWidth, maxHeight, "JPEG", 100, 0, (uri) => resolve(uri), "file");
});


export const isValidImage = (file: any, setIsValidResolution: Function | undefined, maxFileSizeMb = 10, minWidth = 640, minHeight = 480) => {
    const ext = getFileExtension(file.name);
    if (!ext || !IMAGE_EXTENTION_STRING.includes(ext.toLowerCase())) {
        notifyWarning("Incorrect file type!");
        return false;
    }
    if (maxFileSizeMb) {
        const fileSize = file.size / 1000000;
        if (fileSize > maxFileSizeMb) {
            notifyWarning("File size exceeded!");
            return false;
        }
    }
    if (setIsValidResolution && minWidth && minHeight) {
        checkResolution(file, setIsValidResolution, minWidth, minHeight);
    }
    return true;
}

export const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const checkResolution = async (file: any, setIsValidResolution: Function, minWidth: Number, minHeight: Number) => new Promise((resolve) => {
    var reader = new FileReader();
    reader.onload = function (e) {
        var img: any = new Image();
        img.onload = function () {
            if (img.width >= minWidth && img.height >= minHeight) {
                setIsValidResolution(true);
            } else {
                setIsValidResolution(false);
            }
        };
        img.src = reader.result;
    };
    reader.readAsDataURL(file);
});