import { Modal, Row, Col, Spin, Form } from 'antd';
import { FloorFormView } from './FloorFormView';
import { FloorImageUploadView } from './FloorImageUploadView';
import {createFloor, deleteImage, getFloor, updateFloor, uploadImage} from '../../actions';
import { useEffect, useState } from 'react';
import { ulid } from 'ulid';
import { notifiableAPICall, notifyError } from '../../../utils/notification';
import { DESK_KEY } from '../../constants';

export const FloorModal = ({ onClose, floor, onAdd, onSave }: any) => {
    const [imageList, setImageList] = useState<any>([]);
    const [floorDetails, setFloorDetails] = useState<any>();
    const [isLoading, setIsLoading] = useState(!!floor);
    const [form] = Form.useForm();
    let isEditView = floor !== undefined;

    useEffect(() => {
        if (isEditView) {
            getFloor(floor.buildingId, floor.floorId).then(fl => {
                setImageList([{ uid: fl.image, isOld: true, url: fl.imageLink }]);
                setFloorDetails(fl);
                setIsLoading(false);
            });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCreateFloor = async () => {
        try {
            let floorData = await form.validateFields();
            if (imageList.length === 0) {
                notifyError(`Floor planner image can't be empty!`);
                return;
            }
            setIsLoading(true);
            floorData.floorId = ulid();
            let uploadPromises = await uploadImage(imageList[0], floorData.floorId, false);
            floorData.image = await notifiableAPICall(async () => uploadPromises,
                'thumb-upload',
                'Uploading images...',
                'Images uploaded successfully',
                'Something went wrong!'
            );
            //approvalRequired is showing to user as auto approval, so we need to get !
            let { success } = await notifiableAPICall(async () => await createFloor({ ...floorData, approvalRequired: !floorData.approvalRequired }),
                'create-floor',
                'Creating floor...',
                'Floor created successfully',
                'Something went wrong!'
            );
            if (success) {
                onAdd();
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    const onSaveFloor = async () => {
        try {
            let floorData = await form.validateFields();
            if (imageList.length === 0) {
                notifyError(`Floor planner image can't be empty!`);
                return;
            }
            setIsLoading(true);
            floorData.floorId = floorDetails.floorId;
            let imagesToUpload = false;

            let uploadPromises = imageList[0].uid;
            if (!imageList[0].isOld) {
                imagesToUpload = true;
               uploadPromises = await uploadImage(imageList[0], floorData.floorId, false);
            }

            const newImageIds = imageList[0].uid;

            floorData.image = imagesToUpload ? await notifiableAPICall(async () => await uploadPromises,
                'img-upload',
                'Uploading images...',
                'Images uploaded successfully',
                'Something went wrong!'
            ) : newImageIds;

            let imagesToDelete = (floorDetails.image !== floorData.image) && floorDetails.image;
            if (imagesToDelete) {
                let deletePromises = await deleteImage(floorData.floorId, imagesToDelete, DESK_KEY);
                await notifiableAPICall(async () => await deletePromises,
                    'img-delete',
                    'Deleting removed images...',
                    'Images deleted successfully',
                    'Something went wrong!'
                );
            }
            //approvalRequired is showing to user as auto approval, so we need to get !
            let { success } = await notifiableAPICall(async () => await updateFloor({ ...floorData, approvalRequired: !floorData.approvalRequired }),
                'update-floor',
                'Saving changes...',
                'Changes saved successfully',
                'Something went wrong!'
            );
            if (success) {
                onSave();
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    return (
        <Modal
            visible={true}
            onCancel={onClose}
            closable={false}
            footer={null}
            width={890}
            bodyStyle={{
                padding: 0
            }}
            style={{
                borderRadius: 10,
                overflow: 'hidden'
            }}
            className='intnnt-mdl bs-add-space-mdl'
            centered
            maskClosable={false}
        >
            <Spin spinning={isLoading} size={'large'}>
                <Row className='intnnt-rw'>
                    <Col className='intnnt-clmn intnnt-clmn-left'>
                        {(!isEditView || (isEditView && floorDetails)) && <FloorFormView form={form} floor={floorDetails} />}
                    </Col>
                    <Col className='intnnt-clmn intnnt-clmn-right intnnt-drk-10'>
                        {(!isEditView || (isEditView && floorDetails)) &&
                            <FloorImageUploadView
                                imageList={imageList}
                                setImageList={setImageList}
                                onCreate={onCreateFloor}
                                onSave={onSaveFloor}
                                onCancel={onClose}
                                floor={floorDetails}
                            />
                        }
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
};
