import { Button, Row, Space, Upload, Image as AntdImage, Col, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { notifyConfirmation, notifyWarning, confirm, notifiableAPICall, notifyError, notifySuccess } from '../../../utils/notification';
import { useState } from 'react';
import { DragUploadBox, ImageUploadBox, ThumbUploadBox } from './UploadBoxes';
import ImgCrop from 'antd-img-crop';
import { deleteSpace } from '../../actions';
import { getBase64, isValidImage, resizeImageFile, THUMBNAIL_ASPECT_RATIO, THUMBNAIL_MAX_HEIGHT, THUMBNAIL_MAX_WIDTH } from '../../../utils/img-utils';


const { Dragger } = Upload;

export const ImageUploadView = ({ thumbnailImg, setThumbnailImg, imageList, setImageList, btnDisable, onCreate, onCancel, space, onSave }: any) => {
    const [isValidResolution, setIsValidResolution] = useState(true);
    let isEditView = space !== undefined;
    const [previewImg, setPreviewImg] = useState<any>();
    const thumbUploadView =
        <ImgCrop aspect={THUMBNAIL_ASPECT_RATIO} quality={1} beforeCrop={file => isValidImage(file, setIsValidResolution)}>
            <Dragger
                name='file'
                multiple={false}
                beforeUpload={(file: File) => {
                    if (!isValidResolution) {
                        notifyWarning("Minimum resolution allowed is 640x480!");
                    } else {
                        resizeImageFile(file, THUMBNAIL_MAX_WIDTH, THUMBNAIL_MAX_HEIGHT).then(newFile => getBase64(newFile).then(base64 => setThumbnailImg({ file: newFile, base64 })));
                    }
                    return false;
                }}
                showUploadList={false}
                className='thumb-upload'
                accept="image/*"
            >
                <ThumbUploadBox />
            </Dragger >
        </ImgCrop >

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImg({ url: file.url || file.preview, name: file.name });
    };

    const onDeleteSpaceAction = (space: any) => {
        confirm("Delete Space", `Are you sure you want to delete this space (${space.name})?`,
            async () => {
                let { success, hasBookings } = await notifiableAPICall(async () => await deleteSpace(space.buildingId, space.spaceId),
                    "delete-space",
                    "Deleting space...",
                    undefined,
                    "Something went wrong in deleting space. Please retry."
                );
                if (success) {
                    notifySuccess('Space deleted successfully');
                    onCancel();
                }
                else if (hasBookings) notifyError("Can't delete a space which has bookings");
            }, () => { });
    }

    return (
        <Space direction='vertical' className='intnnt-spc img-upload'>
            <Row>
                <p className='intnnt-sbttl'>Upload Image</p>
                {thumbnailImg && <Button onClick={() => setThumbnailImg(undefined)} icon={<DeleteOutlined color='#E83034' />} type='text' style={{ paddingRight: 0, color: '#E83034' }} >Remove</Button>}
            </Row>
            <Row >
                <div className='intnnt-upld-thmbnl'>
                    {!thumbnailImg && thumbUploadView}
                    {thumbnailImg && <AntdImage width={'100%'} src={thumbnailImg?.base64 || thumbnailImg?.url} />}
                </div>
                <ImgCrop aspect={2 / 1} quality={1} beforeCrop={file => isValidImage(file, setIsValidResolution)} >
                    <Upload
                        listType={imageList.length > 0 ? "picture-card" : undefined}
                        fileList={imageList}
                        onPreview={handlePreview}
                        accept="image/*"
                        beforeUpload={(file: any) => {
                            if (!isValidResolution) {
                                notifyWarning("Minimum resolution allowed is 640x480!");
                            } else {
                                getBase64(file).then(base64 => { file.url = base64; setImageList([...imageList, file]) });
                            }
                            return false;
                        }}
                        onRemove={(file: any) => { setImageList(imageList.filter((item: any) => item.uid !== file.uid)); return true; }}
                    >
                        {imageList.length > 0 && (imageList.length >= 16 ? null : <ImageUploadBox />)}
                        {imageList.length === 0 && <DragUploadBox />}
                    </Upload>
                </ImgCrop>
                {previewImg &&
                    <Modal visible={true} title={previewImg.name} footer={null} onCancel={() => setPreviewImg(undefined)} >
                        <img alt="preview" style={{ width: '100%', padding: 15 }} src={previewImg.url} />
                    </Modal>
                }
            </Row>
            <Row className='intnnt-btn-rw'>
                <Col span={8} style={{ alignSelf: 'center' }}>
                    <Button
                        type='text'
                        icon={<DeleteOutlined className='intnnt-btn-dngr' />}
                        className='link-btn link-btn--danger'
                        disabled={btnDisable}
                        onClick={() => isEditView ? onDeleteSpaceAction(space) : notifyConfirmation('Continuing this action will discard your data', 'Discard', onCancel)}
                    >
                        {isEditView ? 'Delete' : 'Discard'}
                    </Button>
                </Col>
                <Col span={12} pull={4} style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                    {isEditView ?
                        <Button
                            className='btn btn--o-primary'
                            onClick={() => notifyConfirmation('Continuing this action will discard your data', 'Discard', onCancel)}
                            disabled={btnDisable}
                        >
                            Cancel
                        </Button>
                        : null
                    }
                    <Button style={{ paddingLeft: 10, paddingRight: 10 }} className='btn btn--primary' onClick={isEditView ? onSave : onCreate} disabled={btnDisable} >
                        {isEditView ? 'Save changes' : 'Create new space'}
                    </Button>
                </Col>
            </Row>
        </Space>
    );
}