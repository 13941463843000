import { Tag } from 'antd';
import { DESK_STATUS } from '../../constants';

export const getStatusTag = (status: number) => {
    switch (status) {
        case DESK_STATUS.NOT_AVAILABLE.value:
            return <Tag color='processing'>{DESK_STATUS.NOT_AVAILABLE.label}</Tag>;
        case DESK_STATUS.HIDDEN.value:
            return <Tag color='warning'>{DESK_STATUS.HIDDEN.label}</Tag>;
        default:
            return <Tag color='success'>{DESK_STATUS.AVAILABLE.label}</Tag>;
    }
}
