import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Progress, Row, Space, Typography, Upload, Image } from 'antd';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { S3_METHODS, uploadFile } from '../utils/api-utils';
import { getSignedUrl } from './actions';
import { getFileExtension } from '../utils/common-utils';
import ReactPlayer from 'react-player/lazy';
import { IMAGE_EXTENTION_STRING, INDUCTION_MODAL_TYPE, VIDEO_EXTENTION_STRING } from './constants';
import { notifyWarning } from '../utils/notification';
import Icon from '../components/Icon';
const axios = require('axios').default;

const cancelToken = axios.CancelToken;

const { Title } = Typography;
const { Dragger } = Upload;

type Props = {
    videoKey: string | undefined;
    setVideoKey: Function;
    thumbKey: string | undefined;
    setThumbKey: Function;
    inductionId: any;
    mode: string;
    mediaFileName: string;
    setPrevVideoKey: Function;
    setPrevThumbKey: Function;
};

const VideoUploadView = (props: Props) => {

    const {
        inductionId,
        videoKey,
        setVideoKey,
        thumbKey,
        setThumbKey,
        mode,
        mediaFileName,
        setPrevVideoKey,
        setPrevThumbKey
    } = props;

    const [videoUploading, setVideoUploading] = useState(false);
    const [vidUploadSuccess, setVidUploadSuccess] = useState(false);
    const [videoProgress, setVideoProgress] = useState(0);
    const [videoFile, setVideoFile] = useState<File>();
    const [videoUrl, setVideoUrl] = useState();
    const [imageUploading, setImageUploading] = useState(false);
    const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
    const [imageProgress, setImageProgress] = useState(0);
    const [imageFile, setImageFile] = useState<File>();
    const [thumbUrl, setThumbUrl] = useState();

    const videoCancelRef = useRef({ source: null });
    const imageCancelRef = useRef({ source: null });

    useEffect(() => {

        const getVid = async (key: string) => {
            const getUrl = await getMediaUrl(key);
            setVideoUrl(getUrl);
            setVideoUploading(true);
            setVidUploadSuccess(true);
        }

        const getThumb = async (key: string) => {
            const getUrl = await getMediaUrl(key);
            setThumbUrl(getUrl);
            setImageUploading(true);
            setImageUploadSuccess(true);
        }
        if (mode === INDUCTION_MODAL_TYPE.EDIT) {
            if (!!videoKey) {
                getVid(videoKey);
            }
            if (!!thumbKey) {
                getThumb(thumbKey);
            }
        }
    }, [mode, videoKey, thumbKey]);

    const uploadVideo = async (file: File, key: string) => {
        const videoSource = cancelToken.source();
        videoCancelRef.current.source = videoSource;
        const url = await getSignedUrl(key, file.type, S3_METHODS.PUT, 3600);
        await uploadFile(url, file, file.type, videoUploadProgress, videoSource.token)
            .then(async res => {
                setVideoKey(key);
                setVideoProgress(0);
                const getUrl = await getMediaUrl(key);
                setVideoUrl(getUrl);
                setVidUploadSuccess(true);
            })
            .catch(err => {
                console.error("err :", err);
            });
    }

    const uploadThumb = async (file: File, key: string) => {
        const imageSource = cancelToken.source();
        imageCancelRef.current.source = imageSource;
        const url = await getSignedUrl(key, file.type, S3_METHODS.PUT, 900);
        await uploadFile(url, file, file.type, imageUploadProgress, imageSource.token)
            .then(async res => {
                setThumbKey(key);
                setImageProgress(0);
                const getUrl = await getMediaUrl(key);
                setThumbUrl(getUrl);
                setImageUploadSuccess(true);
            })
            .catch(err => {
                console.error("err :", err);
            });

    }

    const videoUploadProgress = (progressEvent: ProgressEvent) => {
        let uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        setVideoProgress(uploadPercentage);
    }

    const imageUploadProgress = (progressEvent: ProgressEvent) => {
        let uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        setImageProgress(uploadPercentage);
    }

    const uploadCancel = (source: any, setUploading: Function, setFile: Function, setProgress: Function) => {
        source.cancel('Upload process abort!');
        setFile();
        setUploading(false);
        setProgress(0);
    }

    const getMediaUrl = async (key: string) => {
        const result = await getSignedUrl(key, undefined, S3_METHODS.GET, 1800);
        return result;
    }

    const onDiscardVideo = async () => {
        setVideoUploading(false);
        setVidUploadSuccess(false);
        setVideoFile(undefined);
        setPrevVideoKey(videoKey);
        setVideoKey();
    }

    const onDiscardThumb = async () => {
        setImageUploading(false);
        setImageUploadSuccess(false);
        setImageFile(undefined);
        setPrevThumbKey(thumbKey)
        setThumbUrl(undefined);
        setThumbKey();
    }

    const videoDraggerProps = {
        name: 'file',
        multiple: false,
        beforeUpload: (file: File, fileList: any) => {
            const fileSize = file.size / 1000000;
            if (fileSize > 100) {
                notifyWarning("File size exceeded!");
                return false;
            }
            const ext = getFileExtension(file.name);
            if (!ext || !VIDEO_EXTENTION_STRING.includes(ext.toLowerCase())) {
                notifyWarning("incorrect file type!");
                return false;
            }
            setVideoUploading(true);
            setVideoFile(file);
            const key = `${inductionId}/${mediaFileName}.${ext}`;
            uploadVideo(file, key);
            return false;
        },
        showUploadList: false
    };

    const thumbnailDraggerProps = {
        name: 'file',
        multiple: false,
        beforeUpload: (file: File, fileList: any) => {
            const ext = getFileExtension(file.name);
            if (!ext || !IMAGE_EXTENTION_STRING.includes(ext.toLowerCase())) {
                notifyWarning("incorrect file type!");
                return false;
            }
            setImageUploading(true);
            setImageFile(file)
            const key = `${inductionId}/${mediaFileName}.${ext}`;
            uploadThumb(file, key);
            return false;
        },
        showUploadList: false
    };

    const videoUploadprogressView = !vidUploadSuccess ?
        <>
            <Card className='intnnt-prgrss-crd' bodyStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Progress type='circle' percent={videoProgress} width={40} strokeWidth={6} strokeColor={'#09B95B'} />
                <p className='intnnt-prgrss-fl-nm'>{videoFile?.name}</p>
                <p className='intnnt-prgrss-fl-sz'>{Math.round((videoFile?.size ? videoFile?.size : 1) / 1000000)}MB</p>
            </Card>
        </>
        :
        <>
            <ReactPlayer
                url={videoUrl}
                light={thumbUrl}
                controls={true}
                width={'100%'}
                height={215}
                playing={true}
            />
        </>
        ;

    const videoUploadView = !videoUploading ?
        <Dragger {...videoDraggerProps} className='intnnt-drggr' accept='video/*'>
            <p className="ant-upload-drag-icon">
                <Icon icon="upload-cloud" width="50px" height="50px"/>
            </p>
            <p className="ant-upload-text">Drag & Drop to upload or</p>
            <p className="new-user-upload-hint margin-bottom-10">browse from your device</p>
            <p className="ant-upload-text">Only .MP4 .MOV and .AVI formats</p>
            <p className="ant-upload-text">with max size of 100MB</p>
        </Dragger>
        :
        videoUploadprogressView
        ;

    const imageUploadProgressView = !imageUploadSuccess ?
        <>
            <Card className='intnnt-prgrss-crd' bodyStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Progress type='circle' percent={imageProgress} width={40} strokeWidth={6} strokeColor={'#09B95B'} />
                <p className='intnnt-prgrss-fl-nm'>{imageFile?.name}</p>
                <p className='intnnt-prgrss-fl-sz'>{Math.round((imageFile?.size ? imageFile?.size : 1) / 1000000)}MB</p>
            </Card>
        </>
        :
        <>
            <Image
                width={'100%'}
                src={thumbUrl}
            />
        </>

    const thumbUploadView = !imageUploading ?
        <Dragger {...thumbnailDraggerProps} className='intnnt-drggr-thmbnl' accept="image/*">
            <p className="ant-upload-drag-icon">
                <Icon icon="upload-image" width="50px" height="50px"/>
            </p>
            <p className="ant-upload-text">Upload thumbnail</p>
        </Dragger>
        :
        imageUploadProgressView
        ;

    const videoControlBtn = videoUploading ? !vidUploadSuccess ?
        <div>
            <Button icon={<CloseOutlined />} type='text' style={{ paddingRight: 0 }} onClick={() => { uploadCancel(videoCancelRef.current.source, setVideoUploading, setVideoFile, setVideoProgress) }}>Cancel</Button>
        </div>
        :
        <div>
            <Button icon={<DeleteOutlined color='#E83034' />} type='text' style={{ paddingRight: 0, color: '#E83034' }} onClick={() => onDiscardVideo()}>Remove</Button>
        </div>
        :
        null;

    const thumbControlBtn = imageUploading ? !imageUploadSuccess ?
        <div>
            <Button icon={<CloseOutlined />} type='text' style={{ paddingRight: 0 }} onClick={() => { uploadCancel(imageCancelRef.current.source, setImageUploading, setImageFile, setImageProgress) }}>Cancel</Button>
        </div>
        :
        <div>
            <Button icon={<DeleteOutlined color='#E83034' />} type='text' style={{ paddingRight: 0, color: '#E83034' }} onClick={() => onDiscardThumb()}>Remove</Button>
        </div>
        :
        null;

    return (
        <Space direction='vertical' className='intnnt-spc'>
            <Row>
                <Title level={4}>Upload New Video</Title>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className='intnnt-sbttl'>Upload video</p>
                {
                    videoControlBtn
                }
            </Row>
            <Row >
                <div className='intnnt-upld'>
                    {videoUploadView}
                </div>
            </Row>
            <Row>
                <p className='intnnt-sbttl'>Upload Thumbnail</p>
                {
                    thumbControlBtn
                }
            </Row>
            <Row >
                <div className='intnnt-upld-thmbnl'>
                    {thumbUploadView}
                </div>
            </Row>
        </Space>
    );
}

export default VideoUploadView;