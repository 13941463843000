import { getApi, APIS, postApi, putApi, S3_METHODS } from "../utils/api-utils";
import { INDUCTION_STATUS_TYPE } from "./constants";
import download from 'downloadjs';

export const getInductions = async (state = INDUCTION_STATUS_TYPE.PUBLISH, buildingId: string) => {
    const { inductionList } = await getApi(APIS.INDUCTION_API, '/inductions', { queryStringParameters: { state, buildingId } });
    return inductionList;
}

export const getSignedUrl = async (key: string, contentType: string | undefined, method: string, expires: number) => {
    const params = {
        key: key,
        contentType: contentType,
        method: method,
        expires: expires
    }
    const { url } = await getApi(APIS.INDUCTION_API, '/inductions/signedurl', { queryStringParameters: params });
    return url;
}

export const createInduction = async (params: any) => {
    return await postApi(APIS.INDUCTION_API, '/induction', params);
}

export const discardIduction = async (params: any) => {
    return await postApi(APIS.INDUCTION_API, '/inductions/discard', params);
}

export const changeState = async (inductionId: string, buildingId: string, state: number, prevState: number, updatedBy:any) => {
    return await putApi(APIS.INDUCTION_API, '/inductions/state', { inductionId, buildingId, state, prevState, updatedBy: updatedBy });
}

export const downloadVideo = async (key: string, name: string) => {
    const ext = key.split('.').pop();
    const url = await getVideo(key);
    const res = await fetch(url);
    const data = await res.blob();
    download(data, `${name}.${ext}`);
}

export const getThumbnail = async (key: string) => {
    return await getSignedUrl(key, undefined, S3_METHODS.GET, 300);
}

export const getVideo = async (key: string) => {
    return await getSignedUrl(key, undefined, S3_METHODS.GET, 1800);
}