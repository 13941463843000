import React, { createContext, Dispatch, ReactNode, Reducer, useReducer } from 'react';
import { Map as IMap } from 'immutable';

type StateType = IMap<string, unknown>;

type ActionType = {
    type: string,
    payload: any
}

type ReducerType = Reducer<StateType, ActionType>;

export type DispatchType = Dispatch<ActionType>;

type PropsType = {
    children: ReactNode
}

type ContextType = {
    state: StateType,
    dispatch: DispatchType
}

const ACTIONS = {
    BUILDINGS_LOADED: "building_loaded",
    TENANTS_LOADED: "tenants_loaded",
    LOGGED_IN: "logged_in",
    SELECTED_NEWSFEED_TYPE_CHANGED: "selected_newsfeed_type_changed"
}

export const SESSION_ATTRS = {
    BUILDINGS: "buildings",
    TENANTS: "tenants",
    BUILDING_ID_NAMES: "buidlingIdNames",
    TENANT_ID_NAMES: "tenantIdNames",
    LOGGED_USER: "loggedUser",
    LOGGED_TENANT: "loggedTenant",
    SELECTED_NEWSFEED_TYPE : "selectedNewsfeedType"
}

const initialState = IMap({
    [SESSION_ATTRS.BUILDINGS]: [],
    [SESSION_ATTRS.TENANTS]: [],
    [SESSION_ATTRS.BUILDING_ID_NAMES]: {},
    [SESSION_ATTRS.TENANT_ID_NAMES]: {},
    [SESSION_ATTRS.LOGGED_USER]: {},
    [SESSION_ATTRS.LOGGED_TENANT]: {},
    [SESSION_ATTRS.SELECTED_NEWSFEED_TYPE]: undefined
});


const sessionStore = createContext<ContextType>({ state: initialState, dispatch: () => null });
const { Provider } = sessionStore;

const toIdNameMap = (array: Array<any>, idColumn: string, nameColumn: string) => {
    return array.reduce((map, curr) => {
        const id = curr[idColumn];
        const name = curr[nameColumn];
        if (!map[id]) {
            map[id] = name;
        }
        return map;
    }, {});
};

const SessionStateProvider: Function = ({ children }: PropsType) => {

    const [state, dispatch] = useReducer<ReducerType>((state: StateType, action: ActionType): StateType => {
        const { type, payload } = action;
        switch (type) {
            case ACTIONS.BUILDINGS_LOADED:
                return state.merge(IMap({
                    [SESSION_ATTRS.BUILDINGS]: payload,
                    [SESSION_ATTRS.BUILDING_ID_NAMES]: toIdNameMap(payload, "buildingId", "name")
                }));
            case ACTIONS.TENANTS_LOADED:
                const loggedUser: any = state.get(SESSION_ATTRS.LOGGED_USER);
                let loggedTenant = {};
                if (loggedUser) {
                    loggedTenant = payload.find((t: any) => t.tenantId === loggedUser.tenantId);
                }
                return state.merge(IMap({
                    [SESSION_ATTRS.TENANTS]: payload,
                    [SESSION_ATTRS.TENANT_ID_NAMES]: toIdNameMap(payload, "tenantId", "name"),
                    [SESSION_ATTRS.LOGGED_TENANT]: loggedTenant
                }));
            case ACTIONS.LOGGED_IN:
                return state.merge(IMap({
                    [SESSION_ATTRS.LOGGED_USER]: payload
                }));
            case ACTIONS.SELECTED_NEWSFEED_TYPE_CHANGED:
                return state.merge(IMap({
                    [SESSION_ATTRS.SELECTED_NEWSFEED_TYPE]: payload
                }))
            default:
                throw new Error("action is not defined");
        }
    }, initialState);

    return <Provider value={{ state, dispatch }}  >{children}</Provider>;
};

export { sessionStore, SessionStateProvider, ACTIONS };