import { useState } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { resetNewPassword, sendForogotPasswordCode } from './actions';
import { notifyError, notifySuccess } from '../utils/notification';


type Props = {
    email: string;
    setEmail: Function;
    setIsLoginView: Function;
}

const ForgotPassword = (props: Props) => {

    const {
        email,
        setEmail,
        setIsLoginView
    } = props;

    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [codeSendView, setCodeSendView] = useState(false);

    const sendCode = async (userParams: any) => {
        setLoading(true);
        setErrorMessage('');
        setUserName(userParams.userName);
        const error = await sendForogotPasswordCode(userParams.userName.toLowerCase());
        if (error && error === "Username/client id combination not found.") {
          setErrorMessage("You are not a registered user");
          setLoading(false);
          return;
        }
        if (error) {
          setErrorMessage(error);
          setLoading(false);
          return;
        }
        setEmail(userParams.userName);
        setCodeSendView(true);
        setLoading(false);
      };

      const resetPassword = async (credentialParams: any) => {
        setLoading(true);
        setErrorMessage('');
        if (credentialParams.password !== credentialParams.confirmPassword) {
          setErrorMessage("Passwords are not matching");
          notifyError("Passwords are not matching");
          setLoading(false);
          return;
        }
        const error = await resetNewPassword(
          userName,
          credentialParams.code,
          credentialParams.password
        );
        if (
          error &&
          error === "Invalid verification code provided, please try again."
        ) {
          setErrorMessage("Invalid Verification Code,Please Try Again");
          notifyError("Invalid Verification Code, Please Try Again");
          setLoading(false);
          return;
        }
        if (error) {
          const errMsg = error.split(":")[1] ? error.split(":")[1] + (error.split(":")[2] ? error.split(":")[2] : "") : error; 
          setErrorMessage(errMsg);
          notifyError(errMsg);
          setLoading(false);
          return;
        }
        setLoading(false);
        setCodeSendView(false);
        setErrorMessage('');
        setIsLoginView(true);
        notifySuccess("Your password has been reset successfully. Please login with new password.")
      };

    const errorView = errorMessage ? (
        <div style={{color:'#f00'}} >{errorMessage}</div>
    ) : null;

    return (
        <div>
        {codeSendView ? (
            <Form layout="vertical" onFinish={resetPassword}>
              <Form.Item
                label="Verification code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Please input your verification code",
                  },
                ]}
              >
                <Input
                  placeholder="Please input your verification code"
                  disabled={loading ? true : false}
                  onChange={()=>setErrorMessage('')}
                ></Input>
              </Form.Item>
              <Form.Item
                label="New Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your new password",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter your new password"
                  disabled={loading ? true : false}
                  onChange={()=>setErrorMessage('')}
                />
              </Form.Item>
              <Form.Item
                label="Confirm New Password"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please re-enter your new password",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Re-enter your new password"
                  disabled={loading ? true : false}
                  onChange={()=>setErrorMessage('')}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="send-password-btn btn btn--primary"
                  htmlType="submit"
                  block
                  shape="round"
                  icon={
                    loading ? (
                      <Spin
                        indicator={<LoadingOutlined size={14} style={{color:'#fff'}}/>}
                      />
                    ) : null
                  }
                >
                  Reset
                </Button>
              </Form.Item>
              {errorView}
            </Form>
          ) : (
            <div>
              <div className="alert alert-info">
                <div><strong>Forgot Password?</strong></div>
                We will send you a verification code to your email.
              </div>
              <Form layout="vertical" validateTrigger={'onFinish'} onFinish={sendCode} initialValues={{userName: email}}>
                <Form.Item
                  label="Email"
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message:"Please enter your email"
                    },
                    {
                      type: 'email',
                      message: "Invalid email address",
                    }
                  ]}
                >
                  <Input
                    placeholder="Please input your email here"
                    disabled={loading ? true : false}
                  ></Input>
                </Form.Item>
                <Form.Item >
                  <Button
                    className="forgot-password-btn btn btn--primary"
                    htmlType="submit"
                    block
                    shape="round"
                    icon={
                      loading ? (
                        <Spin
                          indicator={<LoadingOutlined size={14} style={{color:'#fff'}}/>}
                        />
                      ) : null
                    }
                  >
                    Request Password Reset
                  </Button>
                </Form.Item>
                {errorView}
              </Form>
            </div>
          )}
        </div>
    );
}

export default ForgotPassword;