import { Layout, Menu, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import Icon from "../components/Icon";

const { Sider } = Layout;

interface propsType {
    menuCollapsed: boolean,
    selectedMenu: string,
    isDesktop: boolean,
    toggle: () => void,
    logoUrl: string
}


export const SideMenu = ({ menuCollapsed, selectedMenu, isDesktop, toggle, logoUrl }: propsType) => {
    const menuItems = (
        <Menu theme="light" mode="inline" defaultSelectedKeys={[selectedMenu]}>
            <Menu.Item key="users">
                <Link to="/users">
                    <Icon icon="users" hovered={true} />
                    Users
                </Link>
            </Menu.Item>
            <Menu.Item key="news-feed">
                <Link to="/news-feed">
                    <Icon icon="news-feed" hovered={true} />
                    Newsfeed
                </Link>
            </Menu.Item>
            <Menu.Item key="booking-space">
                <Link to="/booking-space">
                    <Icon icon="booking-space" hovered={true} />
                    Space Bookings
                </Link>
            </Menu.Item>
            <Menu.Item key="desk-booking">
                <Link to="/desk-booking">
                    <Icon icon="desk-booking" hovered={true} />
                    Desk Bookings
                </Link>
            </Menu.Item>
            <Menu.Item key="inductions">
                <Link to="/inductions">
                    <Icon icon="play-video" hovered={true} />
                    Induction Videos
                </Link>
            </Menu.Item>
            <Menu.Item key="building-contacts">
                <Link to="/building-contacts">
                    <Icon icon="building" hovered={true} />
                    Building Contacts
                </Link>
            </Menu.Item>
            <Menu.Item key="issues">
                <Link to="/issues">
                    <Icon icon="issues" hovered={true} />
                    Issues
                </Link>
            </Menu.Item>
        </Menu>);

    return (
        !isDesktop ? <Drawer
            placement="left"
            closable={false}
            onClose={toggle}
            width={250}
            visible={menuCollapsed}
            className="side-drawer"
        >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <div>
                    <div className="menu-logo" >
                        {logoUrl && <img src={logoUrl} alt="User-Icon" />}
                    </div>
                    {menuItems}
                </div>
                <div>
                    <div className="menu-int-logo" >
                        <img src="/images/inTenant.png" alt="InTenant-Icon" />
                    </div>
                    <div className='menu-links'>
                        <a href='/#'>FAQ</a>
                        <a href='/#'>Support</a>
                    </div>
                </div>
            </div>
        </Drawer> :
            <Sider trigger={null} collapsible collapsed={menuCollapsed} className="side-menu">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <div>
                        <div className="menu-logo" >
                            {logoUrl && <img src={logoUrl} alt="Tenant logo" />}
                        </div>
                        {menuItems}
                    </div>
                    <div>
                        <div className="menu-int-logo" >
                            <img src="/images/inTenant.png" alt="InTenant-Icon" />
                        </div>
                        <div className="menu-links">
                            <p><a href='https://www.buildingspacesgroup.com.au/intenant.html'>FAQ</a></p>
                            <p><a href='mailto:support@buildingspacesgroup.com.au'>Support</a></p>
                        </div>
                    </div>
                </div>
            </Sider>
    )
}
