import { Row, Select, Space, Input, Button, Typography, Radio } from 'antd';
import { useState, useContext, useEffect } from 'react';
import { BOOKING_STATUS, BOOKING_STATUS_ARRAY, BOOKING_TIME_FORMAT, FUTURE_BOOKING_CSV_EXPORT_FIELDS, VIEWS } from './constants';
import { PlusOutlined, RedoOutlined, ExportOutlined } from '@ant-design/icons';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { BookingRequests } from './BookingRequests';
import { DatePopup } from './DatePopup';
import { BookingSpaces } from './bookingSpaces/BookingSpaces';
import { getBookingRequests, getSpaceList } from './actions';
import { convertToCsv, stringSorter } from '../utils/common-utils';
import { BookingHistory } from './BookingHistory';
import { notifiableAPICall } from '../utils/notification';
import moment from 'moment';

const { Option } = Select;
const { Search } = Input;
const { Title } = Typography;
const opts = { fields: FUTURE_BOOKING_CSV_EXPORT_FIELDS }

export const ALL = "all";

export const BookingSpaceView = () => {
    const [selectedView, setSelectedView] = useState(VIEWS.BOOKINGS);
    const [prevSelectedView, setPrevSelectedView] = useState(VIEWS.BOOKINGS);
    const [selectedBuilding, setSelectedBuilding] = useState<string>(ALL);

    //bookings
    const [bookingStatusFilter, setBookingStatusFilter] = useState(BOOKING_STATUS_ARRAY[0].value);
    const [bookingSearchText, setBookingSearchText] = useState<string>();
    const [exportModalVisible, setExportModalVisible] = useState<boolean>(false);

    //spaces
    const [spaceSearchText, setSpaceSearchText] = useState<string>();
    const [newSpaceModalVisible, setNewSpaceModalVisible] = useState(false);
    const [spaceList, setSpaceList] = useState<any[] | undefined>();

    const { state } = useContext(sessionStore);
    const user: any = state.get(SESSION_ATTRS.LOGGED_USER);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const buildingIdNameMapping: any = state.get(SESSION_ATTRS.BUILDING_ID_NAMES);

    useEffect(() => {
        loadSpaces();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadSpaces = async () => {
        try {
            let spaces = await getSpaceList(user.buildingId);
            spaces.sort((s1: any, s2: any) => stringSorter(s1.name, s2.name));
            setSpaceList(spaces);
        } catch (e) {
            setSpaceList([]);
        }
    }

    const exportableRequests = (allRequests: any) => {
        let exportRequestsList = [];
        exportRequestsList = allRequests.filter((item: any) => item.reqStatus === BOOKING_STATUS.APPROVED || item.reqStatus === BOOKING_STATUS.PENDING)
            .map((item: any) => {
                let startTime = moment(item.startTime);
                let endTime = moment(item.endTime);
                let space = spaceList?.find(space => space.spaceId === item.spaceId);
                let erl = {
                    Building: buildingIdNameMapping[item.buildingId],
                    Space: space?.name,
                    'Start Time':startTime.format(BOOKING_TIME_FORMAT),
                    'End Time': endTime.format(BOOKING_TIME_FORMAT),
                    'Booking Duration (hours)': moment.duration(endTime.diff(startTime)).asHours(),
                    'Booked By': item.createdBy.name,
                    'Booking Reason': item.bookingReason,
                    'Approved By': item.reqStatus === BOOKING_STATUS.APPROVED ? item.approvedBy.name : "-",
                    'Status': item.reqStatus === BOOKING_STATUS.APPROVED ? "Approved" : "Pending",
                    'Per Hour Cost($)': space.hourlyPrice,
                }
                return erl;
            });

        return exportRequestsList;
    }

    const exportBookings = async (to: any) => {
        const bookingRequests = await notifiableAPICall(() => getBookingRequests(user.buildingId, '', undefined, undefined, to),
            'get_all_req',
            "Please wait...",
            "Booking requests loaded",
            "Something went wrong.",
            true);
        const data = exportableRequests(bookingRequests);
        convertToCsv(opts, data, "Future_Booking_Requests.csv");
        setExportModalVisible(false);
    }

    const changeToHistoryView = () => {
        setPrevSelectedView(selectedView);
        setSelectedView(VIEWS.HISTORY);
    }

    if (selectedView === VIEWS.HISTORY) {
        return <BookingHistory prevSelectedView={prevSelectedView} spaceList={spaceList} setSelectedView={setSelectedView} setSelectedBuilding={setSelectedBuilding} buildings={buildings} />;
    }

    return (
        <>
            {exportModalVisible &&
                <DatePopup
                    title="Export Upcoming Boookings"
                    okButtontext="Export"
                    onCancel={() => setExportModalVisible(false)}
                    onConfirm={exportBookings}
                    defaultDate={moment().add('1', 'month')}
                    label="Up To" />}
            <Space direction='vertical'>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Row style={{ marginTop: 15, justifyContent: 'space-between' }}>
                        <Title level={4}>
                            Space Bookings
                        </Title>
                        <div>
                            {selectedView === VIEWS.BOOKINGS ? <Button type='link' style={{ color: '#104EA1', fontSize: 12, fontWeight: 600 }} icon={<ExportOutlined style={{ color: '#104EA1', fontWeight: "bold" }} />} onClick={() => setExportModalVisible(true)}>Export .csv</Button> : null}
                            <Button type='link' style={{ color: '#104EA1', fontSize: 12, fontWeight: 600 }} icon={<RedoOutlined rotate={-90} style={{ color: '#104EA1', fontWeight: "bold" }} />} onClick={changeToHistoryView}>Booking history</Button>
                        </div>
                    </Row>
                    <Row className="grid-options">
                        <Space>
                            <Radio.Group value={selectedView} onChange={e => setSelectedView(e.target.value)} className='bs-tab-selector'>
                                <Radio.Button value={VIEWS.BOOKINGS} className='left-button'>Booking requests</Radio.Button>
                                <Radio.Button value={VIEWS.SPACES} className='right-button'>Space management</Radio.Button>
                            </Radio.Group>
                            <Select defaultValue={ALL} style={{ width: 160 }} onChange={setSelectedBuilding}>
                                <Option value={ALL}>All Buildings</Option>
                                {buildings.map((building: any) => (
                                    <Option key={building.buildingId} value={building.buildingId}>{building.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Space className="grid-options--search">
                            {selectedView === VIEWS.BOOKINGS &&
                                <>
                                    <Select value={bookingStatusFilter} onChange={setBookingStatusFilter} >
                                        {BOOKING_STATUS_ARRAY.map(({ filterName, value }: any) => (
                                            <Option key={value} value={value}>{filterName}</Option>
                                        ))}
                                    </Select>
                                    <Search placeholder="Search" allowClear onChange={(e) => setBookingSearchText(e.target.value)} className="grid-search-box" />
                                </>
                            }
                            {selectedView === VIEWS.SPACES &&
                                <>
                                    <Search placeholder="Search" allowClear onChange={(e) => setSpaceSearchText(e.target.value)} className="grid-search-box bs-grid-search-box" />
                                    <Button
                                        onClick={() => setNewSpaceModalVisible(true)}
                                        className="btn btn--primary"
                                    >
                                        Create new space
                                        <PlusOutlined />
                                    </Button>
                                </>
                            }
                        </Space>
                    </Row>
                </Space>
                {selectedView === VIEWS.BOOKINGS &&
                    <BookingRequests
                        spaceList={spaceList}
                        bookingStatus={bookingStatusFilter}
                        bookingSearchText={bookingSearchText}
                        buildings={buildings}
                    />}
                {selectedView === VIEWS.SPACES &&
                    <BookingSpaces
                        newSpaceModalVisible={newSpaceModalVisible}
                        setNewSpaceModalVisible={setNewSpaceModalVisible}
                        selectedBuilding={selectedBuilding}
                        searchPhrase={spaceSearchText}
                        spaceList={spaceList}
                        buildings={buildings}
                        loadSpaces={loadSpaces}
                    />
                }
            </Space >
        </>
    )
};
