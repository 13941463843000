import { useContext, useState } from "react";

import { Button, Form, Input, Modal, Select } from "antd";
import { ulid } from 'ulid';

import ContactNumberFormItem from "../components/ContactNumberFormItem";
import { sessionStore, SESSION_ATTRS } from "../store/session";
import { ROLES_ARRAY, BUILDING_CONTACT_STATE, BUILDING_CONTACT_MODAL_TYPE } from "./constants"
import { getUserObj } from '../utils/common-utils'
import { getDialCode } from "../utils/countryCodes";

const { Option } = Select;
const { TextArea } = Input;

type PropType = {
    onClose: Function,
    onSubmit: (data: any, mode: string) => Promise<any>,
    mode: string;
    editRecordData?: any;
}

const FormItem = Form.Item;

const BuildingContractModal = ({ onClose, onSubmit, mode, editRecordData }: PropType) => {

    const [form] = Form.useForm();
    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const [buildingContactId, setBuildingContactId] = useState<string>(editRecordData.buildingContactId);
    const [role, setRole] = useState(editRecordData.role);
    const [description, setDescription] = useState(editRecordData.description);
    const [buildingId, setBuildingId] = useState(editRecordData.buildingId);
    const [status, setStatus] = useState(editRecordData.state);
    const [countryCode, setCountryCode] = useState(editRecordData.countryCode);
    const [contactNumber, setContactNumber] = useState(editRecordData.contactNumber);
    const [createdBy] = useState<any>("");

    const onSubmitClicked = async () => {
        try {
            const values = await form.validateFields();

            let newUlid;
            if (!buildingContactId || buildingContactId === "") {
                newUlid = ulid();
                setBuildingContactId(newUlid);
            } else {
                newUlid = buildingContactId;
            }

            setRole(values.roles);
            setDescription(values.description);
            setBuildingId(values.access);
            setStatus(BUILDING_CONTACT_STATE.ACTIVE);
            setCountryCode(values.countryCode);
            setContactNumber(values.contactNumber);

            let params: any = {
                contactId: newUlid,
                buildingId: values.access,
                contactNumber: values.contactNumber,
                countryCode: values.countryCode,
                countryDialingCode: getDialCode(values.countryCode),
                role: values.roles,
                description: values.description,
                state: BUILDING_CONTACT_STATE.ACTIVE
            };

            let currUser: any = state.get(SESSION_ATTRS.LOGGED_USER);
            if (mode === BUILDING_CONTACT_MODAL_TYPE.CREATE) {
                params.createdBy = getUserObj(currUser);
            } else {
                params.createdBy = createdBy;
                params.updatedBy = getUserObj(currUser);
            }
            onSubmit(params, mode);
        } catch (e) {
            console.error(e);
        }
    }

    const rolesOptions = (
        ROLES_ARRAY.map(role => <Option key={role.value} value={role.value}>{role.name}</Option>)
    );

    const buildingOptions = (
        buildings.map((building: any) => (
            <Option key={building.buildingId} value={building.buildingId}>
                <div>
                    <p className='intnnt-bldng-nm'>{building.name}</p>
                    <p className='intnnt-bldng-lctn'>{building.geolocation}</p>
                </div>
            </Option>
        ))
    );

    const buttonText = (mode === BUILDING_CONTACT_MODAL_TYPE.EDIT) ? "Save" : "Add Building Contact";

    return (
        <Modal
            visible={true}
            footer={null}
            onCancel={() => onClose()}
            wrapClassName="add-new-building-contact-modal"
            maskClosable={false}
            centered
        >
            <h2 className="new-building-contact-title">Add Building Contact</h2>
            <div className="add-building-contact-wrp">
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={{
                        roles: role, description: description, access: buildingId,
                        countryCode: countryCode, contactNumber: contactNumber, buildingContactId: buildingContactId, state: status
                    }}
                >
                    <FormItem
                        label="Roles"
                        name="roles"
                        rules={[{ required: true, message: 'Please select a role' }]}
                    >
                        <Select placeholder="Please select a role">
                            {rolesOptions}
                        </Select>
                    </FormItem>
                    <ContactNumberFormItem placeHolder="Phone number" />
                    <FormItem
                        label="Access"
                        name="access"
                        rules={[{ required: true, message: 'Please select a building' }]}

                    >
                        <Select placeholder="Select a building"
                            disabled={mode === BUILDING_CONTACT_MODAL_TYPE.EDIT}>
                            {buildingOptions}
                        </Select>
                    </FormItem>
                    <FormItem
                        label="Description"
                        name="description"
                        rules={[{ required: false }]}
                    >
                        <TextArea rows={4} placeholder="Enter a short description" />
                    </FormItem>
                    <FormItem>
                        <Button className="btn btn--primary new-user-manual-btn" onClick={onSubmitClicked}> {buttonText}</Button>
                    </FormItem>
                </Form>
            </div>
        </Modal>
    );
}

export default BuildingContractModal;