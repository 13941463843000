import { API, Auth } from 'aws-amplify';
const axios = require('axios').default;

export const APIS = {
  BUILDING_API: 'buildingAPI',
  NEWS_API: 'newsAPI',
  INDUCTION_API: 'inductionAPI',
  TENANT_API: 'tenantAPI',
  USER_API: 'userAPI',
  BUILDING_CONTACTS_API : 'buildingContactAPI',
  BUILDING_SPACES_API : 'buildingSpacesAPI',
  ISSUES_API : 'issuesAPI',
  DESK_BOOKING_API : 'buildingDeskAPI'
}

export const S3_METHODS = {
  GET: 'get',
  PUT: 'put'
}

export async function getApi(api, path, params) {
  let options = (params) ? params : {};
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  return API.get(api, path, options);
}

export async function putApi(api, path, body) {
  let options = { body };
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  return API.put(api, path, options);
}

export async function postApi(api, path, body) {
  let options = { body };
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  try {
    const result = await API.post(api, path, options);
    return result;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.error) {
      console.error('ERROR:', err.response.data.error);
      throw err.response.data.error;
    } else {
      console.error('ERROR:', err);
      throw err;
    }
  }
}

export async function uploadFile(url, file, contentType, uploadProgress, uploadCancelToken) {
  return await axios.put(url,
    file,
    {
      headers: {
        'Content-Type': contentType
      },
      onUploadProgress: uploadProgress,
      cancelToken: uploadCancelToken,
    }
  );
}
