import { BarChart, Bar, PieChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Pie, Cell, ResponsiveContainer } from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10, } from 'd3-scale-chromatic';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row, Card, Select } from 'antd';
import { ALL } from './IssuesView';

const MONTHLY = 'monthly';
const DAILY = 'daily';
export const LAST60DAYS = '60';
export const LAST30DAYS = '30';
export const CUSTOM = 'custom';

const colors = scaleOrdinal(schemeCategory10).range();

export const AnalyticsView = ({ categoryList, issues, buildingId, tenantId, analyticsDateRange }: any) => {
    const [barChartData, setBarChartData] = useState<any>([]);
    const [pieChartData, setPieChartData] = useState<any>([]);
    const [barChartView, setBarChartView] = useState<string>(MONTHLY);

    useEffect(() => {
        if (categoryList && issues) {
            const from = analyticsDateRange[0];
            const to = analyticsDateRange[1];
            let bcData: any = [];
            let pcData: any = [];
            let processingDt = from.clone();

            let fList = issues.filter((i: any) => (tenantId === ALL || i.tenant === tenantId) &&
                (buildingId === ALL || i.buildingId === buildingId)
                && moment(i.createdAt).isAfter(from.startOf('day')) && moment(i.createdAt).isBefore(to.endOf('day'))
            );

            if (barChartView === MONTHLY) {
                while (!processingDt.isSame(to, 'month')) {
                    processMonthIssues(processingDt, bcData, fList);
                    processingDt.set('month', (processingDt.month() + 1));
                };
                processMonthIssues(to, bcData, fList);
            } else if (barChartView === DAILY) {
                while (!processingDt.isSame(to, 'day')) {
                    processDayIssues(processingDt, bcData, fList);
                    processingDt.add(1, 'day');
                };
                processDayIssues(to, bcData, fList);
            }

            fList.forEach((issue: any) => {
                let pcIndex = pcData.findIndex((item: any) => item.name === issue.currentStatusName);
                if (pcIndex >= 0) {
                    pcData[pcIndex].value++;
                } else {
                    pcData.push({ name: issue.currentStatusName, value: 1 })
                }
            })
            setBarChartData(bcData);
            setPieChartData(pcData);
        }
    }, [issues, categoryList, buildingId, tenantId, analyticsDateRange, barChartView]);

    const processMonthIssues = (processingDt: any, bcData: any, fList: any) => {
        let length = bcData.push({ name: processingDt.format('MMM YYYY') });
        let index = length - 1;
        let monthIssues = fList.filter((issue: any) => moment(issue.createdAt).isSame(processingDt, 'month'));
        monthIssues.forEach((issue: any) => {
            if (!bcData[index][issue.categoryName]) {
                bcData[index][issue.categoryName] = 1;
            } else {
                bcData[index][issue.categoryName]++;
            }
        });
    }

    const processDayIssues = (processingDt: any, bcData: any, fList: any) => {
        let length = bcData.push({ name: processingDt.format('MMM DD') });
        let index = length - 1;
        let monthIssues = fList.filter((issue: any) => moment(issue.createdAt).isSame(processingDt, 'day'));
        monthIssues.forEach((issue: any) => {
            if (!bcData[index][issue.categoryName]) {
                bcData[index][issue.categoryName] = 1;
            } else {
                bcData[index][issue.categoryName]++;
            }
        });

    }

    return <Row>
        <Col span={8}>
            <Card title="Issue Status" style={{ paddingBottom: '20px' }}>
                <div style={{ width: '100%', height: '50vh', paddingBottom: '30px' }}>
                    <ResponsiveContainer>
                        <PieChart>
                            <Pie dataKey="value" isAnimationActive={false} data={pieChartData} label>
                                {pieChartData.map((entry: any, index: number) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)}
                            </Pie>
                            <Tooltip />
                            <Legend wrapperStyle={{ position: 'unset' }} />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </Card>
        </Col>
        <Col span={15} offset={1}>
            <Card title="Issues Reported" style={{ paddingBottom: '20px' }}
                extra={<Select value={barChartView} onChange={setBarChartView}>
                    <Select.Option value={MONTHLY}>Monthly</Select.Option>
                    <Select.Option value={DAILY}>Daily</Select.Option>
                </Select>}>
                <div style={{ width: '100%', height: '50vh', paddingBottom: '30px' }}>
                    <ResponsiveContainer>
                        <BarChart
                            data={barChartData}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5, }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis allowDecimals={false} />
                            <Tooltip />
                            <Legend wrapperStyle={{ position: 'unset' }} />
                            {categoryList?.map(({ categoryName }: any, index: number) => <Bar dataKey={categoryName} fill={colors[index % colors.length]} stackId="a" />)}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Card>
        </Col>
    </Row >
};