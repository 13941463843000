import React, { useEffect, useState, useContext } from 'react';
import { Modal, Row, Col, Spin, Form } from 'antd';
import { ulid } from 'ulid';
import { DateTime } from 'luxon';

import VideoUploadView from './VideoUploadView';
import InductionFormView from './InductionFormView';
import { createInduction, discardIduction } from './actions';
import { notifiableAPICall, notifyConfirmation, notifyError } from '../utils/notification';
import { DISCARD_TYPE, INDUCTION_MODAL_TYPE, INDUCTION_STATUS_TYPE } from './constants';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { getUserObj } from '../utils/common-utils'

type Props = {
    visible: boolean;
    setVisible: Function;
    refresh: Function;
    mode: string;
    editRecordData?: any;
}

const InductionModal = (props: Props) => {
    const {
        visible,
        setVisible,
        refresh,
        mode,
        editRecordData
    } = props

    const [inductionId, setInductionId] = useState<string>(editRecordData.inductionId);
    const [mediaFileName, setMediaFileName] = useState('');
    const [videoKey, setVideoKey] = useState<string>(editRecordData.videoKey);
    const [thumbKey, setThumbKey] = useState<string>(editRecordData.thumbKey);
    const [title, setTitle] = useState<string>(editRecordData.title);
    const [description, setDescription] = useState<string>(editRecordData.description);
    const [buildingId, setBuildingId] = useState<string>(editRecordData.buildingId);
    const [tenants, setTenants] = useState<Array<string>>(editRecordData.tenants);
    const [createdBy] = useState<any>(editRecordData.createdBy);
    const [btnDisable, setBtnDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [prevVideoKey, setPrevVideoKey] = useState('');
    const [prevThumbKey, setPrevThumbKey] = useState('');

    const [form] = Form.useForm();

    const { state } = useContext(sessionStore);
    const currentUser: any = state.get(SESSION_ATTRS.LOGGED_USER);

    useEffect(() => {
        const mediaName = DateTime.now().toMillis().toString();
        setMediaFileName(mediaName);
        if (mode === INDUCTION_MODAL_TYPE.CREATE) {
            const newUlid = ulid();
            setInductionId(newUlid);
            setBtnDisable(false);
        }
    }, [mode]);

    const onModalClose = () => {
        notifyConfirmation('Continuing this action will discard your data', 'Discard', discard);
    }

    const onChange = (setValue: Function, value: any) => {
        setValue(value);
    }

    const mediaValidate = () => {
        if (!videoKey || !thumbKey) {
            notifyError('Media can\'t be empty!')
            throw new Error('Media empty!');
        }
    }

    const mediaClear = async () => {
        const params = {
            type: DISCARD_TYPE.OBJECT,
            prefix: ''
        }
        if (!!prevVideoKey && (prevVideoKey !== videoKey)) {
            params.prefix = prevVideoKey;
            await discardIduction(params);
        }
        if (!!prevThumbKey && (prevThumbKey !== thumbKey)) {
            params.prefix = prevThumbKey;
            await discardIduction(params);
        }
    }

    const addNewInduction = async (state: any) => {
        try {
            setBtnDisable(true);
            setIsLoading(true);
            const formData = form.getFieldsValue();
            let params: any = {
                inductionId,
                buildingId: formData.buildingId,
                title: formData.title,
                description: formData.description,
                videoKey,
                thumbKey,
                tenants,
                state
            }
            if (state === INDUCTION_STATUS_TYPE.PUBLISH) {
                mediaValidate();
            }
            if (!!formData.title && !!formData.description && tenants && tenants.length > 0 && !!videoKey && !!thumbKey) {
                params.isPublishable = true;
            }
            else {
                params.isPublishable = false;
            }
            if (mode === INDUCTION_MODAL_TYPE.CREATE) {
                params.createdBy = getUserObj(currentUser);
            } else {
                params.createdAt = editRecordData.createdAt;
                params.createdBy = createdBy;
                params.updatedBy = getUserObj(currentUser)
            }
            const successMsg = mode === INDUCTION_MODAL_TYPE.CREATE ? 'Created induction successfully' : 'Updated induction successfully'
            await notifiableAPICall(async () => await createInduction(params),
                'induction-create',
                'Please wait...',
                successMsg,
                'something went wrong!'
            )
            .then(res => {
                if (mode === INDUCTION_MODAL_TYPE.EDIT) {
                    mediaClear();
                }
                setIsLoading(false);
                onClear();
                refresh();
            })
            .catch(err => {
                console.error(err);
                setIsLoading(false);
                setBtnDisable(false);
            });
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setBtnDisable(false);
        }
    }

    const onClear = () => {
        setInductionId('');
        setVideoKey('');
        setThumbKey('');
        setTitle('');
        setDescription('');
        setBuildingId('');
        setTenants([]);
        setVisible(false);
    }

    const handleDiscard = async () => {
        if (mode === INDUCTION_MODAL_TYPE.CREATE) {
            const params = {
                type: DISCARD_TYPE.ALL,
                prefix: inductionId
            }
            await discardIduction(params);
        } else {
            const params = {
                type: DISCARD_TYPE.OBJECT,
                prefix: ''
            }
            if (!!prevVideoKey && videoKey) {
                params.prefix = videoKey;
                await discardIduction(params);
            }
            if (!!prevThumbKey && thumbKey) {
                params.prefix = thumbKey;
                await discardIduction(params);
            }
        }
    }

    const discard = async () => {
        await notifiableAPICall(() => handleDiscard(),
            'discard induction',
            'Please wait',
            'Induction discarded',
            'Something went wrong!',
            true);
        onClear();
    }

    return (
        <Modal
            visible={visible}
            onCancel={onModalClose}
            footer={null}
            width={890}
            bodyStyle={{
                padding: 0
            }}
            style={{
                borderRadius: 10,
                overflow: 'hidden'
            }}
            className='intnnt-mdl'
            centered
            maskClosable={false}
        >
            <Spin spinning={isLoading} size={'large'}>
                <Row className='intnnt-rw'>
                    <Col className='intnnt-clmn intnnt-clmn-left intnnt-drk-10'>
                        <VideoUploadView
                            inductionId={inductionId}
                            videoKey={videoKey}
                            setVideoKey={setVideoKey}
                            thumbKey={thumbKey}
                            setThumbKey={setThumbKey}
                            mode={mode}
                            mediaFileName={mediaFileName}
                            setPrevVideoKey={setPrevVideoKey}
                            setPrevThumbKey={setPrevThumbKey}
                        />
                    </Col>
                    <Col className='intnnt-clmn intnnt-clmn-right'>
                        <InductionFormView
                            title={title}
                            description={description}
                            buildingId={buildingId}
                            setBuildingId={setBuildingId}
                            tenants={tenants}
                            setTenants={setTenants}
                            onChange={onChange}
                            addNewInduction={addNewInduction}
                            btnDisable={btnDisable}
                            discard={discard}
                            form={form}
                            mode={mode}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}

export default InductionModal;