export const BOOKING_STATUS_ARRAY = [
    { name: "Pending", filterName: 'Open requests', value: 1 },
    { name: "Approved", filterName: 'Approved bookings', value: 2 },
    { name: "Declined", filterName: 'Declined bookings', value: -1 },
    { name: "Cancelled", filterName: 'Cancelled bookings', value: -2 }
];

export const HISTORY_STATUS_ARRAY = [
    { name: "All", filterName: 'All', value: 1 },
    { name: "Approved", filterName: 'Approved bookings', value: 2 },
    { name: "Declined", filterName: 'Declined bookings', value: -1 },
    { name: "Cancelled", filterName: 'Cancelled bookings', value: -2 }
]

export const MODAL_TYPE = {
    CREATE: 'create',
    EDIT: 'edit'
}

export const CALENDAR_VIEWS = {
    DAY: 'day',
    WEEK: 'week'
}

export const DESK_VIEWS = {
    BOOKINGS: 'BOOKINGS',
    DESKS: 'DESKS',
    FLOOR: 'FLOOR',
    HISTORY: 'HISTORY',
    ANALYTICS: 'ANALYTICS'
};

export const BOOKING_STATUS = {
    NO_BOOKING: 0,
    PENDING: 1,
    APPROVED: 2,
    DECLINED: -1,
    CANCELLED: -2,
};

export const BOOKING_STATUS_NAMES: objType = {
    '0': 'NO_BOOKING',
    '1': 'PENDING',
    '2': 'APPROVED',
    '-1': 'DECLINED',
    '-2': 'CANCELLED',
};

export const DESK_STATUS = {
    AVAILABLE: { value: 1, label: 'Available' },
    NOT_AVAILABLE:  { value: 0, label: ' Not Available' },
    HIDDEN:  { value: -1, label: 'Hidden' }
};

type objType = {
    [key: string]: any
}

export const BOOKING_ERROR_MSGS: objType = {
    'INVALID_START_TIME': 'Selected start time is not valid.',
    'INVALID_END_TIME': 'Selected end time is not valid.',
    'START_IS_AFTER_END': 'End time should be after start time.',
    'ADVANCED_BOOKING_TIME_FAILURE': 'Advance booking time failure.',
    'SLOT_NOT_AVAILABLE': 'Selected time slot is not available.',
    'HOUR_LIMIT_EXCEEDED': 'Maximum booking period exceeded.',
};

export const DESK_FACILITIES = ['Standard Monitor', 'Dual Monitors', 'Widescreen Monitor', 'Video Conference System'];

export const FUTURE_BOOKING_CSV_EXPORT_FIELDS = ['Building', 'Floor', 'Desk', 'Start Time', 'End Time', 'Booking Duration (hours)', 'Booked By', 'Approved By', 'Status', 'Per Hour Cost($)'];
export const HISTORY_BOOKING_CSV_EXPORT_FIELDS = ['Building', 'Floor', 'Desk', 'Start Time', 'End Time', 'Booking Duration (hours)', 'Booked By', 'Approved By', 'Approved At', 'Cancelled By', 'Cancelled At', 'Declined By', 'Declined At', 'Decline Reason', 'Status', 'Per Hour Cost($)'];

export const BOOKING_TIME_FORMAT = "dddd, DD MMMM hh:mm A";

export const DESK_KEY = "DESK";

export const FLOOR_KEY = "FLOOR";

export const BOOKING_CHECKED_STATUS = {
    CHECKED_IN: 0,
    CHECKED_OUT: 1
};