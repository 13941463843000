import Auth from "@aws-amplify/auth";
import { ACTIONS, DispatchType } from "../store/session";
import { USER_STATE, USER_ROLE } from "../users/constants";
import { APIS, getApi } from "../utils/api-utils";

export const isSessionAuthenticated = async (dispatch: DispatchType) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        await postLogin(user, dispatch, ACTIONS.LOGGED_IN);
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
}

export const signIn = async (username: string, password: string, newPassword: string, dispatch: DispatchType) => {
    try {
        let user = await Auth.signIn(username, password);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            if (!newPassword) {
                return ('NEW_PASSWORD_REQUIRED');
            }
            user = await Auth.completeNewPassword(user, newPassword);
            await postLogin(user, dispatch, ACTIONS.LOGGED_IN);
        } else {
            await postLogin(user, dispatch, ACTIONS.LOGGED_IN);
        }
    } catch (err: unknown) {
        console.error('ERR:', err);
        if (err instanceof Error) {
            return err.message ? err.message : "Error occured";
        }
        return "Error occured";
    }
}

const getBuildings = async (buildingId: string) => {
    try {
        return await getApi(APIS.BUILDING_API, `/buildings/${buildingId}`);
    } catch (e) {
        console.error(e);
    }
    return [];
}

const getTenants = async (buildingId: string) => {
    try {
        return await getApi(APIS.TENANT_API, `/tenants/${buildingId}`);
    } catch (e) {
        console.error(e);
    }
    return [];
}

const getUser = async (userId: string, tenantId: string) => {
    try {
        return await getApi(APIS.USER_API, '/user', { queryStringParameters: { tenantId, userId } });
    } catch (e) {
        console.error(e);
    }
    return null;
}

const dispatchDbUser = async (payload: any, tenantId: string,
    dispatch: DispatchType, dispatchType: string) => {
    const dbUser = await getUser(payload.sub, tenantId);
    if (dbUser) {

        dispatch({
            type: dispatchType, payload: {
                firstName: dbUser.firstName,
                lastName: dbUser.lastName,
                userId: dbUser.userId,
                buildingId: dbUser.buildingId,
                tenantId: dbUser.tenantId,
                contactNumber: dbUser.contactNumber,
                countryDialingCode: dbUser.countryDialingCode,
                isFederatedUser: dbUser.isFederatedUser,
                isActive: dbUser.state === USER_STATE.ACTIVE,
                role: dbUser.role,
                isAdmin: dbUser.role === USER_ROLE.ADMIN
            }
        });
        const buildings = await getBuildings(dbUser.buildingId);
        dispatch({ type: ACTIONS.BUILDINGS_LOADED, payload: buildings });
        const tenants = await getTenants(dbUser.buildingId);
        dispatch({ type: ACTIONS.TENANTS_LOADED, payload: tenants });
    } else {
        dispatch({
            type: dispatchType, payload: {
                firstName: payload.name,
                lastName: payload.family_name,
                userId: payload.sub,
                isInvalid: true
            }
        });
    }
}

const postLogin = async (user: any, dispatch: DispatchType, dispatchType: string) => {
    let payload = user.getSignInUserSession().getIdToken().payload;
    const tenantId = payload["custom:tenantId"];
    if (tenantId) {
        await dispatchDbUser(payload, tenantId, dispatch, dispatchType);
    } else {
        dispatch({
            type: dispatchType, payload: {
                firstName: payload.name,
                lastName: payload.family_name,
                userId: payload.sub,
                isFederatedUser: true
            }
        });
    }
}

export const logout = async () => {
    try {
        await Auth.signOut();
    } catch (err) {
        console.error('ERR:', err);
    }
}

export const sendForogotPasswordCode = async (userName: string) => {
    try {
        await Auth.forgotPassword(userName);
    } catch (err) {
        if (err instanceof Error) {
            return err.message ? err.message : "Error occured";
        }
        return "Error occured";
    }
};

export const resetNewPassword = async (userName: string, code: string, password: string) => {
    try {
        await Auth.forgotPasswordSubmit(userName, code, password);
    } catch (err) {
        if (err instanceof Error) {
            return err.message ? err.message : "Error occured";
        }
        return "Error occured";
    }
};