import { Button } from "antd";
import { INDUCTION_STATUS_TYPE } from "./constants";
import { UndoOutlined, EditFilled, DownloadOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons';


type PropType = {
    row: any
    state: number,
    editModalOpen: Function,
    onDownload: Function,
    onConvertToDraft: Function,
    onMoveToTrash: Function,
    onConvertToPublish: Function,
    onRestore: Function,
    canPublish: boolean
}

const PopOverBody = ({ row, state, editModalOpen, onDownload, onConvertToDraft, onConvertToPublish, onMoveToTrash, onRestore, canPublish }: PropType) => {
    let content;
    switch (state) {
        case INDUCTION_STATUS_TYPE.PUBLISH:
            content = (
                <>
                    <Button className='intnnt-ppvr-btn' type='text' icon={<EditFilled />} onClick={() => editModalOpen(row)}>Edit</Button>
                    <Button className='intnnt-ppvr-btn' type='text' icon={<DownloadOutlined />} onClick={() => onDownload(row.videoKey, row.title)}>Download</Button>
                    <Button className='intnnt-ppvr-btn' type='text' icon={<FormOutlined />} onClick={() => onConvertToDraft(row.inductionId, row.buildingId, row.state)}>Convert to draft</Button>
                    <Button className='intnnt-ppvr-btn' type='text' danger icon={<DeleteOutlined />} onClick={() => onMoveToTrash(row.inductionId, row.buildingId, row.state)}>Move to trash</Button>
                </>
            );
            break;
        case INDUCTION_STATUS_TYPE.DRAFT:
            content = (
                <>
                    <Button className='intnnt-ppvr-btn' type='text' icon={<EditFilled />} onClick={() => editModalOpen(row)}>Edit</Button>
                    <Button className='intnnt-ppvr-btn' type='text' disabled={!row.videoKey} icon={<DownloadOutlined />} onClick={() => onDownload(row.videoKey, row.title)}>Download</Button>
                    <Button className='intnnt-ppvr-btn' type='text' disabled={!canPublish} icon={<FormOutlined />} onClick={() => onConvertToPublish(row.inductionId, row.buildingId, row.state)}>Convert to publish</Button>
                    <Button className='intnnt-ppvr-btn' type='text' danger icon={<DeleteOutlined />} onClick={() => onMoveToTrash(row.inductionId, row.buildingId, row.state)}>Move to trash</Button>
                </>
            );
            break;
        case INDUCTION_STATUS_TYPE.TRASH:
            content = (
                <>
                    <Button className='intnnt-ppvr-btn' type='text' disabled={row.prevState === INDUCTION_STATUS_TYPE.PUBLISH && !canPublish} icon={<UndoOutlined />} onClick={() => onRestore(row.inductionId, row.buildingId, row.prevState, row.state)}>Restore</Button>
                </>
            );
            break;
        default:
            content = null;
    }

    return (
        <div className='intnnt-ppvr'>
            {content}
        </div>
    )
}

export default PopOverBody;