import { useContext } from 'react';
import { Form, Input, Row, Select, Space } from 'antd';
import { sessionStore, SESSION_ATTRS } from '../../../store/session';
import { MODAL_TYPE } from '../../constants';

const { Option } = Select;

export const FloorFormView = ({ form, mode, floor }: any) => {
    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    let isEditView = floor !== undefined;

    let { name, shortDescription, buildingId }: any = floor ? floor : {};

    return (
        <Space direction='vertical' align='baseline' className='intnnt-spc intnnt-spc-wdth'>
            <Row className='title'>{!isEditView ? 'Create new' : 'Edit'} Floor</Row>
            <Row>
                <p className='intnnt-sbttl'>Information</p>
            </Row>
            <Form
                name='induction-information-form'
                layout={'vertical'}
                // approvalRequired is showing as auto approval, so we need to use !approvalRequired
                initialValues={isEditView ? {
                    name, shortDescription, buildingId
                } : {}}
                requiredMark={false}
                form={form}
            >
                <Form.Item
                    name='name'
                    label="Floor name"
                    rules={[{ required: true, message: 'Please input the floor name!' }]}
                >
                    <Input placeholder='Enter floor name' className='intnnt-npt' />
                </Form.Item>
                <Form.Item
                    name='buildingId'
                    label='Building name'
                    rules={[{ required: true, message: 'Please select a building!' }]}
                >
                    <Select placeholder='Select a building' className='intnnt-npt' disabled={mode === MODAL_TYPE.EDIT || isEditView} >
                        {buildings.map((building: any) => {
                            return (
                                <Option key={building.buildingId} value={building.buildingId}>
                                    <div>
                                        <p className='intnnt-bldng-nm'>{building.name}</p>
                                        <p className='intnnt-bldng-lctn'>{building.geolocation}</p>
                                    </div>
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Space>
    );
}
