export const NEWS_FEEDS_TYPES = {
    PUBLISH: 1,
    DRAFT: 0,
    TRASH: -1
};

export const NEWS_FEEDS_STATUS = [
    { name: "Post", state: NEWS_FEEDS_TYPES.PUBLISH },
    { name: "Draft", state: NEWS_FEEDS_TYPES.DRAFT },
    { name: "Trash", state: NEWS_FEEDS_TYPES.TRASH }
]

export const NEWS_CATEGORIES_ARRAY = [
    {
        value: 'cleaning',
        name: 'Cleaning'
    },
    {
        value: 'maintenance',
        name: 'Maintenance'
    },
    {
        value: 'events',
        name: 'Events'
    },
    {
        value: 'building_update',
        name: 'Building Update'
    }

];

export const NEWS_CATEGORIES: any = {
    all: 'All Categories',
    cleaning: 'Cleaning',
    maintenance: 'Maintenance',
    events: 'Events',
    building_update: 'Building Update'
};

export const DISCARD_TYPE = {
    ALL: 'all',
    OBJECT: 'object'
}

export const IMAGE_EXTENTION_STRING = 'jpg_png_jpeg';