export const CONFIG = {
  stage: 'dev',
  baseApiUrl: 'https://api.dev.intenant.io'
}

export const AWS_CONFIG = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_ceUgqdJiG',
    userPoolWebClientId: '2itcos672s4ctnjqn15g8pm0rq'
  }
}