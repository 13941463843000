import { Row, Select, Space, Typography, Layout, DatePicker, Spin  } from 'antd';
import { useState, useContext, useEffect } from 'react';
import { BOOKING_STATUS_NAMES } from './constants';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { getAllBookingRequests } from './actions';
import { DeskAnalyticsView } from './DeskAnalyticsView';
import moment from 'moment';

export const LAST60DAYS = '60';
export const LAST30DAYS = '30';
export const CUSTOM = 'custom';
export const ALL = "all";
const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

export const DeskAnalytics = ({ deskList, setSelectedView, prevSelectedView, floorList }: any) => {

    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);

    const [selectedBuildingId, setSelectedBuildingId] = useState<any>(buildings.length === 1 ? buildings[0].buildingId : undefined);
    const [deskBookings, setDeskBookings] = useState<Array<any>>([]);
    const [isLoading, setLoadingState] = useState<Boolean>(true);
    const [analyticsRange, setAnalyticsRange] = useState<string>(LAST30DAYS);
    const [analyticsDateRange, setAnalyticsDateRange] = useState<any>([moment().subtract(30, 'days'), moment()]);

    useEffect(() => {
        if (analyticsRange === LAST30DAYS) {
            setAnalyticsDateRange([moment().subtract(30, 'days'), moment()]);
        } else if (analyticsRange === LAST60DAYS) {
            setAnalyticsDateRange([moment().subtract(60, 'days'), moment()]);
        }
    }, [analyticsRange]);

    useEffect(() => {
        const setBookingData = async() => {
            const result = await getAllBookingRequests(selectedBuildingId);
            setLoadingState(false);
            const bookings:any = [];
            result.forEach((booking: any)=> {
                const floor = floorList.filter((floor: any) => floor.floorId === booking.floorId);
                const desk = deskList.filter((desk: any) => desk.deskId === booking.deskId);
                if(!floor.length || !desk.length) return;
                booking.floorName = floor[0].name;
                booking.deskName = desk[0].name;
                booking.statusName = BOOKING_STATUS_NAMES[booking.reqStatus]
                bookings.push(booking);
            });
            setDeskBookings(bookings);
        }
        setBookingData();
    }, [selectedBuildingId, deskList, floorList]);

    return (
        <>
            <Space direction='vertical'>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Row style={{ marginTop: 15, justifyContent: 'space-between' }}>
                        <Title level={4}>
                            <ArrowLeftOutlined size={15} style={{ marginRight: 10 }} onClick={() => setSelectedView(prevSelectedView)} />
                            Analytics
                        </Title>
                        <Row className="grid-options">
                            <Space style={{ marginBottom: 16, flexWrap: "wrap" }}>
                                <Select defaultValue={buildings.length === 1 ? buildings[0].buildingId : undefined} onChange={setSelectedBuildingId} style={{ width: 150 }}>
                                    {buildings.map((building: any) => (
                                        <Option key={building.buildingId} value={building.buildingId}>{building.name}</Option>
                                    ))}
                                </Select>
                            </Space>
                            <Space className="grid-options--search">
                                <Select style={{ width: 180 }} value={analyticsRange} onChange={setAnalyticsRange}>
                                    <Select.Option value={LAST30DAYS}>Last 30 Days</Select.Option>
                                    <Select.Option value={LAST60DAYS}>Last 60 Days</Select.Option>
                                    <Select.Option value={CUSTOM}>Custom Date Range</Select.Option>
                                </Select>
                                {analyticsRange === CUSTOM && <DatePicker.RangePicker allowClear={false}
                                value={analyticsDateRange} onChange={setAnalyticsDateRange} style={{ width: 250 }} format={'YYYY-MM-DD'} />}
                            </Space>
                        </Row>
                    </Row>
                    <Row>
                        <Content style={{textAlign: 'center'}}> 
                            {isLoading ? 
                              <Spin tip="Loading..." size="large"/> :
                            <DeskAnalyticsView
                                buildingId={selectedBuildingId === ALL ? buildings[0].buildingId : selectedBuildingId} analyticsDateRange={analyticsDateRange}
                                deskBookings = {deskBookings} floorList={floorList}
                            />}
                        </Content>
                    </Row>
                </Space>
            </Space >
        </>
    )
};
