import { Button, Modal, List, Row, Popconfirm, Switch, Col, Input, Space, Spin, Tooltip } from "antd";
import { PlusOutlined, DeleteOutlined, UpCircleOutlined, DownCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { useState } from "react";
import { notifiableAPICall } from "../utils/notification";
import { addStatus, archiveStatus, updateStatusIndex } from "./actions";

type PropType = {
    onClose: Function,
    onUpdate: Function,
    statusList: any,
    buildingId: string
}

export const StatusListModal = ({ onClose, statusList, onUpdate, buildingId }: PropType) => {
    const [addNew, setAddNew] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const [newStatusName, setNewStatusName] = useState<string>('');
    const onArchive = async (st: any, archive: boolean) => {
        try {
            setIsLoading(true);
            let { success } = await notifiableAPICall(async () => await archiveStatus(st.buildingId, st.statusId, archive),
                'archive-status',
                `${archive ? 'Archiving' : 'Unarchiving'} status...`,
                `Status ${archive ? 'archived' : 'restored'} successfully`,
                'something went wrong!'
            );
            if (success) {
                onUpdate();
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    const onAddStatus = async () => {
        try {
            setIsLoading(true);
            let { success } = await notifiableAPICall(async () => await addStatus(buildingId, newStatusName, statusList.length),
                'add-status',
                'Adding status...',
                'Status added successfully',
                'something went wrong!'
            );
            if (success) {
                setNewStatusName('');
                setAddNew(false);
                onUpdate();
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    const onIndexChanged = async (statusId1: string, index1: number, statusId2: string, index2: number) => {
        try {
            setIsLoading(true);
            let { success } = await notifiableAPICall(async () => {
                let success1 = await updateStatusIndex(buildingId, statusId1, index1);
                let success2 = await updateStatusIndex(buildingId, statusId2, index2);
                return success1 && success2;
            },
                'add-status',
                'Updating status order...',
                'Status order updated successfully',
                'something went wrong!'
            );
            if (success) {
                onUpdate();
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    const getFilteredList = () => statusList?.filter(({ isArchived }: any) => showArchived ? isArchived : !isArchived) || [];

    return (
        <Modal
            visible={true}
            footer={null}
            onCancel={() => onClose()}
            wrapClassName="add-new-building-contact-modal"
            maskClosable={false}
            centered
        >
            <Spin spinning={isLoading} size={'large'}>
                <h2 className="new-building-contact-title">Status List</h2>
                <Row className="grid-options" align="middle">
                    <Col>Show Archived <Switch onChange={setShowArchived} /></Col>
                    <Col flex="auto" style={{ textAlign: 'end' }}>
                        {!addNew && <Button icon={<PlusOutlined />} className="btn btn--o-primary" onClick={() => setAddNew(true)}>Add New</Button>}
                    </Col>
                </Row>
                <br />
                {addNew && <Space>
                    Status: <Input value={newStatusName} onChange={e => setNewStatusName(e.target.value)} />
                    <Button className="btn btn--o-primary" disabled={newStatusName?.trim().length === 0} onClick={onAddStatus}>Add</Button>
                    <Button className="btn btn--o-primary" onClick={() => { setAddNew(false); setNewStatusName('') }}>Cancel</Button>
                </Space>}
                <List
                    bordered
                    dataSource={getFilteredList()}
                    style={{ marginTop: 20, marginBottom: 20 }}
                    renderItem={(item: any, index: number) => (
                        <List.Item actions={[<Popconfirm
                            title={`Are you sure you want to ${item.isArchived ? 'unarchive' : 'archive'} this status?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => onArchive(item, !item.isArchived)}
                        >
                            <Tooltip title={item.isArchived ? 'Restore' : 'Archive'}>
                                <Button icon={!item.isArchived ? <DeleteOutlined /> : <UndoOutlined />} danger type="text" />
                            </Tooltip>
                        </Popconfirm>,
                        ]}> <Space>
                                <Button.Group>
                                    <Tooltip title="Move Up"><Button size='small' type='text' shape="circle" icon={<UpCircleOutlined />} disabled={index === 0}
                                        onClick={() => onIndexChanged(item.statusId, index - 1, statusList[index - 1].statusId, index)} /></Tooltip>
                                    <Tooltip title="Move Down"><Button size='small' type='text' shape="circle" icon={<DownCircleOutlined />} disabled={index === getFilteredList().length - 1}
                                        onClick={() => onIndexChanged(item.statusId, index + 1, statusList[index + 1].statusId, index)} /></Tooltip>
                                </Button.Group>
                                {item.statusName}
                            </Space></List.Item>
                    )}
                />
            </Spin>
        </Modal>
    );
}