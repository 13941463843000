
const InactiveUserView = () => {

    return (
        <div className="banner-body">
            <div className="banner">
                Sorry, you are not able to access this application. Please contact an admin.
            </div>
        </div>
    );
}

export default InactiveUserView;