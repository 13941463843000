import { Button, Card, Form, Input, Spin } from "antd";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { sessionStore } from "../store/session";
import { signIn } from "./actions";
import ForgotPassword from "./ForgotPassword";

type PropType = {
    setIsAuthenticatedUser: (isLogged: boolean) => void;
}

type LoginParamType = {
    newPassword: string,
    confirmNewPassword: string,
    email: string,
    password: string
}

export const LoginPage = ({ setIsAuthenticatedUser }: PropType) => {

    const [requireNewPassword, setRequireNewPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoginView, setIsLoginView] = useState<boolean>(true);
    const [userName, setUserName] = useState('');

    const history = useHistory();
    const { dispatch } = useContext(sessionStore);

    const [form] = Form.useForm();

    const login = async ({ newPassword, confirmNewPassword, email, password }: LoginParamType) => {
        setLoading(true);
        if (requireNewPassword && newPassword !== confirmNewPassword) {
            setErrorMessage("New password does not match with confirm password");
            setLoading(false);
            return;
        }

        const error = await signIn(email.toLowerCase(), password, newPassword, dispatch);
        if (error && error === "NEW_PASSWORD_REQUIRED") {
            setRequireNewPassword(true);
            setErrorMessage("Please set a new password");
            setLoading(false);
            return;
        }

        if (error) {
            setErrorMessage(error);
            setLoading(false);
            return;
        }

        setLoading(false);
        setIsAuthenticatedUser(true);
        history.push('/');
    };

    const toggleForgotPasswordView = () => {
        setIsLoginView(!isLoginView);
    }

    const newPasswordFieldItems = (requireNewPassword) ? <>
        <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
                {
                    required: true,
                    message: 'Please input your new password',
                },
            ]}
        >
            <Input.Password placeholder="Type you new password here" disabled={loading ? true : false} />
        </Form.Item>
        <Form.Item
            label="Confirm New Password"
            name="confirmNewPassword"
            rules={[
                {
                    required: true,
                    message: 'Please re-enter your new password',
                },
            ]}
        >
            <Input.Password placeholder="Type you new password here too" disabled={loading ? true : false} />
        </Form.Item>
    </> : null;

    const errorView = (errorMessage) ? <div className="login-error">{errorMessage}</div> : null;

    const loginFormView = isLoginView ?
    <>
        <Form name="login" layout="vertical" validateTrigger={'onFinish'} form={form} onFinish={login}>
            {errorView}
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message:"Please enter your email"
                    },
                    {
                        type:"email",
                        message: "Invalid email address",
                    }
                ]}
            >
                <Input
                    placeholder="Type in your email"
                    disabled={loading ? true : false}
                    onChange={(value)=>setUserName(value.target.value)}
                ></Input>
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: "Please enter your password",
                    },
                ]}
            >
                <Input.Password
                    placeholder="Type in your password"
                    disabled={loading ? true : false}
                ></Input.Password>
            </Form.Item>
            {newPasswordFieldItems}
            <Form.Item>
                <Button
                    id="sign-in-btn"
                    className="sign-in-btn btn btn--primary"
                    htmlType="submit"
                    block
                    icon={loading ? <Spin className="sign-in-spin" /> : null}
                >
                    Sign In
                    </Button>
            </Form.Item>
        </Form>
        <div className="forgot-password-wrapper">
            <Button type='link' className="forgot-password-btn link-btn" onClick={toggleForgotPasswordView}>Forgot Password</Button>
        </div>
        <div className="login-links-wrapper">
            <div className="login-logo" >
                <img src="/images/inTenant.png" alt="InTenant-Icon" />
            </div>
            <div className='login-links'>
                <a href='https://www.buildingspacesgroup.com.au/intenant.html'>FAQ</a>
                <a href='mailto:support@buildingspacesgroup.com.au'>Support</a>
            </div>
        </div>
    </>
    :
    <ForgotPassword
        email = {userName}
        setEmail = {setUserName}
        setIsLoginView = {setIsLoginView}
    />

    return (
        <div className="login-body">
            <div className="login-wrp">

                <div className="login-header">
                    <div className="login-logo" style={{paddingBottom:20}} >
                        <img width={200} src="/images/intenant.svg" alt="InTenant-Icon" />
                    </div>
                    <h2>Welcome!</h2>
                    <span>to the InTenant Admin Portal.</span>
                </div>
                <Card
                className="login-form-card">
                    {
                        loginFormView
                    }
                </Card>
            </div>
        </div>
    );
}