import { useContext, useState } from 'react';
import { Col, Form, Input, Row, Select, Space, Checkbox, Radio } from 'antd';
import { sessionStore, SESSION_ATTRS } from '../../../store/session';
import { MODAL_TYPE, SPACE_FACILITIES, SPACE_STATUS } from '../../constants';

const { TextArea } = Input;
const { Option } = Select;

export const SpaceFormView = ({ form, mode, space }: any) => {
    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    let isEditView = space !== undefined;

    let { name, shortDescription, description, buildingId, hourlyPrice, floor, capacity, hourLimit, approvalRequired, advancedBookingRequired,
        advancedBookingPeriod, advancedBookingPeriodType, facilities, spaceStatus }: any = space ? space : {};

    const [isAdvancedBookingRequired, setisAdvancedBookingRequired] = useState(advancedBookingRequired);

    const onFieldsChange = (changedFields: Array<any>) => {
        if (changedFields.length) {
            let item = changedFields.find(({ name }: any) => name[0] === 'advancedBookingRequired');
            if (item) setisAdvancedBookingRequired(item.value);
        }
    }

    return (
        <Space direction='vertical' align='baseline' className='intnnt-spc intnnt-spc-wdth'>
            <Row className='title'>{!isEditView ? 'Create new' : 'Edit'} space</Row>
            <Row>
                <p className='intnnt-sbttl'>Information</p>
            </Row>
            <Form
                name='induction-information-form'
                layout={'vertical'}
                // approvalRequired is showing as auto approval so we need to use !approvalRequired
                initialValues={isEditView ? {
                    name, shortDescription, description, buildingId, hourlyPrice, floor, capacity, hourLimit, approvalRequired:
                        !approvalRequired, advancedBookingRequired, advancedBookingPeriod, advancedBookingPeriodType, facilities, spaceStatus
                } : {}}
                requiredMark={false}
                form={form}
                onFieldsChange={onFieldsChange}
            >
                <Form.Item
                    name='name'
                    label="Space's name"
                    rules={[{ required: true, message: 'Please input the space name!' }]}
                >
                    <Input placeholder='Enter space’s name' className='intnnt-npt' />
                </Form.Item>
                <Form.Item
                    name='shortDescription'
                    label="Short Description"
                    rules={[{ required: true, message: 'Please input the short description!' }]}
                >
                    <Input placeholder='Enter short description' className='intnnt-npt' />
                </Form.Item>
                <Form.Item
                    name='description'
                    label='Description'
                    rules={[{ required: true, message: 'Please input the description!' }]}
                >
                    <TextArea rows={4} placeholder='Tell more about this space' className='intnnt-npt' />
                </Form.Item>
                <Form.Item name='spaceStatus' rules={[{ required: true, message: 'Please select status!' }]}>
                    Status: <Radio.Group optionType="button" buttonStyle="outline" defaultValue={spaceStatus} options={Object.values(SPACE_STATUS)} onChange={e => form.setFieldsValue({ spaceStatus: e.target.value })} />
                </Form.Item>
                <Form.Item
                    name='buildingId'
                    label='Building’s name'
                    rules={[{ required: true, message: 'Please select a building!' }]}
                >
                    <Select placeholder='Select a building' className='intnnt-npt' disabled={mode === MODAL_TYPE.EDIT} >
                        {buildings.map((building: any) => {
                            return (
                                <Option key={building.buildingId} value={building.buildingId}>
                                    <div>
                                        <p className='intnnt-bldng-nm'>{building.name}</p>
                                        <p className='intnnt-bldng-lctn'>{building.geolocation}</p>
                                    </div>
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name='hourlyPrice' label="Pricing per hour" rules={[{ required: true, message: 'Please enter a valid price!' }]}>
                            <Input type='number' min={0} className='intnnt-npt bs-number-input' prefix='$' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='floor' label="Location (floor)" rules={[{ required: true, message: 'Please enter the location!' }]}>
                            <Input placeholder='Eg: 2' className='intnnt-npt' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name='capacity' label="Capacity (people)" rules={[{ required: true, message: 'Please enter capacity!' }]}>
                            <Input placeholder='Eg: 2-4' className='intnnt-npt' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='hourLimit' label="Limited to (hours)" rules={[{ required: true, message: 'Please enter the limit!' }]}>
                            <Input type='number' min={0} placeholder='Eg: 2' className='intnnt-npt  bs-number-input' />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name='approvalRequired' valuePropName="checked">
                    <Checkbox>Auto booking approval</Checkbox>
                </Form.Item>
                <Form.Item name='advancedBookingRequired' valuePropName="checked" className={isAdvancedBookingRequired ? 'input-chk-bx' : ''}>
                    <Checkbox>Requires booking in advance</Checkbox>
                </Form.Item>
                {isAdvancedBookingRequired &&
                    <Row>
                        <Col span={13}>
                            <Form.Item name='advancedBookingPeriod' rules={[{ required: true, message: 'Please input a time period!' }]}>
                                <Input type='number' min={1} placeholder="Eg: 2" className='intnnt-npt bs-input-grp-left bs-number-input' />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item name='advancedBookingPeriodType' rules={[{ required: true, message: 'Please select the period type!' }]}>
                                <Select className='bs-input-grp-right'>
                                    <Option value="hour">Hour</Option>
                                    <Option value="day">Day</Option>
                                    <Option value="week">Week</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                }
                <Form.Item name='facilities' label="Space’s utilities" >
                    <Select mode="tags" style={{ width: '100%' }} placeholder="Please select utilities" className='facility-select'>
                        {SPACE_FACILITIES.map((val: string) => <Option key={val} value={val}>{val}</Option>)}
                    </Select>
                </Form.Item>
            </Form>
        </Space>
    );
}