import { Modal, Row, Col, Spin, Form } from 'antd';
import { DeskFormView } from './DeskFormView';
import {
    createDesk,
    deleteImage, getDesk,
    getFloor,
    updateDesk,
    uploadImageDesk
} from '../../actions';
import { useEffect, useState } from 'react';
import { ulid } from 'ulid';
import { notifiableAPICall, notifyError } from '../../../utils/notification';
import { ImageUploadView } from "./ImageUploadView";
import { DESK_KEY } from '../../constants';
import { FloorSelect } from "./FloorSelect";

const DEFAULT_MAP = {};

export const BookingDeskModal = ({ onClose, desk, onAdd, onSave, floorList, deskList, cloneStatus }: any) => {
    const [thumbnailImg, setThumbnailImg] = useState<any>();
    const [imageList, setImageList] = useState<any>([]);
    const [deskDetails, setDeskDetails] = useState<any>();
    const [isLoading, setIsLoading] = useState(!!desk);
    const [selectedFloor, setSelectedFloor] = useState<any>();
    const [deskLocation, setLocation] = useState<any>(DEFAULT_MAP);
    const [form] = Form.useForm();
    let isEditView = desk !== undefined;

    useEffect(() => {
        if (isEditView) {
            getDesk(desk.buildingId, desk.deskId).then((de) => {
                if(!cloneStatus) {
                    setImageList(de.imageIds ? de.imageIds.map((uid: string, i: number) => { return { uid, isOld: true, url: de.images[i] }; }) : []);
                    setThumbnailImg( de.thumbnailLink && { url: de.thumbnailLink, isOld: true });
                    setInitDeskLocation();
                }
                setDeskDetails(de);
                if (de.floorId) {
                    getFloor(desk.buildingId, de.floorId).then((res) => {
                        setSelectedFloor({ floorId: de.floorId, url: res.imageLink});
                    })
                }
                setIsLoading(false);
            });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setInitDeskLocation = () => {
        if(desk && desk.location && !cloneStatus){
            setLocation(desk.location);
        }
        else {
            setLocation(DEFAULT_MAP);
        }
    }
    
    const setDeskLocation = (coords: any) => {
        // Floor map element in FloorSelect functional component 
        const element = document.getElementById('db-desk-img');
        if(!element) return;
        const px =  (coords.x / element?.offsetWidth) * 100;
        const py =  (coords.y / element?.offsetHeight) * 100;
        setLocation({left: `${px}%`, top:`${py}%`});
    }

    const onCreateDesk = async () => {
        try {
            let deskData = await form.validateFields();
            if(form.getFieldValue('name')) {
                const deskAvailability = deskList.filter((d: any)=> d.name === form.getFieldValue('name'));
                if(deskAvailability.length) {
                    notifyError(`Desk name must be unique!`);
                    return;
                }
            }
            if (!selectedFloor?.floorId) {
                notifyError(`Please select the Floor!`);
                return;
            }
            if (!deskLocation.top) {
                notifyError(`Please add desk location!`);
                return;
            }
            deskData.floorId = selectedFloor.floorId;
            setIsLoading(true);
            deskData.location = deskLocation;
            deskData.deskId = ulid();
            if (thumbnailImg) {
                deskData.thumbnailFileName = await notifiableAPICall(async () => await uploadImageDesk(thumbnailImg.file, deskData.deskId, true),
                    'thumb-upload',
                    'Uploading thumbnail...',
                    'Thumbnail uploaded successfully',
                    'Something went wrong!'
                );
            }
            if (imageList.length) {
                let uploadPromises = imageList.map(async (img: any) => await uploadImageDesk(img, deskData.deskId, false));
                deskData.imageIds = await notifiableAPICall(async () => await Promise.all(uploadPromises),
                    'thumb-upload',
                    'Uploading images...',
                    'Images uploaded successfully',
                    'Something went wrong!'
                );
            }
            //approvalRequired is showing to user as auto approval, so we need to get !
            let { success } = await notifiableAPICall(async () => await createDesk({ ...deskData, approvalRequired: !deskData.approvalRequired}),
                'create-desk',
                'Creating desk...',
                'Desk created successfully',
                'Something went wrong!'
            );
            if (success) {
                if(cloneStatus) { 
                    onSave();
                }
                else { 
                    onAdd();
                }
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    const onSaveDesk = async () => {
        try {
            let deskData = await form.validateFields();
            setIsLoading(true);
            deskData.location = deskLocation;
            deskData.deskId = deskDetails.deskId;
            deskData.floorId = selectedFloor?.floorId;

            //new thumbnail
            if (!thumbnailImg?.isOld) {
                if (deskDetails.thumbnailFileName && deskDetails.thumbnailFileName !== "") {
                    await notifiableAPICall(async () => await deleteImage(deskData.deskId, deskDetails.thumbnailFileName, DESK_KEY),
                        'thumb-upload',
                        'Deleting old thumbnail...',
                        'Thumbnail deleted successfully',
                        'Something went wrong!'
                    );
                }
                if (thumbnailImg) {
                    deskData.thumbnailFileName = await notifiableAPICall(async () => await uploadImageDesk
                        (thumbnailImg.file, deskData.deskId, true),
                        'thumb-upload',
                        'Uploading thumbnail...',
                        'Thumbnail uploaded successfully',
                        'Something went wrong!'
                    );
                } else {
                    deskData.thumbnailFileName = "";
                }
            }
            if (!selectedFloor?.floorId) {
                notifyError(`Please select the floor!`);
                return;
            }
            if (!deskLocation.top) {
                notifyError(`Please add desk location!`);
                return;
            }
            let imagesToUpload = false;
            let uploadPromises = imageList.map(async (img: any) => {
                //img not changed
                if (img.isOld) {
                    return img.uid;
                }
                imagesToUpload = true;
                return await uploadImageDesk(img, deskData.deskId, false);
            });

            if (imageList.length) {
                const newImageIds = imageList.map(( img:any) => img.uid);
                deskData.imageIds = imagesToUpload ? await notifiableAPICall(async () => await Promise.all(uploadPromises),
                    'img-upload',
                    'Uploading images...',
                    'Images uploaded successfully',
                    'Something went wrong!'
                ) : newImageIds;
            }

            deskData.imageIds = deskData.imageIds || [];

            let imagesToDelete = deskDetails.imageIds && deskDetails.imageIds.filter((imageId: string) => !deskData.imageIds.includes(imageId));
            if (imagesToDelete && imagesToDelete.length) {
                let deletePromises = imagesToDelete.map(async (imageId: string) => await deleteImage(deskData.deskId, imageId, DESK_KEY));
                await notifiableAPICall(async () => await Promise.all(deletePromises),
                    'img-delete',
                    'Deleting removed images...',
                    'Images deleted successfully',
                    'Something went wrong!'
                );
            }
            //approvalRequired is showing to user as auto approval, so we need to get !
            let { success } = await notifiableAPICall(async () => await updateDesk({ ...deskData, approvalRequired: !deskData.approvalRequired}),
                'update-desk',
                'Saving changes...',
                'Changes saved successfully',
                'Something went wrong!'
            );
            if (success) {
                onSave();
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    return (
        <Modal
            visible={true}
            onCancel={onClose}
            closable={false}
            footer={null}
            width={890}
            bodyStyle={{
                padding: 0
            }}
            style={{
                borderRadius: 10,
                overflow: 'hidden'
            }}
            className='intnnt-mdl bs-add-space-mdl'
            centered
            maskClosable={false}
        >
            <Spin spinning={isLoading} size={'large'}>
                <Row className='intnnt-rw'>
                    <Col className='intnnt-clmn intnnt-clmn-left'>
                        {(!isEditView || (isEditView && deskDetails)) && <DeskFormView cloneStatus = {cloneStatus} form={form} desk={deskDetails} />}
                    </Col>
                    <Col className='intnnt-clmn intnnt-clmn-right intnnt-drk-10'>
                        {(!isEditView || (isEditView && deskDetails)) &&
                            <div>
                                <ImageUploadView
                                    thumbnailImg={thumbnailImg}
                                    setThumbnailImg={setThumbnailImg}
                                    imageList={imageList}
                                    setImageList={setImageList}
                                    onCreate={onCreateDesk}
                                    onSave={onSaveDesk}
                                    onCancel={onClose}
                                    desk={deskDetails}
                                    cloneStatus = {cloneStatus}
                                />
                                <FloorSelect
                                    floorList={floorList}
                                    setSelectedFloor={setSelectedFloor}
                                    selectedFloor={selectedFloor}
                                    setDeskLocation = {setDeskLocation}
                                    deskLocation = {deskLocation}
                                    setInitDeskLocation = {setInitDeskLocation}
                                    deskList = {deskList}
                                    desk = {deskDetails}
                                    cloneStatus = {cloneStatus}
                                />
                            </div>
                        }
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
};
