import { Button, Modal, List, Row, Popconfirm, Switch, Col, Input, Space, Spin, Tooltip } from "antd";
import { PlusOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import { useState } from "react";
import { notifiableAPICall } from "../utils/notification";
import { addCategory, archiveCategory } from "./actions";

type PropType = {
    onClose: Function,
    onUpdate: Function,
    categoryList: any,
    buildingId: string
}

export const CategoriesModal = ({ onClose, categoryList, onUpdate, buildingId }: PropType) => {
    const [addNew, setAddNew] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState<string>('');
    const onArchive = async (cat: any, archive: boolean) => {
        try {
            setIsLoading(true);
            let { success } = await notifiableAPICall(async () => await archiveCategory(cat.buildingId, cat.categoryId, archive),
                'archive-category',
                `${archive ? 'Archiving' : 'Unarchiving'} category...`,
                `Category ${archive ? 'archived' : 'restored'} successfully`,
                'something went wrong!'
            );
            if (success) {
                onUpdate();
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    const onAdd = async () => {
        try {
            setIsLoading(true);
            let { success } = await notifiableAPICall(async () => await addCategory(buildingId, newCategoryName),
                'add-category',
                'Adding category...',
                'Category added successfully',
                'something went wrong!'
            );
            if (success) {
                setNewCategoryName('');
                setAddNew(false);
                onUpdate();
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    const getFilteredList = () => categoryList?.filter(({ isArchived }: any) => showArchived ? isArchived : !isArchived) || [];

    return (
        <Modal
            visible={true}
            footer={null}
            onCancel={() => onClose()}
            wrapClassName="add-new-building-contact-modal"
            maskClosable={false}
            centered
        >
            <Spin spinning={isLoading} size={'large'}>
                <h2 className="new-building-contact-title">Category List</h2>
                <Row className="grid-options" align="middle">
                    <Col>Show Archived <Switch onChange={setShowArchived} /></Col>
                    <Col flex="auto" style={{ textAlign: 'end' }}>
                        {!addNew && <Button icon={<PlusOutlined />} className="btn btn--o-primary" onClick={() => setAddNew(true)}>Add New</Button>}
                    </Col>
                </Row>
                <br />
                {addNew && <Space>
                    Category: <Input value={newCategoryName} onChange={e => setNewCategoryName(e.target.value)} />
                    <Button className="btn btn--o-primary" disabled={newCategoryName?.trim().length === 0} onClick={onAdd}>Add</Button>
                    <Button className="btn btn--o-primary" onClick={() => { setAddNew(false); setNewCategoryName('') }}>Cancel</Button>
                </Space>}
                <List
                    bordered
                    dataSource={getFilteredList()}
                    style={{ marginTop: 20, marginBottom: 20 }}
                    renderItem={(item: any, index: number) => (
                        <List.Item actions={[<Popconfirm
                            title={`Are you sure you want to ${item.isArchived ? 'unarchive' : 'archive'} this category?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => onArchive(item, !item.isArchived)}
                        >
                            <Tooltip title={item.isArchived ? 'Restore' : 'Archive'}>
                                <Button icon={!item.isArchived ? <DeleteOutlined /> : <UndoOutlined />} danger type="text" />
                            </Tooltip>
                        </Popconfirm>,
                        ]}>{item.categoryName}</List.Item>
                    )}
                />
            </Spin>
        </Modal>
    );
}