import { getApi, APIS, postApi, putApi } from "../utils/api-utils";

export const getBuildingContacts = async (buildingId: string) => {
    const { buildingContacts } = await getApi(APIS.BUILDING_CONTACTS_API, '/building-contacts' , { queryStringParameters: { buildingId } })
    return buildingContacts;
}

export const addBuildingContact = async (data: any) => {
    await postApi(APIS.BUILDING_CONTACTS_API, '/building-contact', data);
}

export const changeState = async (contactId: string, buildingId: string, state: number, updatedBy : string) => {
    return await putApi(APIS.BUILDING_CONTACTS_API, '/building-contact/state', { contactId: contactId, buildingId, state: state, updatedBy });
}