import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Layout, Row, Select, Space, Input, Button, Table, Typography, Popover, Tooltip, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined, MoreOutlined, FormOutlined, EditFilled, StarFilled, ExportOutlined, IdcardOutlined } from '@ant-design/icons';
import { addUser, changeRole, changeState, editUser, getUsers, isUserExistError, revokeMobileId } from './actions';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { USER_MODAL_TYPE, USER_ROLE, USER_STATE, USER_STATE_ARRAY, CSV_EXPORT_FIELDS, ADMIN_MODAL_TYPE } from './constants';
import { toDateOnlyString } from '../utils/date-time';
import { confirm, notifiableAPICall } from '../utils/notification';
import ImportUserModal from './ImportUserModal';
import NewUserModal from './NewUserModal';
import download from 'downloadjs';

const { Parser } = require('json2csv')

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;
const { Title } = Typography;
const opts = { fields: CSV_EXPORT_FIELDS }

const ALL = "all";
const user_default = {
    userId: '',
    tenantId: '',
    buildingId: '',
    firstName: '',
    lastName: '',
    contactNumber: '',
    countryDialingCode: undefined,
    email: ''
}

export const Users = () => {
    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const tenantIdNameMapping: any = state.get(SESSION_ATTRS.TENANT_ID_NAMES);
    const buildingIdNameMapping: any = state.get(SESSION_ATTRS.BUILDING_ID_NAMES);
    const loggedUser: any = state.get(SESSION_ATTRS.LOGGED_USER);

    const [users, setUsers] = useState<Array<any>>([]);
    const [selectedUser, setSelectedUsers] = useState<any[]>([]);
    const [searchingPhrase, setSearchingPhrase] = useState<string | undefined>();
    const [selectedState, setSelectedState] = useState<number>(USER_STATE.ACTIVE);
    const [selectedBuilding, setSelectedBuilding] = useState<string>(buildings[0].buildingId);
    const [filteredUsers, setFilteredUsers] = useState<Array<any>>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isShowNewUserModal, setShowNewUserModal] = useState<boolean>(false);
    const [isShowImportUserModal, setShowImportUserModal] = useState<boolean>(false);
    const [mode, setMode] = useState(USER_MODAL_TYPE.CREATE);
    const [editRowData, setEditRowData] = useState<any>(user_default);
    const [isAdminModalVisible, setIsAdminModalVisible] = useState(false);
    const [adminModalType, setAdminModalType] = useState(ADMIN_MODAL_TYPE.ASSIGN);
    const [userRoleChangeData, setUserRoleChangeData] = useState<any>({});

    const prevStateRef = useRef<number | undefined>();

    const tenantsArrayFilter = (selectTenants: Array<string> = []) => {
        return selectTenants.map((t: string) => tenantIdNameMapping[t]).filter(t => !!t).join(', ');
    }

    const getBuildingName = (buildingId: string) => {
        return buildingIdNameMapping[buildingId];
    }

    const filterUsers = (users: Array<any>, building: string, searchString: string | undefined) => {
        let filtered = users;
        if (searchString) {
            const search = searchString.toLowerCase();
            filtered = filtered.filter(f => `${f.firstName} ${f.lastName}`.toLowerCase().includes(search));
        }
        if (building !== ALL) {
            filtered = filtered.filter(f => f.buildingId === building);
        }
        return filtered;
    }

    const loadUsers = useCallback(async (state: number) => {
        try {
            setLoading(true);
            let userList = await getUsers(state, selectedBuilding);
            userList.sort((n1: any, n2: any) => (new Date(n2.createdAt)).getTime() - (new Date(n1.createdAt)).getTime());
            setUsers(userList);
            setFilteredUsers(filterUsers(userList, selectedBuilding, searchingPhrase))
            setLoading(false);
        } catch (e) {
            console.error(e);
            setUsers([]);
            setFilteredUsers([]);
            setLoading(false);
        }
    }, [selectedBuilding, searchingPhrase]);

    useEffect(() => {
        if (prevStateRef.current !== selectedState || !isShowImportUserModal || !isShowNewUserModal) {
            prevStateRef.current = selectedState;
            loadUsers(selectedState);
        }
    }, [loadUsers, selectedState, isShowImportUserModal, isShowNewUserModal]);

    useEffect(() => {
        // setLoading(true);
        setFilteredUsers(filterUsers(users, selectedBuilding, searchingPhrase));
        // setLoading(false);
    }, [searchingPhrase, users, selectedBuilding]);

    const onSearch = (value: string | undefined) => {
        setSearchingPhrase(value);
        setSelectedUsers([]);
    };

    const onStateChange = (value: number) => {
        setSelectedState(value);
        setSelectedUsers([]);
    }

    const onBuildingChange = (value: string) => {
        setSelectedBuilding(value);
        if (value !== ALL) setSelectedUsers([]);
    }

    const statusMapper = (state: number) => {
        return state === USER_STATE.ACTIVE ? <span className="status status--active"> Active </span> : <span className="status status--inactive"> Inactive </span>;
    }

    const onConvertRole = async (userId: string, tenantId: string, currRole: string, buildingId: string) => {
        await notifiableAPICall(async () => {
            await changeRole(userId, tenantId, currRole, buildingId);
            await loadUsers(selectedState);
        },
            "user-role-change",
            "Please wait...",
            "Changed user role successfully.",
            "Something went wrong in changing user role. Please retry."
        );
    }

    const onChangeState = async (state: number, userId: string, tenantId: string, buildingId: string) => {
        confirm(state === USER_STATE.ACTIVE ? "Deactivate User" : "Activate User",
            state === USER_STATE.ACTIVE ? "Are you sure you want to deactivate this user?" : "Are you sure you want to activate this user?",
            async () => {
                await notifiableAPICall(async () => {
                    await changeState(userId, tenantId, buildingId);
                    await loadUsers(selectedState);
                    if (userId === loggedUser.userId) {
                        window.location.reload();
                        return false;
                    }
                },
                    "user-state-change",
                    "Please wait...",
                    "Changed user status successfully.",
                    "Something went wrong in change user status. Please retry."
                );
            }, () => { });

    }

    const onBulkChangeState = async () => {
        confirm(selectedState === USER_STATE.ACTIVE ? "Deactivate Users" : "Activate Users",
            selectedState === USER_STATE.ACTIVE ? "Are you sure you want to deactivate selected users?" : "Are you sure you want to activate selected users?",
            async () => {
                await notifiableAPICall(async () => {
                    const proms = selectedUser.map(async ({ userId, tenantId, buildingId }) =>
                        await changeState(userId, tenantId, buildingId));
                    await Promise.all(proms);
                    setSelectedUsers([]);
                    await loadUsers(selectedState);
                },
                    "user-bulk-state-change",
                    "Please wait...",
                    "Changed user status successfully.",
                    "Something went wrong in changing one or more user's state. Please retry."
                );
            }, () => { });
    }

    const onRevokeMobileId = async (userId: string, tenantId: string, buildingId: string) => {
        confirm("Revoke Mobile Credential",
            "This will require the user to reactivate their mobile credential within the app. Once issued, their new mobile credential will need to be configured in the building's security system. Use this function if the user is having issues activating their mobile credential.",
            async () => {
                await notifiableAPICall(
                    async () => {
                        await revokeMobileId(userId, tenantId, buildingId);
                        await loadUsers(selectedState);
                    },
                    "user-revoke-mobile-id",
                    "Please wait...",
                    "Mobile credential successfully revoked.",
                    "Something went wrong in revoking the user's mobile credential. Please retry."
                );
            }, () => {}
        );
    }

    const editModalOpen = (row: any) => {
        setMode(USER_MODAL_TYPE.EDIT);
        setEditRowData(row);
        setShowNewUserModal(true);
    }

    const onUserAddManually = () => {
        setShowImportUserModal(false);
        setEditRowData(user_default);
        setMode(USER_MODAL_TYPE.CREATE);
        setShowNewUserModal(true);
    }

    const isInBulkArray = (user: any) => {
        return selectedUser.includes(user);
    }

    const onSave = async (values: any) => {
        if (mode === USER_MODAL_TYPE.CREATE) {
            await notifiableAPICall(async () => await addUser(values),
                'add_new_user',
                'Please wait!',
                'user added successfully.',
                (e: any) => isUserExistError(e) ? "Email address is already occupied." : 'Something went wrong, Please try again.',
                true
            );
        }
        else {
            let data: any = values;
            data.userId = editRowData.userId;
            data.createdAt = editRowData.createdAt;
            data.role = editRowData.role;
            data.state = editRowData.state;

            await notifiableAPICall(async () => await editUser(data),
                'edit_user',
                'Please wait!',
                'Updated user details successfully.',
                'Something went wrong, Please try again.',
                true
            );
        }
        setShowNewUserModal(false);
        await loadUsers(selectedState);
    }

    const onBulkUserAdd = async () => {
        setShowImportUserModal(false);
        await loadUsers(selectedState);
    }

    const exportableUsers = () => {
        let exportUsersList = [];
        exportUsersList = filteredUsers.map(item => {
            let eul = {
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
                countryDialingCode: item.countryDialingCode,
                contactNumber: item.contactNumber,
                licensePlate: item.licensePlate,
                tenant: tenantsArrayFilter([item.tenantId]),
                building: getBuildingName(item.buildingId),
                status: (item.state === USER_STATE.ACTIVE) ? 'Active' : 'Inactive',
                createdDate: toDateOnlyString(item.createdAt),
                credentialId: item.credentialId,
            }
            return eul;
        });

        return exportUsersList;
    }

    const convertToCsv = () => {
        try {
            const parser = new Parser(opts);
            const csv = parser.parse(exportableUsers());
            const file = new Blob([csv], { type: 'text/csv' });
            download(file, 'Users.csv');
        } catch (err) {
            console.error(err);
        }
    }

    const rowActions = (userId: string, row: any) => {
        let content = (
            <>
                <Button className='intnnt-ppvr-btn' type='text' icon={<EditFilled />} onClick={() => editModalOpen(row)}>Edit</Button>
                {!row.isFederatedUser && <Button className='intnnt-ppvr-btn' type='text' icon={<FormOutlined />} onClick={() => openAdminModal(row.userId, row.tenantId, row.role, row.buildingId)}>{row.role !== USER_ROLE.ADMIN ? 'Assign Admin' : 'Unassign Admin'}</Button>}
                {row.state === USER_STATE.ACTIVE && <Button className='intnnt-ppvr-btn' type='text' icon={<IdcardOutlined />} onClick={() => onRevokeMobileId(row.userId, row.tenantId, row.buildingId)}>Revoke Credential</Button>}
                <Button className='intnnt-ppvr-btn' type='text' danger icon={<DeleteOutlined />} onClick={() => onChangeState(row.state, row.userId, row.tenantId, row.buildingId)}>{row.state !== USER_STATE.ACTIVE ? 'Activate' : 'Deactivate'}</Button>
            </>
        );

        return (
            <div className='intnnt-ppvr'>
                {content}
            </div>
        )
    };

    const isAdmin = (role: string) => {
        if (role === USER_ROLE.ADMIN) {
            return (
                <Tooltip title='Admin'><StarFilled style={{ color: '#104EA1' }} /></Tooltip>
            )
        }
    }

    const onAdminConfirmationOk = async () => {
        await onConvertRole(userRoleChangeData.userId, userRoleChangeData.tenantId,
            userRoleChangeData.currRole,
            userRoleChangeData.buildingId);
        setIsAdminModalVisible(false);
        if (userRoleChangeData.userId === loggedUser.userId) {
            window.location.reload();
            return false;
        }
    }

    const onAdminConfirmationCancel = () => {
        setIsAdminModalVisible(false);
    }

    const openAdminModal = (userId: string, tenantId: string, currRole: string, buildingId: string) => {
        if (currRole === USER_ROLE.USER) {
            setAdminModalType(ADMIN_MODAL_TYPE.ASSIGN);
        } else {
            setAdminModalType(ADMIN_MODAL_TYPE.Unassign);
        }
        setUserRoleChangeData({ userId, tenantId, currRole, buildingId });
        setIsAdminModalVisible(true);
    }

    const getFullName = (row: any) => row.firstName ? (row.firstName + ' ' + row.lastName + ' ') : "-";

    const moreAction = (userId: string, row: any) => isInBulkArray(row) ?
        <div className="ind-more"><MoreOutlined style={{ color: '#cecece' }} className="more-settings" /></div>
        :
        <Popover trigger='hover' placement='left' content={rowActions(userId, row)}>
            <div className="ind-more"><MoreOutlined className="more-settings" /></div>
        </Popover>

    const columns: Array<any> = [
        {
            title: 'Full Name',
            dataIndex: 'name',
            sorter: (a: any, b: any) => getFullName(a).localeCompare(getFullName(b)),
            render: (userId: string, row: any) => <span>{getFullName(row)}{isAdmin(row.role)}</span>,
        },
        {
            title: 'Tenant',
            dataIndex: 'tenantId',
            sorter: (a: any, b: any) => tenantsArrayFilter([a.tenantId]).localeCompare(tenantsArrayFilter([b.tenantId])),
            render: (tenantId: string) => <span className="tenantsArrayFilter">{tenantsArrayFilter([tenantId])}</span>,
        },
        {
            title: 'Building',
            dataIndex: 'buildingId',
            sorter: (a: any, b: any) => getBuildingName(a.buildingId).localeCompare(getBuildingName(b.buildingId)),
            render: (buildingId: string) => getBuildingName(buildingId),
        },
        {
            title: 'Contact No.',
            dataIndex: 'contactNo',
            render: (userId: string, row: any) => <span>{row.contactNumber ? (row.countryDialingCode + ' ' + row.contactNumber) : "-"}</span>,
        },
        {
            title: 'Credential',
            dataIndex: 'credentialId'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Status',
            dataIndex: 'state',
            render: (state: number) => statusMapper(state),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            render: (createdAt: string) => <span>{toDateOnlyString(createdAt)}</span>
        },
        {
            title: '',
            dataIndex: 'newsId',
            key: 'newsId',
            fixed: 'right',
            render: ((userId: string, row: any) => (
                moreAction(userId, row)
            ))
        }
    ];

    const rowSelection = {
        selectedRowKeys: selectedUser.map(i => i.userId),
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectedUsers(selectedRows);
        }
    };

    const tableHeader = () => {
        if (selectedUser.length === 0) return null;
        let content = null;
        switch (selectedState) {
            case USER_STATE.ACTIVE:
                content = (<>
                    <Button type="text" className="link-btn link-btn--danger" danger icon={<DeleteOutlined />} onClick={onBulkChangeState}>Deactivate</Button>
                </>);
                break;
            case USER_STATE.INACTIVE:
                content = (<>
                    <Button type="text" className="link-btn link-btn--danger" danger icon={<DeleteOutlined />} onClick={onBulkChangeState}>Activate</Button>
                </>);
                break;
            default:
                content = null;
        }
        return (<Space>
            <span className="select-text">{selectedUser.length} selected</span>
            <span className="divider-vertical"></span>
            {content}
        </Space>);
    }

    const AdminConfirmationModal = isAdminModalVisible ?
        <Modal
            visible={isAdminModalVisible}
            footer={null}
            centered
            onCancel={onAdminConfirmationCancel}
            maskClosable={false}
            className='admin-modal-body'
        >
            <div>
                <p className='admin-modal-title'>{adminModalType}</p>
                <p className='admin-modal-text'>{adminModalType === ADMIN_MODAL_TYPE.ASSIGN ? "This person will now have admin permissions." : "Do you want to continue?"}</p>
                <div className='admin-modal-btn-container'>
                    <Button className='btn btn-bordered' onClick={onAdminConfirmationCancel}>Cancel</Button>
                    <Button className='btn btn--primary' onClick={onAdminConfirmationOk}>Confirm</Button>
                </div>
            </div>
        </Modal>
        :
        null

    return (
        <>
            { isShowImportUserModal && <ImportUserModal
                onClose={() => setShowImportUserModal(false)}
                onUserAddManually={onUserAddManually}
                onBulkUserAddSucess={onBulkUserAdd}
                onUserAdd={addUser}
            />}
            {
                isShowNewUserModal && <NewUserModal
                    onClose={() => setShowNewUserModal(false)}
                    onSubmit={onSave}
                    mode={mode}
                    editRowData={editRowData}
                />
            }
            {
                isAdminModalVisible && AdminConfirmationModal
            }
            <Space direction='vertical'>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Row style={{ marginTop: 15, justifyContent: "space-between" }}>
                        <Title level={4}>
                            {filteredUsers.length} Users
                        </Title>
                        <Button type='link' style={{ color: '#104EA1', fontSize: 12, fontWeight: 600 }} icon={<ExportOutlined style={{ color: '#104EA1', fontWeight: 600 }} />} onClick={convertToCsv}>Export .CSV</Button>
                    </Row>
                    <Row className="grid-options">
                        <Space>
                            <Select defaultValue={USER_STATE.ACTIVE} onChange={onStateChange} >
                                {USER_STATE_ARRAY.map(({ name, state }) => <Option key={state} value={state}>{name}</Option>)}
                            </Select>
                            <Select defaultValue={buildings[0].buildingId} onChange={onBuildingChange} >
                                {/* <Option value={ALL}>All Buildings</Option> */}
                                {buildings.map((building: any) => (
                                    <Option key={building.buildingId} value={building.buildingId}>{building.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Space className="grid-options--search">
                            <Search placeholder="Search" allowClear onChange={(e) => onSearch(e.target.value)} style={{ width: 400 }} />
                            <Button
                                className="btn btn--primary"
                                icon={<PlusOutlined />}
                                onClick={() => setShowImportUserModal(true)}
                            >
                                Add New
                        </Button>
                        </Space>
                    </Row>
                </Space>
                <Content className="table-wrapper">
                    <Table rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredUsers}
                        loading={isLoading}
                        rowKey='userId'
                        title={tableHeader}
                        pagination={{
                            total: filteredUsers.length,
                            showSizeChanger: true,
                            showTotal: (total, range) => `showing ${range[0]}-${range[1]} out of ${total} users`
                        }} />
                </Content>
            </Space>
        </>
    )
};
