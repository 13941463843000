import { useContext, useState } from 'react';
import { Form, Input, Row, Select, Space } from 'antd';
import { sessionStore, SESSION_ATTRS } from '../../store/session';
import { getBuildingTenants } from '../../utils/common-utils';

const { TextArea } = Input;
const { Option } = Select;

export const IssueFormView = ({ form, categories }: any) => {
    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const [tenants, setTenants] = useState([]);

    const onFieldsChange = (changedFields: Array<any>) => {
        if (changedFields.length) {
            let item = changedFields.find(({ name }: any) => name[0] === 'buildingId');
            if(item){
                const buildingId = item.value;
                const buildingTenants = getBuildingTenants(state.get(SESSION_ATTRS.TENANTS), buildingId);
                setTenants(buildingTenants);
            }
        }
    }

    return (
        <Space direction='vertical' align='baseline' className='intnnt-spc intnnt-spc-wdth'>
            <Row className='title'>New Issue</Row>
            <Row>
                <p className='intnnt-sbttl'>Information</p>
            </Row>
            <Form
                name='induction-information-form'
                layout={'vertical'}
                requiredMark={false}
                form={form}
                onFieldsChange={onFieldsChange}
            >
                <Form.Item name='categoryId' label="Category" rules={[{ required: true, message: 'Please select category!' }]} >
                    <Select placeholder='Select a category' className='intnnt-npt' >
                        {categories?.map((category: any) => <Option key={category.categoryId} value={category.categoryId}>{category.categoryName}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item name='description' label="Issue" rules={[{ required: true, message: 'Please input the issue!' }]} >
                    <Input placeholder='Enter issue description' className='intnnt-npt' maxLength={500} />
                </Form.Item>
                <Form.Item name='notes' label='Notes' rules={[{ required: true, message: 'Please input notes!' }]} >
                    <TextArea rows={4} placeholder='Tell more about the issue' className='intnnt-npt' maxLength={500} />
                </Form.Item>
                <Form.Item name='buildingId' label='Building’s name' rules={[{ required: true, message: 'Please select a building!' }]} >
                    <Select placeholder='Select a building' className='intnnt-npt'>
                        {buildings.map((building: any) => {
                            return (
                                <Option key={building.buildingId} value={building.buildingId}>
                                    <div>
                                        <p className='intnnt-bldng-nm'>{building.name}</p>
                                        <p className='intnnt-bldng-lctn'>{building.geolocation}</p>
                                    </div>
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item name='tenant' label='Tenant' rules={[{ required: true, message: 'Please select companies!' }]} >
                    <Select
                        showSearch
                        className='intnnt-npt'
                        style={{ width: '100%' }}
                        options={tenants.map((tenant: any) => { return { label: tenant.name, value: tenant.tenantId } })}
                        placeholder='Select company'
                    />
                </Form.Item>
            </Form>
        </Space>
    );
}