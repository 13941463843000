import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { toDateString } from '../utils/date-time';
import { ClockCircleFilled, DownloadOutlined, ShopOutlined } from '@ant-design/icons';
import { getThumbnail, getVideo } from './actions';

type PropType = {
    details: any,
    onClose: Function,
    buildingName: string,
    onDownload: Function
}

const VideoPlayerModal = ({ details, onClose, buildingName, onDownload }: PropType) => {

    const [videoLink, setVideoLink] = useState<string | undefined>(undefined);
    const [thumbUrl, setThumbUrl] = useState<string | undefined>(undefined);

    useEffect(() => {
        async function loadVideoLink() {
            const link = await getVideo(details.videoKey);
            setVideoLink(link);
        }

        loadVideoLink();
    }, [details.videoKey]);

    useEffect(() => {
        async function loadThumbUrl() {
            const link = await getThumbnail(details.thumbKey);
            setThumbUrl(link);
        }

        loadThumbUrl();
    }, [details.thumbKey]);

    const onDownloadClick = async () => {
        onDownload(details.videoKey, details.title);
    }

    return <Modal
        maskClosable={false}
        centered
        closable
        visible
        footer={null}
        onCancel={() => onClose()}
        width={720}
        wrapClassName={"ind-video-player-modal"}
    >
        <div className="ind-video-player">
            <ReactPlayer playing light={thumbUrl} width={718} controls url={videoLink} />
        </div>
        <div className="ind-video-footer">
            <div className="ind-video-footer-col">
                <h4>{details.title}</h4>
                <div className="ind-video-date"><ClockCircleFilled />{toDateString(details.createdAt)}</div>
            </div>
            <div className="ind-video-footer-col">
                <h5><ShopOutlined />{buildingName}</h5>
                <Button type='text' className="btn btn--info" size="small" icon={<DownloadOutlined />} onClick={onDownloadClick} >Download</Button>
            </div>
        </div>
    </Modal>
}

export default VideoPlayerModal;