import { Row, Select, Space, Input, Typography, Layout, Table, Tooltip, Col, Button } from 'antd';
import { useState, useContext, useEffect, useCallback } from 'react';
import { BOOKING_STATUS, BOOKING_STATUS_ARRAY, BOOKING_TIME_FORMAT, HISTORY_BOOKING_CSV_EXPORT_FIELDS, HISTORY_STATUS_ARRAY } from './constants';
import { ArrowLeftOutlined, CheckCircleFilled, StopOutlined, QuestionCircleFilled, ExportOutlined, ExclamationCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import moment from 'moment';
import { clockIcon, locationPinIcon } from './svgIcons';
import { getBookingRequests } from './actions';
import { ALL } from './DeskBookingView';
import { convertToCsv } from '../utils/common-utils';
import { DatePopup } from './DatePopup';
import { notifiableAPICall } from '../utils/notification';

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;
const { Title } = Typography;
const opts = { fields: HISTORY_BOOKING_CSV_EXPORT_FIELDS }

export const BookingHistory = ({ deskList, setSelectedView, setSelectedBuilding, buildings, prevSelectedView, getFloorByFloorId }: any) => {

    const [bookingStatusFilter, setBookingStatusFilter] = useState(BOOKING_STATUS_ARRAY[0].value);
    const [bookingSearchText, setBookingSearchText] = useState<string>('');
    const [bookingRequestList, setBookigRequestList] = useState<Array<any>>([]);
    const [filteredList, setFilteredList] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [exportModalVisible, setExportModalVisible] = useState<boolean>(false);

    const { state } = useContext(sessionStore);
    const user: any = state.get(SESSION_ATTRS.LOGGED_USER);
    const buildingIdNameMapping: any = state.get(SESSION_ATTRS.BUILDING_ID_NAMES);

    const loadRequests = useCallback(async () => {
        try {
            const requestList = await getBookingRequests(user.buildingId, "", true);
            setBookigRequestList(requestList);
        }
        catch (err) {
            setBookigRequestList([]);
            setFilteredList([]);
        }
    }, [user]);

    const loadBookingRequests = useCallback(async () => {
        setIsLoading(true);
        await loadRequests();
        setIsLoading(false);
    }, [loadRequests]);

    useEffect(() => {
        if (deskList !== undefined) {
            loadBookingRequests();
        }
        else {
            setIsLoading(true);
        }
    }, [loadBookingRequests, deskList]);

    const historyStatusFilter = useCallback((status) => {
        if (status === 1) {
            return bookingRequestList;
        }
        else {
            const selectedRequests = bookingRequestList.filter((req: any) => req.reqStatus === status);
            return selectedRequests;
        }
    }, [bookingRequestList]);

    const filterBookingRequests = useCallback((BookingRequests: Array<any>, searching: (string | undefined)) => {
        let filtered;
        if (searching) {
            const searchingText = searching.toLowerCase().trim();
            filtered = BookingRequests.filter(i => {
                const desk = deskList.find((desk: any) => desk.deskId === i.deskId)
                const phrase = desk.name + i.createdBy.name;
                if (bookingStatusFilter === HISTORY_STATUS_ARRAY[0].value) {
                    return phrase.toLowerCase().includes(searchingText);
                }
                return phrase.toLowerCase().includes(searchingText) && i.reqStatus === bookingStatusFilter;
            });
        } else {
            filtered = historyStatusFilter(bookingStatusFilter);
        }
        return filtered;
    }, [deskList, bookingStatusFilter, historyStatusFilter]);

    useEffect(() => {
        if (bookingRequestList.length !== 0) {
            setIsLoading(true);
            setFilteredList(filterBookingRequests(bookingRequestList, bookingSearchText));
            setIsLoading(false);
        }
    }, [bookingSearchText, bookingRequestList, filterBookingRequests]);

    useEffect(() => {
        if (bookingRequestList.length !== 0) {
            setIsLoading(true);
            setBookingSearchText('');
            setFilteredList(historyStatusFilter(bookingStatusFilter));
            setIsLoading(false);
        }
    }, [bookingStatusFilter, bookingRequestList, historyStatusFilter]);

    const getStatusName = (status: number) => {
        switch (status) {
            case BOOKING_STATUS.DECLINED:
                return "Declined";
            case BOOKING_STATUS.PENDING:
                return "Pending";
            case BOOKING_STATUS.APPROVED:
                return "Approved";
            case BOOKING_STATUS.CANCELLED:
                return "Cancelled";
            default:
                break;
        }
    }

    const exportableRequests = (allRequests: any) => {
        let exportRequestsList = [];
        exportRequestsList = allRequests.map((item: any) => {
            let startTime = moment(item.startTime);
            let endTime = moment(item.endTime);
            let desk = deskList?.find((desk: any) => desk.deskId === item.deskId);
            let erl = {
                'Building': buildingIdNameMapping[item.buildingId],
                'Floor': getFloorByFloorId(desk.floorId),
                'Desk': desk?.name,
                'Start Time': startTime.format(BOOKING_TIME_FORMAT),
                'End Time': endTime.format(BOOKING_TIME_FORMAT),
                'Booking Duration (hours)': moment.duration(endTime.diff(startTime)).asHours(),
                'Booked By': item.createdBy.name,
                'Approved By': item.reqStatus === BOOKING_STATUS.APPROVED ? item.approvedBy.name : "-",
                'Approved At': item.approvedAt ? moment(item.approvedAt).format(BOOKING_TIME_FORMAT) : "-",
                'Cancelled By': item.cancelledBy ? item.cancelledBy.name : "-",
                'Cancelled At': item.cancelledAt ? moment(item.cancelledAt).format(BOOKING_TIME_FORMAT) : "-",
                'Declined By': item.reqStatus === BOOKING_STATUS.DECLINED ? item.declinedBy.name : "-",
                'Declined At': item.declinedAt ? moment(item.declinedAt).format(BOOKING_TIME_FORMAT) : "-",
                'Decline Reason': item.reqStatus === BOOKING_STATUS.DECLINED ? item.declineReason : "-",
                'Status': getStatusName(item.reqStatus),
                'Per Hour Cost($)': desk.hourlyPrice,
            }
            return erl;
        });

        return exportRequestsList;
    }

    const exportBookings = async (from: any) => {
        const bookingRequests = await notifiableAPICall(() => getBookingRequests(user.buildingId, '', true, from),
            'get_all_req',
            "Please wait...",
            "Booking requests loaded",
            "Something went wrong.",
            true);
        const data = exportableRequests(bookingRequests);
        convertToCsv(opts, data, "History_Booking_Requests.csv");
        setExportModalVisible(false);
    }

    const requestRoom = (deskId: string) => {
        const desk = deskList.find((desk: any) => desk.deskId === deskId);
        return (
            <>
                <div className="bs-title">
                    <div className="bs-title-img-wrapper fill">
                        <img className="bs-title-img" src={desk.thumbnailLink ? desk.thumbnailLink : "/images/no_image3.jpg"} alt={desk.name} />
                    </div>
                    <div className="bs-title-label">
                        <Row className="bs-title-text" style={{ flexFlow: 'nowrap' }}>
                            <Col style={{ maxWidth: 150, width: 'max-content' }}>{desk.name}</Col>
                            <Col>{desk?.advancedBookingRequired && <div style={{ marginLeft: 5 }}>{clockIcon}</div>}</Col>
                        </Row>
                        <Row gutter={5} className='bs-sub-title-text'>
                            <Col>
                                {locationPinIcon}
                            </Col>
                            <Col>
                                {getFloorByFloorId(desk.floorId)}
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }

    const dateTimeFormat = (row: any) => {
        if (moment(row.startTime).isSame(moment(row.endTime), 'day')) {
            return (
                <>
                    <Row>{moment(row.startTime).format("dddd, DD MMMM")}</Row>
                    <Row className='bs-time-text'>{moment(row.startTime).format("hh:mm A") + ' - ' + moment(row.endTime).format("hh:mm A")}</Row>
                </>
            );
        }
        else {
            return (
                <>
                    <Row gutter={5}>
                        <Col style={{ marginRight: 3 }}>{moment(row.startTime).format("dddd, DD MMMM")}</Col>
                        <Col className='bs-time-text'>{moment(row.startTime).format("hh:mm A") + ' - '}</Col>
                    </Row>
                    <Row>
                        <Col style={{ marginRight: 3 }}>{moment(row.endTime).format("dddd, DD MMMM")}</Col>
                        <Col className='bs-time-text'>{moment(row.endTime).format("hh:mm A")}</Col>
                    </Row>
                </>
            );
        }
    }

    const columns: Array<any> = [
        {
            title: 'Desks',
            dataIndex: 'deskId',
            render: (deskId: string, row: any) => requestRoom(deskId)
        },
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            render: (requestId: string, row: any) => (
                <div className="bs-title-label" style={{ width: 200 }}>
                    {dateTimeFormat(row)}
                </div>
            )
        },
        {
            title: 'Building',
            dataIndex: 'buildingId',
            render: (buildingId: string) => buildings.find((b: any) => b.buildingId === buildingId)?.name
        },
        {
            title: 'Booker',
            dataIndex: 'createdBy',
            render: (createdBy: any, row: any) => (
                <div className="bs-title-label">
                    {createdBy.name}
                </div>
            )
        },
        // {
        //     title: 'Reason for booking',
        //     dataIndex: 'bookingReason',
        //     render: (bookingReason: any, row: any) => (
        //         <div className="bs-title-label">
        //             <Row className='bs-rw'>
        //                 <Tooltip title={bookingReason ? bookingReason : "N/A"} trigger='hover'>
        //                     <p className='bs-txt-wrppr'>{bookingReason ? bookingReason : "N/A"}</p>
        //                 </Tooltip>
        //             </Row>
        //         </div>
        //     )
        // },
        {
            title: 'Status',
            dataIndex: 'reqStatus',
            render: (reqStatus: any, row: any) => (
                <div className="bs-title-label">
                    <Row className='bs-rw-sngl' style={{ flexFlow: 'nowrap' }}>
                        {reqStatus === 2 ?
                            <div className='bs-tbl-stts-rw'><CheckCircleFilled style={{ color: '#09B95B', marginTop: 3, marginRight: 5 }} /><p>Accepted</p></div>
                            :
                            reqStatus === -1 ?
                                <div className='bs-tbl-stts-rw'>
                                    <StopOutlined style={{ color: '#E83034', marginTop: 3, marginRight: 5 }} /><p>Declined</p>
                                    <Tooltip title={<><p style={{ color: '#F4F8FA', fontSize: '12px', fontWeight: 600, marginBottom: '2px' }}>Reason</p><p style={{ color: '#F4F8FA', fontSize: '12px' }}>{row.declineReason}</p></>}><QuestionCircleFilled style={{ color: '#A0AEC0', marginTop: 3, marginLeft: 10 }} /></Tooltip>
                                </div>
                                :
                                reqStatus === -2 ?
                                    <div className='bs-tbl-stts-rw'>
                                        <ExclamationCircleOutlined style={{ color: '#E83034', marginTop: 3, marginRight: 5 }} /><p>Cancelled</p>
                                    </div>
                                    :
                                    reqStatus === 1 ?
                                        <div className='bs-tbl-stts-rw'>
                                            <ClockCircleOutlined style={{ color: '#104EA1', marginTop: 3, marginRight: 5 }} /><p>Pending Expired</p>
                                        </div>
                                        : null
                        }
                    </Row>
                </div>
            )
        },
    ]

    return (
        <>
            {exportModalVisible &&
                <DatePopup
                    title="Export Past Boookings"
                    okButtonText="Export"
                    onCancel={() => setExportModalVisible(false)}
                    onConfirm={exportBookings}
                    defaultDate={moment().subtract('1', 'month')}
                    label="From" />}
            <Space direction='vertical'>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Row style={{ marginTop: 15, justifyContent: 'space-between' }}>
                        <Title level={4}>
                            <ArrowLeftOutlined size={15} style={{ marginRight: 10 }} onClick={() => setSelectedView(prevSelectedView)} />
                            Booking History
                        </Title>
                        <Button type='link' style={{ color: '#104EA1', fontSize: 12, fontWeight: 600 }} icon={<ExportOutlined style={{ color: '#104EA1', fontWeight: "bold" }} />} onClick={() => setExportModalVisible(true)}>Export .csv</Button>
                    </Row>
                    <Row className="grid-options" style={{ justifyContent: 'space-between' }}>
                        <Space>
                            <Select value={bookingStatusFilter} onChange={setBookingStatusFilter} >
                                {HISTORY_STATUS_ARRAY.map(({ filterName, value }: any) => (
                                    <Option key={value} value={value}>{filterName}</Option>
                                ))}
                            </Select>
                            <Select defaultValue={ALL} style={{ width: 160 }} onChange={setSelectedBuilding}>
                                <Option value={ALL}>All Buildings</Option>
                                {buildings.map((building: any) => (
                                    <Option key={building.buildingId} value={building.buildingId}>{building.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Search placeholder="Search" value={bookingSearchText} allowClear onChange={(e) => setBookingSearchText(e.target.value)} className="grid-search-box" />
                    </Row>
                </Space>
                <Content className="table-wrapper">
                    <Table rowKey="requestId"
                        columns={columns}
                        dataSource={filteredList}
                        loading={isLoading}
                        pagination={{
                            total: filteredList.length,
                            showSizeChanger: true,
                            showTotal: (total, range) => `showing ${range[0]}-${range[1]} out of ${total} requests`
                        }} />
                </Content>
            </Space >
        </>
    )
};
