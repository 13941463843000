import { getApi, APIS, postApi, S3_METHODS, uploadFile, putApi } from "../utils/api-utils";
import { getFileExtension } from "../utils/common-utils";
import { ulid } from 'ulid';

export const uploadImage = async (image: File, issueId: string, isThumbnail: boolean) => {
    const ext = getFileExtension(image.name);
    let fileName = isThumbnail ? `thumbnail.${ext}` : `${ulid()}.${ext}`
    const key = `${issueId}/images/${fileName}`;
    const url = await getSignedUrl(key, image.type, S3_METHODS.PUT, 900);
    await uploadFile(url, image, image.type);
    return fileName;
}

export const getSignedUrl = async (key: string, contentType: string | undefined, method: string, expires: number) => {
    const params = {
        key: key,
        contentType: contentType,
        method: method,
        expires: expires
    }
    const { url } = await getApi(APIS.ISSUES_API, '/signedurl', { queryStringParameters: params });
    return url;
}

export const addIssue = async (issue: Object) => {
    return await postApi(APIS.ISSUES_API, '/issue', issue);
}

export const getIssues = async (buildingId: String) => {
    return await getApi(APIS.ISSUES_API, '/issue-by-building', { queryStringParameters: { buildingId } });
}

export const getCategories = async (buildingId: String) => {
    return await getApi(APIS.ISSUES_API, '/categories', { queryStringParameters: { buildingId } });
}

export const getStatusList = async (buildingId: String) => {
    return await getApi(APIS.ISSUES_API, '/status', { queryStringParameters: { buildingId } });
}

export const addComment = async (buildingId: String, issueId: String, comment: String) => {
    return await postApi(APIS.ISSUES_API, '/issue-comment', { buildingId, issueId, comment });
}

export const changeIssueStatus = async (buildingId: String, issueId: String, oldStatus: any, newStatus: any) => {
    return await putApi(APIS.ISSUES_API, '/issue-status', { buildingId, issueId, oldStatus, newStatus });
}

export const archiveStatus = async (buildingId: String, statusId: String, archive: boolean) => {
    return await putApi(APIS.ISSUES_API, '/archive-issue-status', { buildingId, statusId, archive });
}

export const addStatus = async (buildingId: String, statusName: String, index: number) => {
    return await postApi(APIS.ISSUES_API, '/issue-status', { statusName, index, buildingId });
}

export const updateStatusIndex = async (buildingId: String, statusId: String, index: number) => {
    return await putApi(APIS.ISSUES_API, '/issue-status-index', { statusId, index, buildingId });
}

export const archiveCategory = async (buildingId: String, categoryId: String, archive: boolean) => {
    return await putApi(APIS.ISSUES_API, '/archive-issue-category', { buildingId, categoryId, archive });
}

export const addCategory = async (buildingId: String, categoryName: String) => {
    return await postApi(APIS.ISSUES_API, '/issue-category', { categoryName, buildingId });
}