import { APIS, getApi, postApi, putApi, S3_METHODS, uploadFile } from "../utils/api-utils";
import { getFileExtension } from "../utils/common-utils";
import { ulid } from 'ulid';
import moment from "moment";
import { DESK_KEY, FLOOR_KEY } from "./constants";

export const uploadImageDesk = async (image: File, deskId: string, isThumbnail: boolean) => {
    const ext = getFileExtension(image.name);
    let fileName = isThumbnail ? `thumbnail.${ext}` : `${ulid()}.${ext}`
    const key = `${deskId}/images/${fileName}`;
    const url = await getSignedUrl(key, image.type, S3_METHODS.PUT, 900, DESK_KEY);
    await uploadFile(url, image, image.type);
    return fileName;
}

export const uploadImage = async (image: File, floorId: string, isThumbnail: boolean) => {
    const ext = getFileExtension(image.name);
    let fileName = isThumbnail ? `thumbnail.${ext}` : `${ulid()}.${ext}`
    const key = `${floorId}/images/${fileName}`;
    const url = await getSignedUrl(key, image.type, S3_METHODS.PUT, 900, FLOOR_KEY);
    await uploadFile(url, image, image.type);
    return fileName;
}

export const getSignedUrl = async (key: string, contentType: string | undefined, method: string, expires: number, type: any) => {
    const params = {
        key: key,
        contentType: contentType,
        method: method,
        expires: expires,
        type
    }
    const { url } = await getApi(APIS.DESK_BOOKING_API, '/signedurl', { queryStringParameters: params });
    return url;
}

export const createDesk = async (deskData: Object) => {
    return await postApi(APIS.DESK_BOOKING_API, '/desk', deskData);
}

export const updateDesk = async (deskData: Object) => {
    return await putApi(APIS.DESK_BOOKING_API, '/desk', deskData);
}

export const createFloor = async (floorData: Object) => {
    return await postApi(APIS.DESK_BOOKING_API, '/floor', floorData);
}

export const updateFloor = async (floorData: Object) => {
    return await putApi(APIS.DESK_BOOKING_API, '/floor', floorData);
}

export const getFloorList = async (buildingId: string) => {
    let from = moment().startOf('day');
    return await getApi(APIS.DESK_BOOKING_API, `/floor/${buildingId}`, {
        queryStringParameters: {
            forAdmin: true,
            availabilityFrom: from.toISOString(),
            availabilityTo: from.add(1, 'day').startOf('day').toISOString()
        }
    });
}

export const getBookingRequests = async (buildingId: string, bookingStatus: string, isHistory?: boolean, from?: any, to?: any) => {
    let params: any = {};
    if (from) params.from = from.toISOString();
    if (to) params.to = to.endOf('day').toISOString();
    if (isHistory) params.isHistory = true;
    return await getApi(APIS.DESK_BOOKING_API, `/booking-requests/${buildingId}/${bookingStatus}`, { queryStringParameters: params });
}

export const getAllBookingRequests = async (buildingId: string) => {
    return await getApi(APIS.DESK_BOOKING_API, `/booking-requests/${buildingId}`, { queryStringParameters: { getAll: true } });
}

export const getDeskBookingRequests = async (buildingId: string, deskId: string, forCalendarView: boolean = false) => {
    return await getApi(APIS.DESK_BOOKING_API, `/desk-booking-requests/${buildingId}/${deskId}`, { queryStringParameters: { forCalendarView } });
}

export const approveRequest = async (buildingId: string, requestId?: string, requestIds?: any) => {
    const params = {
        buildingId: buildingId,
        requestId: requestId,
        requestIds: requestIds
    }
    return await postApi(APIS.DESK_BOOKING_API, '/approve-booking-request', params);
}

export const declineRequest = async (buildingId: string, reason: string, requestId?: string, requestIds?: any) => {
    const params = {
        buildingId: buildingId,
        requestId: requestId,
        declineReason: reason,
        requestIds:requestIds
    }
    return await postApi(APIS.DESK_BOOKING_API, '/decline-booking-request', params);
}

export const getMaskedBookingRequests = async (buildingId: string, deskId: string) => {
    return await getApi(APIS.DESK_BOOKING_API, `/masked-booking-requests/${buildingId}/${deskId}`);
}

export const addBookingRequest = async (bookingRequest: any) => {
    return await postApi(APIS.DESK_BOOKING_API, '/booking-request', bookingRequest);
}

export const getFloor = async (buildingId: string, floorId: string) => {
    return await getApi(APIS.DESK_BOOKING_API, `/floor/${buildingId}/${floorId}`);
}

export const deleteImage = async (id: string, fileName: string, type: any) => {
    return await putApi(APIS.DESK_BOOKING_API, '/delete-img', { id, fileName, type });
}

export const deleteFloor = async (buildingId: string, floorId: string) => {
    return await putApi(APIS.DESK_BOOKING_API, '/delete-floor', { floorId, buildingId });
}

export const getDeskList = async (buildingId: string) => {
    let from = moment().startOf('day');
    return await getApi(APIS.DESK_BOOKING_API, `/desk/${buildingId}`, {
        queryStringParameters: {
            forAdmin: true,
            availabilityFrom: from.toISOString(),
            availabilityTo: from.add(1, 'day').startOf('day').toISOString()
        }
    });
}

export const getDesk = async (buildingId: string, deskId: string) => {
    return await getApi(APIS.DESK_BOOKING_API, `/desk/${buildingId}/${deskId}`);
}

export const deleteDesk = async (buildingId: string, deskId: string) => {
    return await putApi(APIS.DESK_BOOKING_API, '/delete-desk', { deskId, buildingId });
}

export const checkFloorDeskAvailability = async (buildingId: string, floorId: string) => {
    return await getApi(APIS.DESK_BOOKING_API, '/check-floor-desks', { queryStringParameters: { buildingId, floorId } });
}

export const getDeskListByFloorId = async (buildingId: string, floorId: string, availabilityFrom?: string, availabilityTo?: string): Promise<any[]> => {
    const params = {
        queryStringParameters: {
            availabilityFrom: availabilityFrom,
            availabilityTo: availabilityTo,
        }
    }
    return await getApi(APIS.DESK_BOOKING_API, `/floor/get-floor-bookings/${buildingId}/${floorId}`, params);
}