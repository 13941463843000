import { Modal, Row, Col, Spin, Form } from 'antd';
import { SpaceFormView } from './SpaceFormView';
import { ImageUploadView } from './ImageUploadView';
import { createSpace, deleteImage, getSpace, updateSpace, uploadImage } from '../../actions';
import { useEffect, useState } from 'react';
import { ulid } from 'ulid';
import { notifiableAPICall, notifyError } from '../../../utils/notification';

export const BookingSpaceModal = ({ onClose, space, onAdd, onSave }: any) => {
    const [thumbnailImg, setThumbnailImg] = useState<any>();
    const [imageList, setImageList] = useState<any>([]);
    const [spaceDetails, setSpaceDetails] = useState<any>();
    const [isLoading, setIsLoading] = useState(space ? true : false);
    const [form] = Form.useForm();
    let isEditView = space !== undefined;

    useEffect(() => {
        if (isEditView) {
            getSpace(space.buildingId, space.spaceId).then(sp => {
                setImageList(sp.imageIds.map((uid: string, i: number) => { return { uid, isOld: true, url: sp.images[i] }; }));
                setThumbnailImg({ url: sp.thumbnailLink, isOld: true });
                setSpaceDetails(sp);
                setIsLoading(false);
            });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCreateSpace = async () => {
        try {
            let spaceData = await form.validateFields();
            if (!thumbnailImg) {
                notifyError(`Thumbnail can't be empty!`);
                return;
            }
            if (imageList.length === 0) {
                notifyError(`Images can't be empty!`);
                return;
            }
            setIsLoading(true);
            spaceData.spaceId = ulid();
            spaceData.thumbnailFileName = await notifiableAPICall(async () => await uploadImage(thumbnailImg.file, spaceData.spaceId, true),
                'thumb-upload',
                'Uploading thumbnail...',
                'Thumbnail uploaded successfully',
                'something went wrong!'
            );
            let uploadPromises = imageList.map(async (img: any) => await uploadImage(img, spaceData.spaceId, false));
            spaceData.imageIds = await notifiableAPICall(async () => await Promise.all(uploadPromises),
                'thumb-upload',
                'Uploading images...',
                'Images uploaded successfully',
                'something went wrong!'
            );
            //approvalRequired is showing to user as auto approval, so we need to get !
            let { success } = await notifiableAPICall(async () => await createSpace({ ...spaceData, approvalRequired: !spaceData.approvalRequired }),
                'create-space',
                'Creating space...',
                'Space created successfully',
                'something went wrong!'
            );
            if (success) {
                onAdd();
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    const onSaveSpace = async () => {
        try {
            let spaceData = await form.validateFields();
            if (!thumbnailImg) {
                notifyError(`Thumbnail can't be empty!`);
                return;
            }
            if (imageList.length === 0) {
                notifyError(`Images can't be empty!`);
                return;
            }
            setIsLoading(true);
            spaceData.spaceId = spaceDetails.spaceId;
            //new thumbnail
            if (!thumbnailImg.isOld) {
                console.log({ newThumb: thumbnailImg });
                await notifiableAPICall(async () => await deleteImage(spaceData.spaceId, spaceDetails.thumbnailFileName),
                    'thumb-upload',
                    'Deleting old thumbnail...',
                    'Thumbnail deleted successfully',
                    'something went wrong!'
                );
                spaceData.thumbnailFileName = await notifiableAPICall(async () => await uploadImage(thumbnailImg.file, spaceData.spaceId, true),
                    'thumb-upload',
                    'Uploading thumbnail...',
                    'Thumbnail uploaded successfully',
                    'something went wrong!'
                );
            }
            let imagesToUpload = false;
            let uploadPromises = imageList.map(async (img: any) => {
                //img not changed
                if (img.isOld) {
                    return img.uid;
                }
                imagesToUpload = true;
                return await uploadImage(img, spaceData.spaceId, false);
            });

            const newImageIds = imageList.map(( img:any) => img.uid);

            spaceData.imageIds = imagesToUpload ? await notifiableAPICall(async () => await Promise.all(uploadPromises),
                'img-upload',
                'Uploading images...',
                'Images uploaded successfully',
                'something went wrong!'
            ) : newImageIds;

            let imagesToDelete = spaceDetails.imageIds.filter((imageId: string) => !spaceData.imageIds.includes(imageId));
            if (imagesToDelete.length) {
                let deletePromises = imagesToDelete.map(async (imageId: string) => await deleteImage(spaceData.spaceId, imageId));
                await notifiableAPICall(async () => await Promise.all(deletePromises),
                    'img-delete',
                    'Deleting removed images...',
                    'Images deleted successfully',
                    'something went wrong!'
                );
            }
            //approvalRequired is showing to user as auto approval, so we need to get !
            let { success } = await notifiableAPICall(async () => await updateSpace({ ...spaceData, approvalRequired: !spaceData.approvalRequired }),
                'update-space',
                'Saving changes...',
                'Changes saved successfully',
                'something went wrong!'
            );
            if (success) {
                onSave();
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    return (
        <Modal
            visible={true}
            onCancel={onClose}
            closable={false}
            footer={null}
            width={890}
            bodyStyle={{
                padding: 0
            }}
            style={{
                borderRadius: 10,
                overflow: 'hidden'
            }}
            className='intnnt-mdl bs-add-space-mdl'
            centered
            maskClosable={false}
        >
            <Spin spinning={isLoading} size={'large'}>
                <Row className='intnnt-rw'>
                    <Col className='intnnt-clmn intnnt-clmn-left'>
                        {(!isEditView || (isEditView && spaceDetails)) && <SpaceFormView form={form} space={spaceDetails} />}
                    </Col>
                    <Col className='intnnt-clmn intnnt-clmn-right intnnt-drk-10'>
                        {(!isEditView || (isEditView && spaceDetails)) &&
                            <ImageUploadView
                                thumbnailImg={thumbnailImg}
                                setThumbnailImg={setThumbnailImg}
                                imageList={imageList}
                                setImageList={setImageList}
                                onCreate={onCreateSpace}
                                onSave={onSaveSpace}
                                onCancel={onClose}
                                space={spaceDetails}
                            />
                        }
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
};
