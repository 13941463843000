import { Form, Row, Select, Modal, Col, Alert, Tooltip } from 'antd';
import { MODAL_TYPE } from '../../constants';
import { useEffect, useState } from 'react';
import { redLocationPinIcon, blueLocationPinIcon, bluePinIcon, redPinIcon } from '../../svgIcons';
import { isMobile } from "react-device-detect";
const ImageMapper = require('react-image-mapper');

const {Option} = Select; 

export const FloorSelect = ({mode, floorList = [], setSelectedFloor, selectedFloor, deskLocation, setDeskLocation, setInitDeskLocation, deskList, desk, cloneStatus}: any) => {
    const [isSetFloorDesk, setFloorDeskFlag] = useState<boolean>(false);
    const [floorDesks, setFloorDesks] = useState<Array<any>>([]);
    const [isImgLoad, setImgState] = useState<boolean>(false);
    const getFloorImage = (id: string) => {
        const [selectedFloor] = floorList.filter((floor: any) => floor.floorId === id);
        setSelectedFloor({ floorId: id, url: selectedFloor?.imageLink });
        setFloorDeskFlag(true);
    }

    useEffect(()=>{
        let floorDesks = [];
        if(selectedFloor?.floorId || desk) {
            floorDesks = desk && !cloneStatus ? deskList.filter((el: any)=> el.deskId !== desk.deskId && el.floorId === desk.floorId) 
                              : (desk && cloneStatus ? deskList.filter((el: any)=> el.floorId === desk.floorId) 
                              : deskList.filter((el: any)=> el.floorId === selectedFloor.floorId));
        }
        setFloorDesks(floorDesks);
    },[deskList, desk, selectedFloor?.floorId]);

    const onImageMap = (evt: any) => {
        const coords = ({x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY});
        setDeskLocation(coords);
    }

    const onCancelDeskLocation = () => {
        setInitDeskLocation();
        setFloorDeskFlag(false);
    }

    const deskImage = () => {
        return (
            <div className='db-thumb-map-resize'>
                <div onClick = {()=>setFloorDeskFlag(true)} className="db-box" style={{position:"relative"}}>
                        <img src={selectedFloor?.url} alt="" id="map-img"/>
                    {deskLocation.top &&
                        <div className='db-desk-marker-sm' style={{top:deskLocation.top, left:deskLocation.left }}>
                            {redLocationPinIcon}
                        </div>}
                    <div className="box-content">
                        <span className="post">Click to add desk location</span>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <>
            <Row style={{ marginTop: '30px'}}>
                <Col span={24}>
                    <Form.Item
                        className='db-floor-select'
                        name='floorId'
                        label='Floor name'
                        rules={[{required: true, message: 'Please select a floor!'}]}
                    >
                        {(selectedFloor && selectedFloor.floorId) && <Select
                            placeholder='Select a floor'
                            className='intnnt-npt'
                            onSelect={getFloorImage}
                            disabled={mode === MODAL_TYPE.EDIT}
                            defaultValue={selectedFloor?.floorId}>
                            {floorList.map((floor: any) => <Option key={floor.floorId} value={floor.floorId}>{floor.name}</Option>)}
                        </Select>}
                        {!(selectedFloor && selectedFloor.floorId) && <Select
                            placeholder='Select a floor'
                            className='intnnt-npt'
                            onSelect={getFloorImage}
                            disabled={mode === MODAL_TYPE.EDIT}
                            defaultValue={selectedFloor?.floorId}>
                            {floorList.map((floor: any) => <Option key={floor.floorId} value={floor.floorId}>{floor.name}</Option>)}
                        </Select>}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                {selectedFloor && selectedFloor.floorId && deskImage()}
                {isSetFloorDesk &&
                    <Modal
                        visible={true} 
                        width={700}
                        onCancel={()=>onCancelDeskLocation()} 
                        onOk={()=>setFloorDeskFlag(false)}
                        okText="Add Location"
                        closable={false}
                        style={{
                            borderRadius: 10,
                            overflow: 'hidden'
                        }}
                        centered
                    >
                    <div style={{overflow: isMobile ? 'scroll' : ''}}>
                        <Alert message="Select desk location" type="info" showIcon style={{marginBottom:'30px'}} />
                        <Row className='db-map-legend-wrapper'>
                            <Col className='db-map-icon' span={isMobile ? 2 : 1}>{redPinIcon}</Col>
                            <Col className='db-map-legend' span={22} offset={1}>New Desk</Col>
                        </Row>
                        <Row style={{ marginBottom: 20 }}>
                            <Col className='db-map-icon' span={isMobile ? 2 : 1}>{bluePinIcon}</Col>
                            <Col className='db-map-legend' span={22} offset={1}>Existing Desks</Col>
                        </Row>
                        <div className='db-map-resize'>
                            <div className='db-floor-img-wrapper' id = 'db-desk-img' style={{width: 650, cursor: 'crosshair'}}>
                                <ImageMapper onLoad={() => setImgState(true)} className='db-desk-img' src={selectedFloor?.url} onImageClick={onImageMap} width={650}/>
                                {deskLocation.top &&
                                <div className='db-desk-marker' style={{top:deskLocation.top, left:deskLocation.left}}>
                                    {redLocationPinIcon}
                                </div>}
                                {isImgLoad && floorDesks.map(desk=>(
                                    <div className='db-desk-marker' style={{top:desk.location.top, left:desk.location.left, cursor:'pointer'}}>
                                        <Tooltip title={desk.name}>{blueLocationPinIcon}</Tooltip>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    </Modal>
                }
            </Row>
        </>
    );

}
