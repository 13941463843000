import { useContext, useState } from 'react';
import { Col, Form, Input, Row, Select, Space, Checkbox, Radio } from 'antd';
import { sessionStore, SESSION_ATTRS } from '../../../store/session';
import { MODAL_TYPE, DESK_FACILITIES, DESK_STATUS } from '../../constants';

const { TextArea } = Input;
const { Option } = Select;

export const DeskFormView = ({ form, mode, desk, cloneStatus }: any) => {
    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    let isEditView = desk !== undefined;

    let { name, shortDescription, description, buildingId, hourlyPrice, floor, capacity, hourLimit, approvalRequired, advancedBookingRequired,
        advancedBookingPeriod, advancedBookingPeriodType, facilities, deskStatus, dimensions, assetId }: any = desk ? desk : {};

    const [isAdvancedBookingRequired, setAdvancedBookingRequired] = useState(advancedBookingRequired);

    const onFieldsChange = (changedFields: Array<any>) => {
        if (changedFields.length) {
            let item = changedFields.find(({ name }: any) => name[0] === 'advancedBookingRequired');
            if (item) setAdvancedBookingRequired(item.value);
        }
    }

    return (
        <Space direction='vertical' align='baseline' className='intnnt-spc intnnt-spc-wdth'>
            <Row className='title'>{cloneStatus  || !isEditView ? 'Create new' : 'Edit'} desk</Row>
            <Row>
                <p className='intnnt-sbttl'>Information</p>
            </Row>
            <Form
                name='induction-information-form'
                layout={'vertical'}
                // approvalRequired is showing as auto approval so we need to use !approvalRequired
                initialValues={isEditView && !cloneStatus ? {
                    name, shortDescription, description, buildingId, hourlyPrice, floor, capacity, hourLimit, dimensions, assetId, approvalRequired: !approvalRequired, advancedBookingRequired, advancedBookingPeriod, advancedBookingPeriodType, facilities, deskStatus
                } : 
                cloneStatus ? {shortDescription, description, buildingId: buildings.length === 1 ? buildings[0].buildingId : undefined, hourlyPrice, floor, capacity, hourLimit, dimensions, approvalRequired: !approvalRequired, advancedBookingRequired, advancedBookingPeriod, advancedBookingPeriodType, facilities, deskStatus} : 
                {buildingId: buildings.length === 1 ? buildings[0].buildingId : undefined, deskStatus:  DESK_STATUS.AVAILABLE.value, approvalRequired: true}}
                requiredMark={false}
                form={form}
                onFieldsChange={onFieldsChange}
            >
                <Form.Item
                    name='name'
                    label="Desk name"
                    rules={[{ required: true, message: 'Please input the desk name!' }]}
                >
                    <Input placeholder='Enter desk name' className='intnnt-npt' />
                </Form.Item>
                <Form.Item
                    name='shortDescription'
                    label="Short Description"
                    rules={[{ required: true, message: 'Please input the short description!' }]}
                >
                    <Input placeholder='Enter short description' className='intnnt-npt' />
                </Form.Item>
                <Form.Item
                    name='description'
                    label='Description'
                    rules={[{ required: true, message: 'Please input the description!' }]}
                >
                    <TextArea rows={4} placeholder='Tell more about this desk' className='intnnt-npt' />
                </Form.Item>
                <Form.Item name='deskStatus' rules={[{ required: true, message: 'Please select status!' }]}>
                    Status: <Radio.Group optionType="button" buttonStyle="outline" defaultValue={!isEditView ? DESK_STATUS.AVAILABLE.value : deskStatus} options={Object.values(DESK_STATUS)} onChange={e => form.setFieldsValue({ deskStatus: e.target.value })} />
                </Form.Item>
                <Form.Item
                    name='buildingId'
                    label='Building name'
                    rules={[{ required: true, message: 'Please select a building!' }]}
                >
                    <Select placeholder='Select a building' className='intnnt-npt' disabled={mode === MODAL_TYPE.EDIT} >
                        {buildings.map((building: any) => {
                            return (
                                <Option key={building.buildingId} value={building.buildingId}>
                                    <div>
                                        <p className='intnnt-bldng-nm'>{building.name}</p>
                                        <p className='intnnt-bldng-lctn'>{building.geolocation}</p>
                                    </div>
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name='hourlyPrice' label="Pricing per hour">
                            <Input type='number' min={0} className='intnnt-npt bs-number-input' prefix='$' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='dimensions' label="Dimensions">
                            <Input placeholder='Eg: 4×5' className='intnnt-npt' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name='assetId' label="Desk Id">
                            <Input className='intnnt-npt' />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name='approvalRequired' valuePropName="checked">
                    <Checkbox>Auto booking approval</Checkbox>
                </Form.Item>
                <Form.Item name='advancedBookingRequired' valuePropName="checked" className={isAdvancedBookingRequired ? 'input-chk-bx' : ''}>
                    <Checkbox>Requires booking in advance</Checkbox>
                </Form.Item>
                {isAdvancedBookingRequired &&
                    <Row>
                        <Col span={13}>
                            <Form.Item name='advancedBookingPeriod' rules={[{ required: true, message: 'Please input a time period!' }]}>
                                <Input type='number' min={1} placeholder="Eg: 2" className='intnnt-npt bs-input-grp-left bs-number-input' />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item name='advancedBookingPeriodType' rules={[{ required: true, message: 'Please select the period type!' }]}>
                                <Select className='bs-input-grp-right'>
                                    <Option value="hour">Hour</Option>
                                    <Option value="day">Day</Option>
                                    <Option value="week">Week</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                }
                <Form.Item name='facilities' label="Desk utilities" >
                    <Select mode="tags" style={{ width: '100%' }} placeholder="Please select utilities" className='facility-select'>
                        {DESK_FACILITIES.map((val: string) => <Option key={val} value={val}>{val}</Option>)}
                    </Select>
                </Form.Item>
            </Form>
        </Space>
    );
}
