import { Button, Col, Modal, Row, Spin, Tag } from 'antd';
import { useEffect, useState } from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import { getDesk } from '../actions';
import { getStatusTag } from './common/DeskUtil';
import { blueClockIcon, bluePriceTagIcon, calendarIcon, clockIcon, editIcon, bluePinIcon } from '../svgIcons';

export const DeskDetailsModal = ({ onClose, desk, onBookDesk, onEditDesk, onViewCalendar }: any) => {
    const [deskData, setDeskData] = useState<any>();
    useEffect(() => {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        getDesk(desk.buildingId, desk.deskId).then(fl => setDeskData(fl));
    }, [desk]);

    return (
        <Modal
            visible={true}
            onCancel={onClose}
            footer={null}
            width={530}
            bodyStyle={{
                padding: 0,
                height: '80vh',
                overflow: 'auto'
            }}
            style={{
                borderRadius: 10,
            }}
            className='bs-details-modal'
            centered
            maskClosable={false}
        >
            <Spin spinning={!deskData} size='large'>
                <Row className='title-row'>
                    <Col span={12} className='title'><span style={{ marginRight: '10px' }}>{deskData?.name}</span>{getStatusTag(deskData?.deskStatus)}</Col>
                    <Col span={12} className='book-space'>
                        <Button type='text' size='small' onClick={() => onViewCalendar(desk)} className='book-space'>Desk Calendar</Button>
                        <Button type='text' size='small' onClick={() => onBookDesk(desk)} className='book-space'>Book Desk</Button>
                    </Col>
                </Row>
                <Row>
                    {deskData &&
                        <SimpleImageSlider
                            width={530}
                            height={265}
                            images={deskData.images ? deskData.images.map((link: string) => { return { url: link }}) : [{url: "/images/no_image3.jpg"}]}
                            showNavs={true}
                            showBullets={true}
                        />
                    }
                </Row>
                <div className='details-div'>
                    <Button icon={editIcon} type='text' style={{ position: 'absolute', right: '10px' }} onClick={() => onEditDesk(desk)} />
                    {deskData?.approvalRequired && <Row className='detail-row'>{clockIcon}Requires booking approval</Row>}
                    <Row className='detail-row'><Col>{bluePinIcon} {desk?.buildingName}</Col>{deskData?.hourLimit && deskData?.hourLimit !== '0' && <Col offset={1}>{blueClockIcon}Can only book up to&nbsp;<span style={{ fontWeight: 600, color: '#2D3748' }}>{deskData?.hourLimit} hours</span></Col>}<Col offset={2}>{bluePriceTagIcon}<span style={{ fontWeight: 600, color: '#2D3748' }}>${deskData?.hourlyPrice}</span>/hour</Col></Row>
                    {deskData?.advancedBookingRequired && <Row className='detail-row'>{calendarIcon}Requires&nbsp;<span style={{ fontWeight: 600, color: '#2D3748' }}>{deskData?.advancedBookingPeriod} {deskData?.advancedBookingPeriodType}{deskData?.advancedBookingPeriod !== 1 ? '(s)' : ''}</span>&nbsp;booking in advance</Row>}
                    <Row className='detail-row' style={{ fontWeight: 600, marginTop: '32px' }}>{deskData?.shortDescription}</Row>
                    <Row className='description'>{deskData?.description}</Row>
                    <Row className='facilities-row'>
                        {deskData?.facilities?.map((val: string) => <Tag color='geekblue'>{val}</Tag>)}
                    </Row>
                </div>
            </Spin>
        </Modal >
    );
};