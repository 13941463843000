import { Layout, Row, Table, Col, Popover, Button } from 'antd';
import { useEffect, useState } from 'react';
import { BookingDeskModal } from './deskModal/BookingDeskModal';
import { DeskBookingModal } from './DeskBookingModal';
import { DeskCalendarModal } from './DeskCalendarModal';
import { stringSorter } from '../../utils/common-utils';
import moment from 'moment';
import { bookIcon, clockIcon, deleteIcon, editIcon, locationPinIcon, priceTagIcon, cloneIcon } from '../svgIcons';
import { MoreOutlined } from '@ant-design/icons';
import { DeskDetailsModal } from './DeskDetailsModal';
import { Link } from 'react-router-dom';
import { confirm, notifiableAPICall, notifyError, notifySuccess } from '../../utils/notification';
import { deleteDesk } from '../actions';
import { getStatusTag } from './common/DeskUtil';

const { Content } = Layout;
const ALL = "all";
let onBookDeskAction: Function;
let onViewDeskAction: Function;
let onEditDeskAction: Function;
let onCloneDeskAction: Function;
let onDeleteDeskAction: Function;
let buildingList: Array<any>;

export const BookingDesks = ({ newDeskModalVisible, floorList, setNewDeskModalVisible, selectedBuilding, searchPhrase, deskList, buildings, loadDesks, getFloorByFloorId }: any) => {
    buildingList = buildings;

    const [filteredList, setFilteredList] = useState<any[] | undefined>(undefined);
    const [deskBooking, setDeskBooking] = useState<Object | undefined>();
    const [viewingDesk, setViewingDesk] = useState<Object | undefined>();
    const [editingDesks, setEditingDesks] = useState<Object | undefined>();
    const [calendarDesk, setCalendarDesk] = useState<Object | undefined>();
    const [isCloningDesk, setCloningStatus] = useState<Boolean>(false);

    onBookDeskAction = (desk: Object) => setDeskBooking(desk);
    onViewDeskAction = (desk: any) => setViewingDesk({ ...desk, buildingName: buildings.find((b: any) => b.buildingId === desk.buildingId)?.name });
    onEditDeskAction = (desk: Object) => setEditingDesks(desk);
    onCloneDeskAction = (desk: Object) => {
        setEditingDesks(desk);
        setCloningStatus(true);
    }
    onDeleteDeskAction = (desk: any) => {
        confirm("Delete Desk", `Are you sure you want to delete this desk (${desk.name})?`,
            async () => {
                let { success, hasBookings } = await notifiableAPICall(async () => await deleteDesk(desk.buildingId, desk.deskId),
                    "delete-desk",
                    "Deleting Desk...",
                    undefined,
                    "Something went wrong in deleting desk. Please retry."
                );
                if (success) {
                    notifySuccess('Desk deleted successfully');
                    loadDesks();
                }
                else if (hasBookings) notifyError("Cannot delete a desk with existing bookings.");
            }, () => { });
    }

    const filterDesks = () => {
        let filtered;
        if (searchPhrase) {
            const searchingText = searchPhrase.toLowerCase();
            filtered = deskList.filter((i: any) => (
                i.name.toLowerCase().includes(searchingText) || getFloorByFloorId(i.floorId).toLowerCase().includes(searchingText)
            ));
        } else {
            filtered = deskList;
        }
        if (selectedBuilding === ALL) return filtered;
        return filtered.filter((i: any) => i.buildingId === selectedBuilding);
    };

    const handleSave = () => {
        loadDesks(); 
        setEditingDesks(undefined); 
        setNewDeskModalVisible(false);
        setCloningStatus(false);
    }

    useEffect(() => {
        if (deskList !== undefined) {
            setFilteredList(undefined);
            setFilteredList(filterDesks());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchPhrase, deskList, selectedBuilding]);

    const columns: Array<any> = [
        {
            title: 'Desks',
            dataIndex: 'name',
            sorter: (a: any, b: any) => stringSorter(a.name, b.name),
            render: (name: string, desk: any) => (
                <Link onClick={() => onViewDeskAction(desk)} to='#'>
                    <div className="bs-title">
                        <div className="bs-title-img-wrapper fill">
                            <img className="bs-title-img" src={desk.thumbnailLink ? desk.thumbnailLink  : "/images/no_image3.jpg"} alt={name} />
                        </div>
                        <div className="bs-title-label">
                            <Row className="bs-title-text" gutter={5} style={{flexFlow:'nowrap'}}>
                                <Col style={{maxWidth: 150, width:'max-content'}}>{desk.name}</Col>
                                <Col >{desk?.approvalRequired && <div style={{ marginLeft: 5 }}>{clockIcon}</div>}</Col>
                            </Row>
                            <Row gutter={5} style={{ fontSize:12 }}>
                                <Col>
                                    {locationPinIcon}
                                </Col>
                                <Col>
                                    <span>{getFloorByFloorId(desk.floorId)}</span>
                                </Col>
                            </Row>
                            <Row>{getStatusTag(desk.deskStatus)}</Row>
                        </div>
                    </div>
                </Link>
            )
        },
        {
            title: 'Today calendar (hour)',
            dataIndex: 'calendarSlots',
            render: (calendarSlots: Array<any>, row: any) => {
                let availabilityFrom = moment(row.availabilityFrom);
                let slotData = calendarSlots.map((item, index) => {
                    return getSlotImg(index === 0 ? availabilityFrom.hour() : index % 2 === 0 ? availabilityFrom.add(1, 'hour').hour() : undefined, item === 0, (index + 1) % 24 === 0 ? availabilityFrom.hour() + 1 : undefined)
                });
                return (
                    <div style={{minWidth: 375}}>
                        <Row>
                            {slotData.slice(0, 24)}
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            {slotData.slice(24, 48)}
                        </Row>
                    </div>
                );
            }
        },
        {
            title: 'Building',
            dataIndex: 'buildingId',
            render: (buildingId: any) => buildingList.find(b => b.buildingId === buildingId)?.name
        },
        {
            title: 'Pricing per hour',
            dataIndex: 'hourlyPrice',
            render: (hourlyPrice: string) => (
                <Row gutter={6} style={{flexFlow:'nowrap'}}>
                    <Col>
                        {priceTagIcon}
                    </Col>
                    <Col>
                        ${hourlyPrice}
                    </Col>
                </Row>
            )
        },
        {
            title: "",
            dataIndex: 'deskId',
            key: 'deskId',
            fixed: 'right',
            render: (deskId: string, desk: any) => (
                <Row style={{flexFlow:'nowrap'}}>
                    <Button icon={editIcon} type='text' onClick={() => onEditDeskAction(desk)} />
                    <Popover trigger='hover' placement='left' content={
                        <div className='intnnt-ppvr'>
                            <Button className='intnnt-ppvr-btn' type='text' icon={bookIcon} onClick={() => onBookDeskAction(desk)}>Book desk</Button>
                            <Button className='intnnt-ppvr-btn' type='text' danger icon={deleteIcon} onClick={() => onDeleteDeskAction(desk)}>Delete desk</Button>
                            <Button className='intnnt-ppvr-btn' type='text' icon={cloneIcon} onClick={() =>onCloneDeskAction(desk)}>Clone desk</Button>
                        </div>}>
                        <div className="ind-more"><MoreOutlined className="more-settings" /></div>
                    </Popover>
                </Row>
            )
        }
    ];

    return (
        <>
            {newDeskModalVisible && <BookingDeskModal deskList = {deskList} floorList={floorList} onClose={() => setNewDeskModalVisible(false)} onAdd={() => { loadDesks(); setNewDeskModalVisible(false); }} />}
            {deskBooking && <DeskBookingModal desk={deskBooking} onClose={() => setDeskBooking(undefined)} />}
            {viewingDesk && <DeskDetailsModal desk={viewingDesk} onClose={() => setViewingDesk(undefined)} onBookDesk={setDeskBooking} onEditDesk={(desk: any) => { setViewingDesk(undefined); setEditingDesks(desk); }} onViewCalendar={setCalendarDesk} />}
            {editingDesks && <BookingDeskModal deskList = {deskList} desk={editingDesks} cloneStatus = {isCloningDesk} floorList={floorList} onClose={() => {setEditingDesks(undefined); loadDesks(); setCloningStatus(false);}} onSave={() => handleSave()} />}
            {calendarDesk && <DeskCalendarModal desk={calendarDesk} onClose={() => setCalendarDesk(undefined)} />}
            <Content className="table-wrapper">
                <Table rowKey="deskId"
                    columns={columns}
                    dataSource={filteredList || []}
                    loading={filteredList === undefined}
                    pagination={{
                        total: filteredList?.length,
                        showSizeChanger: true,
                        showTotal: (total, range) => `showing ${range[0]}-${range[1]} out of ${total} desks`
                    }} />
            </Content>
        </>
    )
};

const getSlotImg = (startHour: number | undefined, isAvailable: boolean, endHour: number | undefined) => {
    return (
        <svg width="15" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.6" x="2" width="17" height="5" fill={!isAvailable ? "#E2E8F0" : "#1DC14B"} />
            {startHour !== undefined && <text x="-1" y="23" fill="#718096" style={{ fontSize: '10px' }}>{startHour}</text>}
            {startHour !== undefined && <line x1="2" y1="7" x2="2" y2="12" stroke="#CBD5E0" />}
            {endHour && <line x1="18" y1="7" x2="18" y2="12" stroke="#CBD5E0" />}
            {endHour && <text x="8" y="23" fill="#718096" style={{ fontSize: '10px' }}>{endHour}</text>}
        </svg>
    )
}
