import { Form, Input, Select } from "antd";
import { isValidPhoneNumber } from "../utils/common-utils";
import { getDialCode, getDialCodes } from "../utils/countryCodes";

type PropType = {
    label?: string,
    errorMessage?: string,
    placeHolder?: string
}

const ContactNumberFormItem = ({ label = "Contact Number",
    errorMessage = "Please enter a valid phone number",
    placeHolder = "Phone number" }: PropType) => {

    const contactNumberPrefix = (
        <Form.Item name="countryCode" style={{ margin: 0, height: 30 }}  >
            <Select className="contact-number-dial-code" optionLabelProp="title" placeholder="(+61)"
                dropdownMatchSelectWidth={false}
                showSearch
                showArrow={true}
                filterOption={(i, o: any) => o?.children.toLowerCase().includes(i.toLowerCase())}>
                {getDialCodes().map(c => <Select.Option
                    key={c.code}
                    title={c.dialCode}
                    value={c.code}>{c.name}
                </Select.Option>)}
            </Select>
        </Form.Item>
    );

    return (
        <Form.Item
            label={label}
            className="contact-number-field"
            name="contactNumber"
            dependencies={["countryCode"]}
            rules={[
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        const countryCode = getFieldValue('countryCode');
                        if (!countryCode && !value) return Promise.resolve();
                        const countryDailCode = getDialCode(countryCode);
                        if (countryCode && countryDailCode && value && isValidPhoneNumber(`${countryDailCode}${value}`)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error(errorMessage));
                    },
                })]}
        >
            <Input addonBefore={contactNumberPrefix} placeholder={placeHolder} />
        </Form.Item>
    )
}

export default ContactNumberFormItem;
