import { useState } from "react";
import { Row, Button, Card, Modal, Form, DatePicker } from 'antd';

export const DatePopup = ({ title, okButtonText, onCancel, onConfirm, defaultDate, label }: any) => {
    const [selectedDate, setSelectedDate] = useState<any>(defaultDate);
    return (
        <Modal
            visible={true}
            footer={null}
            onCancel={onCancel}
            closable={false}
            width={460}
            centered
            maskStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
            maskClosable={false}
        >
            <Card bordered={false} bodyStyle={{ paddingBottom: 0 }}>
                <Row className='bs-dcln-rw'>
                    <h2 className='bs-dcln-mdl-ttl'>{title}</h2>
                </Row>
                <Form.Item label={label} style={{ marginLeft: '85px' }}>
                    <DatePicker defaultValue={selectedDate} onChange={setSelectedDate} style={{ margin: 'auto' }} />
                </Form.Item>
                <Row className='bs-dcln-btn-rw'>
                    <div>
                        <Button className='btn btn--o-primary' onClick={onCancel}>Cancel</Button>
                        <Button className='btn btn--primary' onClick={async () => await onConfirm(selectedDate)}>{okButtonText}</Button>
                    </div>
                </Row>
            </Card>
        </Modal>
    )
}
