import { Modal, Row, Typography, Button, Col, Select, InputNumber } from 'antd';
import { addBookingRequest, getMaskedBookingRequests } from './../actions';
import { useContext, useEffect, useState } from 'react';
import { BOOKING_ERROR_MSGS, BOOKING_STATUS, CALENDAR_VIEWS } from './../constants';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { ltIcon, gtIcon, calendarIcon } from '../svgIcons';
import { sessionStore, SESSION_ATTRS } from '../../store/session';
import { notifiableAPICall, notifyError, notifySuccess, confirm } from '../../utils/notification';
import { DateRangePopup } from '../DateRangePopup';
import { RECURRENCES } from '../../bookingSpace/constants';

const localizer = momentLocalizer(moment);

const topBarHeight = '170px';
const bottomInputHeight = '130px';
const bottomSectionHeight = '100px';

export const DeskBookingModal = ({ onClose, desk }: any) => {
    const [calendarView, setCalenderView] = useState(CALENDAR_VIEWS.DAY);
    const [calendarDate, setCalenderDate] = useState<string>(moment().toISOString());
    const [selectedSlot, setSelectedSlot] = useState<any | undefined>();
    const [bookingRequests, setBookingRequests] = useState<Array<any> | undefined>();
    const [timeSelectionModalVisible, setTimeSelectionModalVisible] = useState<boolean>(false);
    const [recurrence, setRecurrence] = useState<string>(RECURRENCES.ONETIME);
    const [occurrences, setOccurrences] = useState<number>(1);

    const { state } = useContext(sessionStore);
    const user: any = state.get(SESSION_ATTRS.LOGGED_USER);

    useEffect(() => {
        getMaskedBookingRequests(desk.buildingId, desk.deskId).then(requests => setBookingRequests(requests));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (recurrence === RECURRENCES.ONETIME) setOccurrences(1);
    }, [recurrence])

    const addBooking = async () => {
        let req = {
            buildingId: user.buildingId,
            deskId: desk.deskId,
            startTime: selectedSlot.startTime,
            endTime: selectedSlot.endTime,
            floorId: desk.floorId,
            recurrence,
            occurrences
        };
        await notifiableAPICall(async () => {
            let { errorCode, reqStatus, unavailableSlots } = await addBookingRequest(req);
            if (unavailableSlots?.length) {
                confirm('Following slots are not available', <>
                    {unavailableSlots.sort((a: any, b: any) => moment(a.startTime).diff(moment(b.startTime))).map(({ startTime }: any) => <Row justify='center'>{moment(startTime).format('dddd, D MMMM hh:mm A')}</Row>)}
                    <br />
                    <Row justify='center'>Do you want to book available slots?</Row>
                </>,
                    async () => {
                        let { errorCode, reqStatus } = await addBookingRequest({ ...req, skipUnavailableSlots: true });
                        if (errorCode) {
                            notifyError(`Error in request: ${BOOKING_ERROR_MSGS[errorCode]}`)
                        } else {
                            notifySuccess("Booking added successfully");
                            setSelectedSlot({ ...selectedSlot, reqStatus });
                        }
                    }
                )
            }
            if (errorCode) {
                notifyError(`Error in request: ${BOOKING_ERROR_MSGS[errorCode]}`)
            } else {
                notifySuccess("Booking added successfully");
                setSelectedSlot({ ...selectedSlot, reqStatus });
            }
        },
            "add-booking",
            "Please wait...",
            undefined,
            "Something went wrong in booking the slot. Please retry."
        );
    }

    const roundUpEndTime = (end: any) => {
        const endTime = moment(end).format("hh:mm A");
        if (endTime === '11:59 PM') {
            return moment(end).add(1, 'milliseconds').toISOString();
        }
        return moment(end).toISOString();
    }

    return (
        <Modal
            visible={true}
            onCancel={onClose}
            footer={null}
            width={530}
            bodyStyle={{
                padding: 0,
                height: '90vh',
                overflow: 'auto'
            }}
            style={{
                borderRadius: 10,
            }}
            className='bs-booking-modal'
            centered
            maskClosable={false}
        >
            {timeSelectionModalVisible &&
                <DateRangePopup
                    title="Event Time"
                    okButtonText="OK"
                    onCancel={() => setTimeSelectionModalVisible(false)}
                    from={moment(selectedSlot.startTime)}
                    to={moment(selectedSlot.endTime)}
                    onConfirm={(from: any, to: any) => { setSelectedSlot({ ...selectedSlot, startTime: from.toISOString(), endTime: to.toISOString() }); setTimeSelectionModalVisible(false) }}
                    label="Up To" />}
            <div style={{ height: topBarHeight }}>
                <Row className='title-row'>
                    <Typography className='space-name'>{desk.name}</Typography>{gtIcon}
                    <Typography className='book-space'>Book desk</Typography>
                </Row>
                <Row className='bs-calendar-view-selector-row'>
                    <Button type='text' onClick={() => setCalenderView(CALENDAR_VIEWS.DAY)} className={`bs-calendar-view-btn ${calendarView === CALENDAR_VIEWS.DAY ? 'bs-calendar-view-btn-selected' : ''}`} style={{ marginLeft: '15px' }}>Day</Button>
                    <Button type='text' onClick={() => setCalenderView(CALENDAR_VIEWS.WEEK)} className={`bs-calendar-view-btn ${calendarView === CALENDAR_VIEWS.WEEK ? 'bs-calendar-view-btn-selected' : ''}`}>Week</Button>
                </Row>
                <Row className='bs-calendar-date-row'>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Button type="text" onClick={() => setCalenderDate(moment(calendarDate).subtract(1, calendarView === CALENDAR_VIEWS.WEEK ? 'week' : 'day').toISOString())} disabled={moment(calendarDate).isSameOrBefore(moment().subtract(1, 'week'), 'day')} icon={ltIcon} />
                        {calendarView === CALENDAR_VIEWS.WEEK ? `${moment(calendarDate).startOf('week').format('Do MMMM')} - ${moment(calendarDate).endOf('week').format('Do MMMM')}` : moment(calendarDate).format('dddd, Do MMMM')}
                        <Button type="text" onClick={() => setCalenderDate(moment(calendarDate).add(1, calendarView === CALENDAR_VIEWS.WEEK ? 'week' : 'day').toISOString())} icon={gtIcon} />
                    </Col>
                </Row>
            </div>
            <div style={{ height: `calc(100% - ${topBarHeight} ${selectedSlot && selectedSlot.reqStatus === undefined ? ' - ' + bottomInputHeight : ''} ${selectedSlot && selectedSlot.reqStatus === undefined ? ' - ' + bottomSectionHeight : ''})` }}>
                <Calendar
                    className='bs-calendar'
                    date={moment(calendarDate).toDate()}
                    getNow={() => moment().toDate()}
                    view={calendarView === CALENDAR_VIEWS.DAY ? 'day' : 'week'}
                    events={[...(bookingRequests ? bookingRequests : []), ...(selectedSlot ? [selectedSlot] : [])]}
                    startAccessor={({ startTime }) => moment(startTime).toDate()}
                    endAccessor={({ endTime }) => moment(endTime).toDate()}
                    titleAccessor={({ currentEvent, reqStatus }) => currentEvent ? (reqStatus ? `Desk booking (${reqStatus === BOOKING_STATUS.APPROVED ? 'Approved' : 'Pending Approval'})` : '') : calendarView === CALENDAR_VIEWS.DAY ? 'Currently unavailable' : 'N/A'}
                    step={30}
                    showMultiDayTimes={true}
                    onSelecting={({ start, end }: any) => moment(start).isAfter(moment())}
                    views={['day', 'week']}
                    localizer={localizer}
                    selectable={selectedSlot && selectedSlot.reqStatus !== undefined ? false : 'ignoreEvents'}
                    onSelectSlot={({ start, end }: any) => moment(start).isAfter(moment()) ? setSelectedSlot({ startTime: moment(start).toISOString(), endTime: roundUpEndTime(end), currentEvent: true }) : undefined}
                    toolbar={false}
                    eventPropGetter={({ currentEvent, reqStatus, endTime, startTime }) => currentEvent ? { className: reqStatus ? `${reqStatus === BOOKING_STATUS.APPROVED ? 'approved-event-selected' : 'pending-event-selected'} ${moment(endTime).diff(moment(startTime), 'minutes') === 30 ? 'single-line' : ''}` : `new-event ${moment(endTime).diff(moment(startTime), 'minutes') === 30 ? 'single-line' : ''}` } : { className: 'unavailable-event' }}
                    dayPropGetter={(date) => { return { className: moment(date).isSame(moment(calendarDate), 'day') && calendarView === CALENDAR_VIEWS.DAY ? 'day-col' : '' } }}
                    onSelectEvent={({ currentEvent, reqStatus }) => currentEvent && !reqStatus ? setTimeSelectionModalVisible(true) : undefined}
                />
            </div>
            {selectedSlot && selectedSlot.reqStatus === undefined &&
                <>
                    <div style={{ height: bottomInputHeight }}>
                        {moment(selectedSlot.startTime).isSame(moment(selectedSlot.endTime), 'day') &&
                            <Row className='date-time-row' onClick={() => setTimeSelectionModalVisible(true)}>
                                {calendarIcon}
                                <Col className='date-text' span={11}>{moment(selectedSlot.startTime).format('dddd, D MMMM')}</Col>
                                <Col className='time-text' span={11}>{moment(selectedSlot.startTime).format('hh:mm A')} - {moment(selectedSlot.endTime).format('hh:mm A')}</Col>
                            </Row>}
                        {!moment(selectedSlot.startTime).isSame(moment(selectedSlot.endTime), 'day') &&
                            <Row className='date-time-row' onClick={() => setTimeSelectionModalVisible(true)}>
                                {calendarIcon}
                                <Col>{moment(selectedSlot.startTime).format('dddd, D MMMM')} {moment(selectedSlot.startTime).format('hh:mm A')}</Col>
                                <Col span={1} style={{ textAlign: 'center' }}>-</Col>
                                <Col>{moment(selectedSlot.endTime).format('dddd, D MMMM')} {moment(selectedSlot.endTime).format('hh:mm A')}</Col>
                            </Row>
                        }
                        <Row className='date-time-row' justify="space-between" align="middle">
                            <Col style={{ textAlign: 'center' }}>
                                Recurrence
                            </Col>
                            <Col>
                                <Select value={recurrence} onChange={setRecurrence}>
                                    <Select.Option value={RECURRENCES.ONETIME}>Does not Repeat</Select.Option>
                                    <Select.Option value={RECURRENCES.DAILY}>Daily</Select.Option>
                                    <Select.Option value={RECURRENCES.WEEKLY}>Weekly</Select.Option>
                                    <Select.Option value={RECURRENCES.MONTHLY}>Monthly</Select.Option>
                                </Select>
                            </Col>
                            {recurrence !== RECURRENCES.ONETIME && <>
                                <Col style={{ textAlign: 'center' }}>
                                    Occurrences
                                </Col>
                                <Col>
                                    <InputNumber max={30} min={1} value={occurrences} onChange={setOccurrences} />
                                </Col>
                            </>}
                        </Row>
                    </div>
                    <div style={{ height: bottomSectionHeight }}>
                        <Button onClick={addBooking} className='confirm-button'>Confirm Booking</Button>
                    </div>
                </>
            }
        </Modal >
    );
};
