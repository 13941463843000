import { thumbUploadIcon, uploadIcon } from "../../svgIcons"

export const ThumbUploadBox = () => {
    return (
        <div>
            {thumbUploadIcon}
            <p className="ant-upload-text">Upload thumbnail</p>
        </div>
    )
}

export const DragUploadBox = () => {
    return (
        <div className='upload-dragger'>
            {uploadIcon}
            <p className="ant-upload-text">Drag & Drop to upload or</p>
            <p className="new-user-upload-hint margin-bottom-10">browse from your device</p>
            <p className="ant-upload-text">Only .JPG and .PNG formats with max</p>
            <p className="ant-upload-text">size of 10MB</p>
        </div>
    )
}

export const ImageUploadBox = () => {
    return (
        <div>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="15" fill="#E2E8F0" />
                <path d="M15 10.5V19.5" stroke="#718096" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.5 15H10.5" stroke="#718096" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p className="ant-upload-text" style={{fontSize: '9px'}}>or drop here</p>
            <p className="ant-upload-text" style={{fontSize: '9px'}}>to upload</p>
        </div>
    )
}