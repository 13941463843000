import Amplify from 'aws-amplify';
import { CONFIG } from '../config/app-config';
import { APIS } from './api-utils';

const { baseApiUrl, stage } = CONFIG;

export function awsAmplifyApiInit() {
    Amplify.configure({
        API: {
            endpoints: [
                {
                    name: APIS.BUILDING_API,
                    endpoint: `${baseApiUrl}/${stage}-building-api`
                },
                {
                    name: APIS.INDUCTION_API,
                    endpoint: `${baseApiUrl}/${stage}-induction-api`
                },
                {
                    name: APIS.NEWS_API,
                    endpoint: `${baseApiUrl}/${stage}-news-api`
                },
                {
                    name: APIS.TENANT_API,
                    endpoint: `${baseApiUrl}/${stage}-tenant-api`
                },
                {
                    name: APIS.USER_API,
                    endpoint: `${baseApiUrl}/${stage}-user-api`
                },
                {
                    name: APIS.BUILDING_CONTACTS_API,
                    endpoint: `${baseApiUrl}/${stage}-building-contact-api`
                },
                {
                    name: APIS.BUILDING_SPACES_API,
                    endpoint: `${baseApiUrl}/${stage}-space-api`
                },
                {
                    name: APIS.ISSUES_API,
                    endpoint: `${baseApiUrl}/${stage}-issue-api`
                },{
                    name: APIS.DESK_BOOKING_API,
                    endpoint: `${baseApiUrl}/${stage}-desk-api`
                }
            ]
        }
    });
}
