import { Button, Col, Modal, Row, Spin, Tag } from 'antd';
import { useEffect, useState } from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import { getSpace } from '../actions';
import { SPACE_STATUS } from '../constants';
import { blueClockIcon, bluePeopleIcon, bluePriceTagIcon, calendarIcon, clockIcon, editIcon, blueLocationPinIcon } from '../svgIcons';

export const SpaceDetailsModal = ({ onClose, space, onBookSpace, onEditSpace, onViewCalendar }: any) => {
    const [spaceData, setSpaceData] = useState<any>();
    useEffect(() => {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        getSpace(space.buildingId, space.spaceId).then(sp => setSpaceData(sp));
    }, [space]);

    return (
        <Modal
            visible={true}
            onCancel={onClose}
            footer={null}
            width={530}
            bodyStyle={{
                padding: 0,
                height: '80vh',
                overflow: 'auto'
            }}
            style={{
                borderRadius: 10,
            }}
            className='bs-details-modal'
            centered
            maskClosable={false}
        >
            <Spin spinning={!spaceData} size='large'>
                <Row className='title-row'>
                    <Col span={12} className='title'><span style={{ marginRight: '10px' }}>{spaceData?.name}</span>{getStatusTag(spaceData?.spaceStatus)}</Col>
                    <Col span={12} className='book-space'>
                        <Button type='text' size='small' onClick={() => onViewCalendar(space)} className='book-space' >Space Calendar</Button>
                        <Button type='text' size='small' onClick={() => onBookSpace(space)} className='book-space'>Book space</Button>
                    </Col>
                </Row>
                <Row>
                    {spaceData && spaceData.images &&
                        <SimpleImageSlider
                            width={530}
                            height={265}
                            images={spaceData.images.map((link: string) => { return { url: link }; })}
                            showNavs={true}
                            showBullets={true}
                        />
                    }
                    {!spaceData?.images && <div style={{ height: '265px' }}></div>}
                </Row>
                <div className='details-div'>
                    <Button icon={editIcon} type='text' style={{ position: 'absolute', right: '10px' }} onClick={() => onEditSpace(space)} />
                    {spaceData?.approvalRequired && <Row className='detail-row'>{clockIcon}Requires booking approval</Row>}
                    <Row className='detail-row'><Col>{bluePeopleIcon}Capacity&nbsp;<span style={{ fontWeight: 600, color: '#2D3748' }}>{spaceData?.capacity} people</span>&nbsp;</Col><Col offset={2}>{bluePriceTagIcon}<span style={{ fontWeight: 600, color: '#2D3748' }}>${spaceData?.hourlyPrice}</span>/hour</Col></Row>
                    {spaceData?.advancedBookingRequired && <Row className='detail-row'>{calendarIcon}Requires&nbsp;<span style={{ fontWeight: 600, color: '#2D3748' }}>{spaceData?.advancedBookingPeriod} {spaceData?.advancedBookingPeriodType}{spaceData?.advancedBookingPeriod !== 1 ? 's' : ''}</span>&nbsp;booking in advance</Row>}
                    <Row className='detail-row'><Col>{blueLocationPinIcon}{space?.buildingName}</Col>{spaceData?.hourLimit && spaceData?.hourLimit !== '0' && <Col offset={1}>{blueClockIcon}Can only book up to&nbsp;<span style={{ fontWeight: 600, color: '#2D3748' }}>{spaceData?.hourLimit} hours</span></Col>}</Row>
                    <Row className='detail-row' style={{ fontWeight: 600, marginTop: '32px' }}>{spaceData?.shortDescription}</Row>
                    <Row className='description'>{spaceData?.description}</Row>
                    <Row className='facilities-row'>
                        {spaceData?.facilities?.map((val: string) => <Tag color='geekblue'>{val}</Tag>)}
                    </Row>
                </div>
            </Spin>
        </Modal >
    );
};

const getStatusTag = (status: number) => {
    switch (status) {
        case SPACE_STATUS.NOT_AVAILABLE.value:
            return <Tag color='processing'>{SPACE_STATUS.NOT_AVAILABLE.label}</Tag>;
        case SPACE_STATUS.HIDDEN.value:
            return <Tag color='warning'>{SPACE_STATUS.HIDDEN.label}</Tag>;
        default:
            return <Tag color='success'>{SPACE_STATUS.AVAILABLE.label}</Tag>;
    }
}