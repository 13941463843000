import { Modal, Row, Typography, Button, Col } from 'antd';
import { approveRequest, getSpaceBookingRequests } from './../actions';
import { useEffect, useState } from 'react';
import { BOOKING_STATUS, CALENDAR_VIEWS, RECURRENCES } from './../constants';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { ltIcon, gtIcon, calendarIcon } from '../svgIcons';
import { confirm, notifiableAPICall } from '../../utils/notification';
import { DeclinePopup } from '../DeclinePopup';
import { RecurringApprovalModal } from '../RecurringApprovalModal';

const localizer = momentLocalizer(moment);

const topBarHeight = '170px';
const bottomSectionHeight = '250px';

export const SpaceCalendarModal = ({ onClose, space }: any) => {
    const [calendarView, setCalenderView] = useState(CALENDAR_VIEWS.DAY);
    const [calendarDate, setCalenderDate] = useState<string>(moment().toISOString());
    const [selectedReq, setSelectedReq] = useState<any | undefined>();
    const [bookingRequests, setBookingRequests] = useState<Array<any> | undefined>();
    const [declineModalVisible, setDeclineModalVisible] = useState<boolean>(false);
    const [approveModalVisible, setApproveModalVisible] = useState<boolean>(false);

    useEffect(() => {
        getSpaceBookingRequests(space.buildingId, space.spaceId, true).then(requests => setBookingRequests(requests));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedReq(undefined);
    }, [calendarDate])

    const onApprove = async () => {

        const isOneTime = !selectedReq.recurrence || selectedReq.recurrence === RECURRENCES.ONETIME;
        if (!isOneTime) {
            setApproveModalVisible(true);
            return;
        }
        confirm("Approve Booking", `Are you sure you want to approve the booking?`,
            async () => {
                await notifiableAPICall(() => approveRequest(selectedReq.buildingId, selectedReq.requestId),
                    'approve_req',
                    "Please wait...",
                    "Space Booking Request Approved.",
                    "Something went wrong.",
                    true);
                setSelectedReq(undefined);
                getSpaceBookingRequests(space.buildingId, space.spaceId).then(requests => setBookingRequests(requests));
            }, () => { });
    }

    return (
        <Modal
            visible={true}
            onCancel={onClose}
            footer={null}
            width={530}
            bodyStyle={{
                padding: 0,
                height: '90vh',
                overflow: 'auto'
            }}
            style={{
                borderRadius: 10,
            }}
            className='bs-booking-modal'
            centered
            maskClosable={false}
        >
            {declineModalVisible && <DeclinePopup isVisible={declineModalVisible} onCancel={() => setDeclineModalVisible(false)} request={selectedReq} onOk={() => {
                setDeclineModalVisible(false);
                setSelectedReq(undefined);
                getSpaceBookingRequests(space.buildingId, space.spaceId).then(requests => setBookingRequests(requests));
            }} />}
            {approveModalVisible && <RecurringApprovalModal onClose={() => setApproveModalVisible(false)} request={selectedReq} onOk={() => {
                setApproveModalVisible(false);
                setSelectedReq(undefined);
                getSpaceBookingRequests(space.buildingId, space.spaceId).then(requests => setBookingRequests(requests));
            }} />}
            <div style={{ height: topBarHeight }}>
                <Row className='title-row'>
                    <Typography className='space-name'>{space.name}</Typography>{gtIcon}
                    <Typography className='book-space'>Calendar</Typography>
                </Row>
                <Row className='bs-calendar-view-selector-row'>
                    <Col span={12}>
                        <Button type='text' onClick={() => setCalenderView(CALENDAR_VIEWS.DAY)} className={`bs-calendar-view-btn ${calendarView === CALENDAR_VIEWS.DAY ? 'bs-calendar-view-btn-selected' : ''}`} style={{ marginLeft: '15px' }}>Day</Button>
                        <Button type='text' onClick={() => setCalenderView(CALENDAR_VIEWS.WEEK)} className={`bs-calendar-view-btn ${calendarView === CALENDAR_VIEWS.WEEK ? 'bs-calendar-view-btn-selected' : ''}`}>Week</Button>
                    </Col>
                    <Col span={12} style={{ alignSelf: 'center' }}>
                        <Row align='middle' justify='end' style={{ marginRight: '20px' }}>
                            <Col>
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className='approved-event' style={{ position: 'absolute', top: '3px' }} />
                                <span style={{ marginLeft: '18px' }}>Approved</span>
                            </Col>
                            <Col offset={1}>
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className='pending-event' style={{ position: 'absolute', top: '3px' }} />
                                <span style={{ marginLeft: '18px' }}>Pending</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='bs-calendar-date-row'>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Button type="text" onClick={() => setCalenderDate(moment(calendarDate).subtract(1, calendarView === CALENDAR_VIEWS.WEEK ? 'week' : 'day').toISOString())} disabled={moment(calendarDate).isSameOrBefore(moment().subtract(1, 'week'), 'day')} icon={ltIcon} />
                        {calendarView === CALENDAR_VIEWS.WEEK ? `${moment(calendarDate).startOf('week').format('Do MMMM')} - ${moment(calendarDate).endOf('week').format('Do MMMM')}` : moment(calendarDate).format('dddd, Do MMMM')}
                        <Button type="text" onClick={() => setCalenderDate(moment(calendarDate).add(1, calendarView === CALENDAR_VIEWS.WEEK ? 'week' : 'day').toISOString())} icon={gtIcon} />
                    </Col>
                </Row>
            </div>
            <div style={{ height: `calc(100% - ${topBarHeight} ${selectedReq ? ' - ' + bottomSectionHeight : ''} ` }}>
                <Calendar
                    className='bs-calendar'
                    date={moment(calendarDate).toDate()}
                    getNow={() => moment().toDate()}
                    view={calendarView === CALENDAR_VIEWS.DAY ? 'day' : 'week'}
                    events={[...(bookingRequests ? bookingRequests : [])]}
                    startAccessor={({ startTime }) => moment(startTime).toDate()}
                    endAccessor={({ endTime }) => moment(endTime).toDate()}
                    titleAccessor={({ bookingReason, reqStatus }) => calendarView === CALENDAR_VIEWS.WEEK ? '' : `${bookingReason} (${reqStatus === BOOKING_STATUS.APPROVED ? 'Approved' : 'Pending Approval'})`}
                    step={30}
                    showMultiDayTimes={true}
                    views={['day', 'week']}
                    localizer={localizer}
                    selectable={false}
                    toolbar={false}
                    eventPropGetter={({ reqStatus, requestId, startTime, endTime }) => reqStatus === BOOKING_STATUS.APPROVED ? { className: `approved-event${requestId === selectedReq?.requestId ? '-selected' : ''} ${moment(endTime).diff(moment(startTime), 'minutes') === 30 ? 'single-line' : ''}` } : { className: `pending-event${requestId === selectedReq?.requestId ? '-selected' : ''} ${moment(endTime).diff(moment(startTime), 'minutes') === 30 ? 'single-line' : ''}` }}
                    dayPropGetter={(date) => { return { className: moment(date).isSame(moment(calendarDate), 'day') && calendarView === CALENDAR_VIEWS.DAY ? 'day-col' : '' } }}
                    onSelectEvent={setSelectedReq}
                />
            </div>
            {selectedReq &&
                <>
                    <div style={{ height: bottomSectionHeight }}>
                        {moment(selectedReq.startTime).isSame(moment(selectedReq.endTime), 'day') &&
                            <Row className='date-time-row'>
                                {calendarIcon}
                                <Col className='date-text' span={11}>{moment(selectedReq.startTime).format('dddd, D MMMM')}</Col>
                                <Col className='time-text' span={11}>{moment(selectedReq.startTime).format('hh:mm A')} - {moment(selectedReq.endTime).format('hh:mm A')}</Col>
                            </Row>}
                        {!moment(selectedReq.startTime).isSame(moment(selectedReq.endTime), 'day') &&
                            <Row className='date-time-row'>
                                {calendarIcon}
                                <Col>{moment(selectedReq.startTime).format('dddd, D MMMM')} {moment(selectedReq.startTime).format('hh:mm A')}</Col>
                                <Col span={1} style={{ textAlign: 'center' }}>-</Col>
                                <Col>{moment(selectedReq.endTime).format('dddd, D MMMM')} {moment(selectedReq.endTime).format('hh:mm A')}</Col>
                            </Row>
                        }
                        <Row className='row-details'><Col span={12}>Reason for booking:</Col> <Col span={12} className='reason-row-details'>{selectedReq.bookingReason}</Col></Row>
                        <Row className='row-details'><Col span={12}>Booked by:</Col> <Col span={12} className='reason-row-details'>{selectedReq.createdBy.name}</Col></Row>
                        <Row style={{ margin: '30px', float: 'right' }}>
                            {moment(selectedReq.endTime).isAfter(moment()) && moment(selectedReq.startTime).isAfter(moment()) && selectedReq.reqStatus !== BOOKING_STATUS.CANCELLED && <Button
                                className='btn btn--danger'
                                onClick={() => setDeclineModalVisible(true)}
                            >
                                Decline
                            </Button>
                            }
                            {moment(selectedReq.endTime).isAfter(moment()) && selectedReq.reqStatus === BOOKING_STATUS.PENDING && <Button
                                className='btn btn--primary'
                                onClick={onApprove}
                            >
                                Approve
                            </Button>
                            }
                        </Row>
                    </div>
                </>
            }
        </Modal >
    );
};