import { Layout, Row, Select, Space, Input, Button, Table, Typography, Popover } from 'antd';
import { PlusOutlined, UndoOutlined, MoreOutlined, DownloadOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { changeState, downloadVideo, getInductions } from './actions';
import InductionModal from './InductionModal';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { INDUCTION_MODAL_TYPE, INDUCTION_STATUS_TYPE, INDUCTION_STATUS_ARRAY } from './constants';
import { confirm, notifiableAPICall, notifyConfirmation, notifyWarning } from '../utils/notification';
import InductionTitle from './InductionTitle';
import VideoPlayerModal from './VideoPlayerModal';
import { toDateString } from '../utils/date-time';
import PopOverBody from './PopOverBody';
import { getUserObj } from '../utils/common-utils';

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;
const { Title } = Typography;

const ALL = "all";

export const InductionsView = () => {
    const [inductionList, setInductionList] = useState<Array<any>>([]);
    const [editRecordData, setEditRecordData] = useState<any>({ inductionId: "", title: "", description: "", videoKey: "", thumbKey: "", buildingId: "", tenants: [], createdBy: '' });
    const [selectedInductions, setSelectedInductions] = useState<Array<any>>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [filteredList, setFilteredList] = useState<any[]>([]);
    const [selectedBuilding, setSelectedBuilding] = useState<string>(ALL);
    const [selectedState, setSelectedState] = useState<number>(INDUCTION_STATUS_TYPE.PUBLISH);
    const [mode, setMode] = useState<string>(INDUCTION_MODAL_TYPE.CREATE);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [playingVideo, setPlayingVideo] = useState<any>(null);
    const [searchPhrase, setSearchPhrase] = useState<string | undefined>();

    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const buildingIdNameMapping: any = state.get(SESSION_ATTRS.BUILDING_ID_NAMES);
    const tenantIdNameMapping: any = state.get(SESSION_ATTRS.TENANT_ID_NAMES);
    const user: any = state.get(SESSION_ATTRS.LOGGED_USER);

    const prevStateRef = useRef<number | undefined>();

    const onSearch = (phrase: string) => {
        setSearchPhrase(phrase);
        setSelectedInductions([]);
    }

    const filterInductions = useCallback((inductions: Array<any>, searching: (string | undefined), buildingId: string) => {
        let filtered;
        if (searching) {
            const searchingText = searching.toLowerCase();
            filtered = inductions.filter(i => i.title.toLowerCase().includes(searchingText));
        } else {
            filtered = inductions;
        }
        if (buildingId === ALL) return filtered;
        return filtered.filter(i => i.buildingId === buildingId);
    }, []);

    useEffect(() => {
        setLoading(true);
        setFilteredList(filterInductions(inductionList, searchPhrase, selectedBuilding));
        setLoading(false);
    }, [searchPhrase, filterInductions, inductionList, selectedBuilding]);

    const onBuildingSelect = (value: any) => {
        setSelectedBuilding(value);
        if (value !== ALL) setSelectedInductions([]);
    };

    const onStateChange = (value: any) => {
        setSelectedState(value);
        setSelectedInductions([]);
    }

    const loadInductions = useCallback(async (state: number) => {
        try {
            setLoading(true);
            let inductions = await getInductions(state, user.buildingId);
            inductions.sort((i1: any, i2: any) => (new Date(i2.createdAt)).getTime() - (new Date(i1.createdAt)).getTime());
            setInductionList(inductions);
            const list = filterInductions(inductions, searchPhrase, selectedBuilding);
            setFilteredList(list);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setInductionList([]);
            setFilteredList([]);
        }
    }, [selectedBuilding, filterInductions, searchPhrase, user.buildingId]);

    useEffect(() => {
        if (prevStateRef.current !== selectedState) {
            prevStateRef.current = selectedState;
            loadInductions(selectedState);
        }
    }, [selectedState, loadInductions]);

    const newInductionModalVisible = () => {
        setMode(INDUCTION_MODAL_TYPE.CREATE);
        setEditRecordData({ inductionId: "", title: "", description: "", videoKey: "", thumbKey: "", buildingId: "", tenants: [], createdBy: '' });
        setModalVisible(true);
    }

    const refresh = () => {
        loadInductions(selectedState);
    }

    const tenantsArrayFilter = (selectTenants: Array<string> = []) => {
        return selectTenants.map((t: string) => tenantIdNameMapping[t]).filter(t => !!t).join(', ');
    }

    const findBuildingName = (buildingId: string) => {
        return buildingIdNameMapping[buildingId];
    }

    const inductionModal = modalVisible ?
        <InductionModal
            mode={mode}
            visible={modalVisible}
            setVisible={setModalVisible}
            editRecordData={editRecordData}
            refresh={refresh}
        />
        :
        null;

    const onConvertToDraft = async (inductionId: string, buildingId: string, currState: number) => {
        await notifiableAPICall(async () => {
            await changeState(inductionId, buildingId, INDUCTION_STATUS_TYPE.DRAFT, currState, getUserObj(user));
            await loadInductions(selectedState);
        },
            "induction-state-change-draft",
            "Please wait...",
            "Induction was converted to draft successfully.",
            "Something went wrong in converting the induction to draft. Please retry."
        );
    }

    const onMoveToTrash = async (inductionId: string, buildingId: string, currState: number) => {
        confirm("Move induction to Trash",
            "Are you sure you want to move this induction to trash?",
            async () => {
                await notifiableAPICall(async () => {
                    await changeState(inductionId, buildingId, INDUCTION_STATUS_TYPE.TRASH, currState, getUserObj(user));
                    await loadInductions(selectedState);
                },
                    "induction-state-change-trash",
                    "Please wait...",
                    "Induction was moved to trash successfully.",
                    "Something went wrong in moving the induction to trash. Please retry."
                );
            }, () => { });
    }

    const onDownload = async (key: string, name: string) => {
        await notifiableAPICall(async () => await downloadVideo(key, name),
            "induction-download",
            "Downloading...",
            "Induction was downloaded.",
            "Something went wrong in downloading the induction. Please retry."
        );
    }

    const onConvertToPublish = async (inductionId: string, buildingId: string, currState: number) => {
        await notifiableAPICall(async () => {
            await changeState(inductionId, buildingId, INDUCTION_STATUS_TYPE.PUBLISH, currState, getUserObj(user));
            await loadInductions(selectedState);
        },
            "induction-state-change-publish",
            "Please wait...",
            "Induction was converted to publish successfully.",
            "Something went wrong in converting the induction to publish. Please retry."
        );
    }

    const onRestore = async (inductionId: string, buildingId: string, prevState: number, state: number) => {
        await notifiableAPICall(async () => {
            await changeState(inductionId, buildingId, prevState, state, getUserObj(user));
            await loadInductions(selectedState);
        },
            "induction-state-change-restore",
            "Please wait...",
            "Induction was restored successfully.",
            "Something went wrong in restoring the induction. Please retry."
        );
    }

    const onBulkConvertToDraft = async () => {
        await notifiableAPICall(async () => {
            const proms = selectedInductions.map(async ({ inductionId, buildingId, state }) =>
                await changeState(inductionId, buildingId, INDUCTION_STATUS_TYPE.DRAFT, state, getUserObj(user)));
            await Promise.all(proms);
            setSelectedInductions([]);
            await loadInductions(selectedState);
        },
            "induction-state-change-draft-bulk",
            "Please wait...",
            "Inductions were converted to draft successfully.",
            "Something went wrong in converting one or more inductions to draft. Please retry."
        );
    }

    const convertToPublishBulk = async (inductions: Array<any>) => {
        await notifiableAPICall(async () => {
            const proms = inductions.map(async ({ inductionId, buildingId, state }) =>
                await changeState(inductionId, buildingId, INDUCTION_STATUS_TYPE.PUBLISH, state, getUserObj(user)));
            await Promise.all(proms);
            setSelectedInductions([]);
            await loadInductions(selectedState);
        },
            "induction-state-change-publish-bulk",
            "Please wait...",
            "Inductions were converted to publish successfully.",
            "Something went wrong in converting one or more inductions to publish. Please retry."
        );
    }

    const onBulkConvertToPublish = async () => {
        const publishableList = selectedInductions.filter(i => i.isPublishable);
        if (publishableList.length !== selectedInductions.length) {
            notifyConfirmation("There are some inductions which are not be able to be converted to publish. Do you wish to convert convertiable inductions to publish?",
                "Yes", () => convertToPublishBulk(publishableList), "No");
        } else {
            await convertToPublishBulk(publishableList);
        }
    }

    const onBulkMoveToTrash = async () => {
        confirm("Move inductions to Trash",
            "Are you sure you want to move selected inductions to trash?",
            async () => {
                await notifiableAPICall(async () => {
                    const proms = selectedInductions.map(async ({ inductionId, buildingId, state }) =>
                        await changeState(inductionId, buildingId, INDUCTION_STATUS_TYPE.TRASH, state, getUserObj(user)));
                    await Promise.all(proms);
                    setSelectedInductions([]);
                    await loadInductions(selectedState);
                },
                    "induction-state-change-trash-bulk",
                    "Please wait...",
                    "Inductions were moved to trash successfully.",
                    "Something went wrong in moving one or more inductions to trash. Please retry."
                );
            }, () => { });
    }

    const downloadBulk = async (inductions: Array<any>) => {
        await notifiableAPICall(async () => {
            const proms = inductions.map(async ({ videoKey, title }) =>
                await downloadVideo(videoKey, title));
            await Promise.all(proms);
            setSelectedInductions([]);
        },
            "induction-downloda-bulk",
            "Downloading...",
            "Inductions were downloaded.",
            "Something went wrong in downloading one or more inductions. Please retry."
        );
    }

    const onBulkDownload = async () => {
        const downloadableList = selectedInductions.filter(i => i.videoKey);
        if (downloadableList.length !== selectedInductions.length) {
            notifyConfirmation("There are some inductions which are not be able to be downloaded. Do you wish to download downloadabl inductions?",
                "Yes", () => downloadBulk(downloadableList), "No");
        } else {
            await downloadBulk(downloadableList);
        }
    }

    const restoreBulk = async (inductions: Array<any>) => {
        await notifiableAPICall(async () => {
            const proms = inductions.map(async ({ inductionId, buildingId, state, prevState }) =>
                await changeState(inductionId, buildingId, prevState, state, getUserObj(user)));
            await Promise.all(proms);
            setSelectedInductions([]);
            await loadInductions(selectedState);
        },
            "induction-state-change-restore-bulk",
            "Please wait...",
            "Inductions were restored successfully.",
            "Something went wrong in restoring one or more inductions. Please retry."
        );
    }

    const onBulkRestore = async () => {
        const restorableList = selectedInductions.filter(i => i.prevState !== INDUCTION_STATUS_TYPE.PUBLISH || i.isPublishable);
        if (restorableList.length !== selectedInductions.length) {
            notifyConfirmation("There are some inductions which are not be able to be restored. Do you wish to restore restorable inductions",
                "Yes", () => restoreBulk(restorableList), "No");
        } else {
            await restoreBulk(restorableList);
        }
    }

    const editModalOpen = (row: any) => {
        setEditRecordData(row);
        setMode(INDUCTION_MODAL_TYPE.EDIT);
        setModalVisible(true);
    }

    const onPlayerClick = (row: any) => {
        if (!row.videoKey) {
            return notifyWarning("Video is not available.");
        }
        setPlayingVideo(row);
    }

    const onPlayerClose = () => {
        setPlayingVideo(null);
    }

    const isInBulkArray = (induction: any) => {
        return selectedInductions.includes(induction);
    }

    const moreAction = (row: any) => isInBulkArray(row) ?
        <div className="ind-more"><MoreOutlined style={{ color: '#cecece' }} className="more-settings" /></div>
        :
        <Popover trigger='hover' placement='left' content={
            <PopOverBody row={row}
                state={selectedState}
                editModalOpen={editModalOpen}
                onDownload={onDownload}
                onConvertToDraft={onConvertToDraft}
                onConvertToPublish={onConvertToPublish}
                onMoveToTrash={onMoveToTrash}
                onRestore={onRestore}
                canPublish={row.isPublishable}
            />
        }>
            <div className="ind-more"><MoreOutlined className="more-settings" /></div>
        </Popover>

    const columns: Array<any> = [
        {
            title: 'Video',
            dataIndex: 'title',
            sorter: (a: any, b: any) => (a.title || '').localeCompare((b.title || '')),
            render: (title: string, row: any) => (<InductionTitle
                title={title}
                description={row.description}
                thumbUrl={row.thumbnailLink}
                onPlayClick={() => onPlayerClick(row)}
            />)
        },
        {
            title: 'Tenants',
            dataIndex: 'tenants',
            sorter: (a: any, b: any) => tenantsArrayFilter(a.tenants).localeCompare(tenantsArrayFilter(b.tenants)),
            render: (selectTenants: Array<string>) => <span className="tenantsArrayFilter">{tenantsArrayFilter(selectTenants)}</span>
        },
        {
            title: 'Building',
            dataIndex: 'buildingId',
            sorter: (a: any, b: any) => findBuildingName(a.buildingId).localeCompare(findBuildingName(b.buildingId)),
            render: (buildingId: string) => findBuildingName(buildingId)
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            render: (createdAt: string) => <span>{toDateString(createdAt)}</span>
        },
        {
            title: "",
            dataIndex: 'inductionId',
            key: 'inductionId',
            fixed: 'right',
            render: ((inductionId: string, row: any) =>
                moreAction(row)
            )
        }
    ];

    const rowSelection = {
        selectedRowKeys: selectedInductions.map(i => i.inductionId),
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectedInductions(selectedRows);
        }
    };

    const tableHeader = () => {
        if (selectedInductions.length === 0) return null;
        switch (selectedState) {
            case INDUCTION_STATUS_TYPE.PUBLISH:
                return (<Space>
                    <span>{selectedInductions.length} Selected</span>
                    <span className="divider-vertical"></span>
                    <Button type="text" className="link-btn link-btn--default" icon={<DownloadOutlined />} onClick={onBulkDownload}>Download</Button>
                    <Button type="text" className="link-btn link-btn--default" icon={<FormOutlined />} onClick={onBulkConvertToDraft}>Convert to Draft</Button>
                    <Button type="text" className="link-btn link-btn--danger" danger icon={<DeleteOutlined />} onClick={onBulkMoveToTrash}>Move to Trash</Button>
                </Space>);
            case INDUCTION_STATUS_TYPE.DRAFT:
                return (<Space>
                    <span>{selectedInductions.length} Selected</span>
                    <span className="divider-vertical"></span>
                    <Button type="text" className="link-btn link-btn--default" icon={<DownloadOutlined />} onClick={onBulkDownload}>Download</Button>
                    <Button type="text" className="link-btn link-btn--default" icon={<FormOutlined />} onClick={onBulkConvertToPublish}>Convert to Publish</Button>
                    <Button type="text" className="link-btn link-btn--danger" danger icon={<DeleteOutlined />} onClick={onBulkMoveToTrash}>Move to Trash</Button>
                </Space>);
            case INDUCTION_STATUS_TYPE.TRASH:
                return (<Space>
                    <span>{selectedInductions.length} Selected</span>
                    <span className="divider-vertical"></span>
                    <Button type="text" className="link-btn link-btn--default" icon={<UndoOutlined />} onClick={onBulkRestore}>Restore</Button>
                </Space>);
            default:
                return null;
        }
    }

    const videoPlayer = !!playingVideo ? <VideoPlayerModal details={playingVideo}
        buildingName={buildingIdNameMapping[playingVideo.buildingId]}
        onDownload={onDownload}
        onClose={onPlayerClose} /> : null;

    return (
        <>
            {videoPlayer}
            <Space direction='vertical'>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Row style={{ marginTop: 15 }}>
                        <Title level={4}>
                            {filteredList.length} Induction Videos
                    </Title>
                    </Row>
                    <Row className="grid-options">
                        <Space>
                            <Select defaultValue={INDUCTION_STATUS_TYPE.PUBLISH} onChange={onStateChange} >
                                {INDUCTION_STATUS_ARRAY.map((status: any) => (
                                    <Option key={status.state} value={status.state}>{status.name}</Option>
                                ))}
                            </Select>
                            <Select defaultValue={ALL} style={{ width: 160 }} onChange={onBuildingSelect}>
                                <Option value={ALL}>All Buildings</Option>
                                {buildings.map((building: any) => (
                                    <Option key={building.buildingId} value={building.buildingId}>{building.name}</Option>
                                ))}
                            </Select>
                        </Space>
                        <Space className="grid-options--search">
                            <Search placeholder="Search" allowClear onChange={(e) => onSearch(e.target.value)} className="grid-search-box" />
                            <Button
                                icon={<PlusOutlined />}
                                onClick={newInductionModalVisible}
                                className="btn btn--primary"
                            >
                                Upload Video
                            </Button>
                            {
                                inductionModal
                            }
                        </Space>
                    </Row>
                </Space>
                <Content className="table-wrapper">
                    <Table rowKey="inductionId"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredList}
                        title={tableHeader}
                        loading={isLoading}
                        pagination={{
                            total: filteredList.length,
                            showSizeChanger: true,
                            showTotal: (total, range) => `showing ${range[0]}-${range[1]} out of ${total} inductions`
                        }} />
                </Content>
            </Space >
        </>
    )
};
