import { Tooltip } from "antd";
import { PlayCircleFilled } from '@ant-design/icons';

type PropType = {
    title: string,
    description: string,
    thumbUrl: string,
    onPlayClick: Function;
}

const InductionTitle = ({ title, description, thumbUrl, onPlayClick }: PropType) => {
    return (
        <div className="ind-title">
            <div className="ind-title-img-wrapper fill" onClick={() => onPlayClick()}>
                <div className="ind-title-on-video-preview">
                    <PlayCircleFilled style={{ color: "#FFFFFF", fontSize: "24px" }} />
                </div>
                <img className="ind-title-img" src={thumbUrl ? thumbUrl : "/images/no_image.jpg"} alt={title} />
            </div>
            <div className="ind-title-label">
                <Tooltip placement="top" title={description}>
                    <span>{title}</span>
                </Tooltip>
            </div>
        </div>
    );
}

export default InductionTitle;