import { Button, Row, Space, Upload, Image, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { notifyConfirmation } from '../../utils/notification';
import { getBase64, isValidImage, resizeImageFile, THUMBNAIL_MAX_HEIGHT, THUMBNAIL_MAX_WIDTH } from '../../utils/img-utils';
import Icon from '../../components/Icon';

const { Dragger } = Upload;

export const ImageUploadView = ({ setThumbnailImg, issueImg, setIssueImg, onCreate, onCancel }: any) => {
    return (
        <Space direction='vertical' className='intnnt-spc img-upload'>
            <Row>
                <p className='intnnt-sbttl'>Upload Image</p>
                {issueImg && <Button onClick={() => setIssueImg(undefined)} icon={<DeleteOutlined color='#E83034' />} type='text' style={{ paddingRight: 0, color: '#E83034' }} >Remove</Button>}
            </Row>
            <Row >
                {!issueImg && <Dragger
                    name='file'
                    multiple={false}
                    beforeUpload={(file: File) => {
                        if (isValidImage(file, undefined)) {
                            getBase64(file).then(base64 => setIssueImg({ file, url: base64 }));
                            resizeImageFile(file, THUMBNAIL_MAX_WIDTH, THUMBNAIL_MAX_HEIGHT).then(newFile => setThumbnailImg(newFile));
                        }
                        return false;
                    }}
                    showUploadList={false}
                    className='thumb-upload'
                    accept="image/*"
                >
                    <p className="ant-upload-drag-icon">
                        <Icon icon="upload-image" width="36px" height="36px" />
                    </p>
                    <p className="ant-upload-text">Upload Image</p>
                </Dragger>}
                {issueImg && <Image width={'100%'} src={issueImg.url} />}
            </Row>
            <Row className='intnnt-btn-rw'>
                <Col span={8} style={{ alignSelf: 'center' }}>
                    <Button
                        type='text'
                        icon={<DeleteOutlined className='intnnt-btn-dngr' />}
                        className='link-btn link-btn--danger'
                        onClick={() => notifyConfirmation('Continuing this action will discard your data', 'Discard', onCancel)}
                    >
                        {'Discard'}
                    </Button>
                </Col>
                <Col span={12} pull={4} style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                    <Button style={{ paddingLeft: 10, paddingRight: 10 }} className='btn btn--primary' onClick={onCreate}>
                        Add New Issue
                    </Button>
                </Col>
            </Row>
        </Space>
    );
}