import { Auth } from "aws-amplify";
import download from 'downloadjs';
const { Parser } = require('json2csv');

export const isSuperUser = async () => {
    const session = await Auth.currentSession();
    const groups = session.idToken.payload['cognito:groups'];
    return (groups && groups.includes('Superusers')) ? true : false;
}

export const isAdminUser = async () => {
    const session = await Auth.currentSession();
    const groups = session.idToken.payload['cognito:groups'];
    return (groups && groups.includes('Adminusers')) ? true : false;
}

export const getCurrentUserId = async () => {
    const session = await Auth.currentSession();
    const userId = session.idToken.payload['sub'];
    return userId;
}

export const sleep = async (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getFileName = (path) => path ? path.replace(/^.*[\\/]/, '') : '';

export const getFileExtension = (filename) => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
}

export const extractContent = (str, max) => {
    var span = document.createElement('span');
    span.innerHTML = str;
    const content = span.textContent || span.innerText;
    if (max >= 0) return content.length > max ? `${content.substr(0, max)}...` : content;
    return content;
};

export const isValidPhoneNumber =
    (phoneNumber) => (/^[+]?[0-9]{3}?[0-9]{3}[0-9]{4,6}$/im).test(phoneNumber);

export const getBuildingTenants = (tenants, buildingId) => {
    return tenants.filter(t => t.buildingId === buildingId);
}

export const getKeyByValue = (object, value) => {
    value = value.toLowerCase();
    for (var prop in object) {
        if (object.hasOwnProperty(prop)) {
            if (object[prop]?.toLowerCase() === value) {
                return prop;
            }
        }
    }
}
export const getUserObj = (loggedUser) => {
    return {
        name: loggedUser.firstName + " " + loggedUser.lastName,
        id: loggedUser.userId
    }
}

export const stringSorter = (s1, s2) => {
    var textA = s1.toUpperCase();
    var textB = s2.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}

export const numberSorter = (n1, n2) => {
    return n1-n2
}

export const convertToCsv = (opts, dataArray, fileName) => {
    try {
        const parser = new Parser(opts);
        const csv = parser.parse(dataArray);
        const file = new Blob([csv], { type: 'text/csv' });
        download(file, fileName);
    } catch (err) {
        console.error(err);
    }
}

export const defaultStartTime = (date) => {
    return date.set("hour", 8).set("minute", 30).set("second", 0);
}

export const defaultEndTime = (date) => {
    return date.set("hour", 17).set("minute", 0).set("second", 0);
}