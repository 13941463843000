export const USER_STATE = {
    ACTIVE: 1,
    INACTIVE: -1
};

export const USER_STATE_ARRAY = [
    { name: "Active", state: USER_STATE.ACTIVE },
    { name: "Inactive", state: USER_STATE.INACTIVE },
];

export const USER_ROLE = {
    ADMIN: 'IntenantAdmin',
    USER: 'IntenantUser',
};

export const USER_MODAL_TYPE = {
    CREATE: 'create',
    EDIT: 'edit',
}

export const CSV_EXPORT_FIELDS = ['firstName', 'lastName', 'email', 'countryDialingCode', 'contactNumber', 'licensePlate', 'tenant', 'building', 'status', "credentialId", "createdDate"];

export const CSV_ERROR_DOWNLOAD_FIELDS = ['firstName', 'lastName', 'email', 'countryDialingCode', 'contactNumber', 'licensePlate', 'tenant', 'building',  'validationMessage'];

export const ADMIN_MODAL_TYPE = {
    ASSIGN:'Assign Admin',
    Unassign:'Unassign Admin',
}