import { getApi, APIS, postApi } from "../utils/api-utils";
import { USER_ROLE } from "./constants";

export const getUsers = async (state: number, buildingId: string) => {
    let params: any = {
        state: state,
        buildingId: buildingId
    };

    return await getApi(APIS.USER_API, '/users', { queryStringParameters: params })
}

export const addUser = async (data: any) => {
    data.email = data.email.toLowerCase();
    await postApi(APIS.USER_API, '/user', data);
}

export const changeRole = async (userId: string, tenantId: string, currRole: string, buildingId: string) => {
    const params = {
        userId,
        tenantId,
        role: currRole === USER_ROLE.USER ? USER_ROLE.ADMIN : USER_ROLE.USER,
        buildingId
    }
    await postApi(APIS.USER_API, '/user/usergroup', params);
}

export const changeState = async (userId: string, tenantId: string, buildingId: string) => {
    const params = {
        userId,
        tenantId,
        buildingId
    }
    await postApi(APIS.USER_API, '/user/state', params);
}

export const revokeMobileId = async (userId: string, tenantId: string, buildingId: string) => {
    const params = {
        userId,
        tenantId,
        buildingId
    }
    await postApi(APIS.USER_API, '/user/revoke-mobile-credential', params);
}

export const editUser = async (params: any) => {
    await postApi(APIS.USER_API, '/user/edit', params);
}

export const isUserExistError = (e: any) => {
    return e && e.response && e.response.data && e.response.data.message === 'User account already exists';
}