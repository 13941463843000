import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import ContactNumberFormItem from "../components/ContactNumberFormItem";
import { sessionStore, SESSION_ATTRS } from "../store/session";
import { getDialCode } from "../utils/countryCodes";
import { USER_MODAL_TYPE } from "./constants";

type PropType = {
    onClose: Function,
    onSubmit: (data:any) => Promise<any>,
    mode: string,
    editRowData: any,
}

const FormItem = Form.Item;

const NAME_ITEMS_LAYOUT = {
    labelCol: { span: 23 },
    wrapperCol: { span: 23 }
}

const NewUserModal = ({ onClose, onSubmit, mode, editRowData }: PropType) => {

    const [form] = Form.useForm();

    const { firstName, lastName, email, contactNumber, licensePlate, buildingId, tenantId, countryCode} = editRowData;

    const [tenantBuilding, setTenantBuilding] = useState<Array<any>>([]);

    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const tenants: any = state.get(SESSION_ATTRS.TENANTS);

    const onSubmitClick = async () => {
        try {
            let values = await form.validateFields();
            values.email = values.email.toLowerCase();
            values.countryDialingCode = getDialCode(values.countryCode);
            onSubmit(values);
        } catch (e) {
            console.error(e);
        }
    }

    const onTenantSelect = useCallback((tenantId: string) => {
        const tenant = tenants.find((t: any) => t.tenantId === tenantId);
        const tntBuildings = buildings.filter((b: any) => b.buildingId === tenant.buildingId);
        setTenantBuilding(tntBuildings);
    },[tenants, buildings]);

    useEffect(()=>{
        if (!!tenantId) {
            onTenantSelect(tenantId);
        }
    },[tenantId, onTenantSelect])

    const tenantOptions = (
        tenants.map((t: any) => <Select.Option key={t.tenantId} value={t.tenantId}>{t.name}</Select.Option>)
    );

    const buildingOptions = (
        tenantBuilding.map(b => <Select.Option key={b.buildingId} value={b.buildingId}>{b.name}</Select.Option>)
    )

    return (
        <Modal
            visible={true}
            footer={null}
            onCancel={() => onClose()}
            wrapClassName="add-new-user-modal"
            maskClosable={false}
            centered
        >
            <h2 className="new-user-title">Add Single User</h2>
            <div className="add-user-form-wrp">
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={{firstName, lastName, email, licensePlate, tenantId, buildingId, contactNumber , countryCode }}
                >
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="First Name"
                                name="firstName"
                                {...NAME_ITEMS_LAYOUT}
                                rules={[{ required: true, message: 'Please enter first name' }]}
                            >
                                <Input placeholder="First Name" />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="Last Name"
                                name="lastName"
                                rules={[{ required: true, message: 'Please enter last name' }]}
                            >
                                <Input placeholder="Last Name" />
                            </FormItem>
                        </Col>
                    </Row>
                    <ContactNumberFormItem placeHolder="Phone number"/>
                    <FormItem
                        label="Email"
                        name="email"
                        rules={[{ type: 'email', message: 'Please enter a valid email' },
                        { required: true, message: 'Please enter email' }]}
                    >
                        <Input disabled={mode === USER_MODAL_TYPE.EDIT} placeholder="Email" type="email" />
                    </FormItem>
                    <FormItem
                        label="License Plate"
                        name="licensePlate"
                    >
                        <Input placeholder="License plate" />
                    </FormItem>
                    <FormItem
                        label="Tenant"
                        name="tenantId"
                        rules={[{ required: true, message: 'Please select a tenant' }]}
                    >
                        <Select disabled={mode === USER_MODAL_TYPE.EDIT} placeholder="Pick a company" onChange={onTenantSelect}>
                            {tenantOptions}
                        </Select>
                    </FormItem>
                    <FormItem
                        label="Access"
                        name="buildingId"
                        rules={[{ required: true, message: 'Please select a building' }]}
                    >
                        <Select placeholder="Select a building">
                            {buildingOptions}
                        </Select>
                    </FormItem>
                    <FormItem>
                        <Button className="btn btn--primary new-user-manual-btn" onClick={onSubmitClick}>{mode === USER_MODAL_TYPE.CREATE ? 'Add New User' : 'Update User'}</Button>
                    </FormItem>
                </Form>
            </div>
        </Modal>
    );
}

export default NewUserModal;