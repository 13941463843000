import { Button, Modal, Upload } from "antd";
import { FileExcelFilled, LoadingOutlined, CheckCircleOutlined, CloseCircleOutlined, ExportOutlined } from '@ant-design/icons';
import { useContext, useState } from "react";
import csvToJson from 'csvtojson';
import download from 'downloadjs';

import { getFileExtension, getKeyByValue } from "../utils/common-utils";
import { sessionStore, SESSION_ATTRS } from "../store/session";
import { CSV_ERROR_DOWNLOAD_FIELDS } from "./constants"
import { notifiableAPICall, notifyWarning } from '../utils/notification';
import Icon from "../components/Icon";
import { getCountryCode } from "../utils/countryCodes";
import { isUserExistError } from "./actions";
import { CSV_EXTENTION_STRING } from "../inductionVideos/constants";

const { Parser } = require('json2csv')

type PropType = {
    onClose: Function,
    onUserAddManually: Function,
    onBulkUserAddSucess: Function,
    onUserAdd: Function;
}

const Dragger = Upload.Dragger;

const UPLOAD_STATE = {
    UPLOADING: 0,
    SUCCESS: 1,
    VALIDATION_FAILED: -1,
    INSERT_FAILED: -2,
    INVALID_TEMPLATE: -3
}

const UI_STATE = {
    SELECT: 1,
    UPLOAD: 2
}

const ImportUserModal = ({ onClose, onUserAddManually, onBulkUserAddSucess, onUserAdd }: PropType) => {

    const [uiState, setUiState] = useState<number>(UI_STATE.SELECT);
    const [csvFile, setCsvFile] = useState<File | undefined>();
    const [uploadState, setUploadState] = useState<number>(UPLOAD_STATE.UPLOADING);
    const [users, setUsers] = useState<Array<any>>([]);

    const { state } = useContext(sessionStore);
    const tenantIdNameMapping: any = state.get(SESSION_ATTRS.TENANT_ID_NAMES);
    const buildingIdNameMapping: any = state.get(SESSION_ATTRS.BUILDING_ID_NAMES);

    const readCSV = (file: File) => {
        return new Promise((res: (t: string) => void, rej) => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                res(event.target.result);
            };

            reader.onerror = (event) => {
                rej(event);
            };

            reader.readAsText(file);

        });
    }

    const validateEmail = (email: string): boolean => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const getValidationError = (tenant: any, building: any, firstname: any, lastname: any, email: any, contactNumber: any, countryDialingCode: any) => {
        let msg = "";
        if (!tenant) msg = `${msg}Tenant not found, `;
        if (!building) msg = `${msg}Building not found, `;
        if (!firstname) msg = `${msg}first name, `;
        if (!lastname) msg = `${msg}last name, `;
        if (!email) msg = `${msg}email, `;
        if (!contactNumber) msg = `${msg}contact number, `;
        if (!countryDialingCode) msg = `${msg}country dialing code, `;
        if (msg) msg = `${msg} field(s) are missing.`;
        if (email && !validateEmail(email)) msg = `${msg} Email is invalid.`;
        return msg;
    }

    const onUpload = async (file: File) => {
        setUiState(UI_STATE.UPLOAD);
        setCsvFile(file);
        try {
            setUploadState(UPLOAD_STATE.UPLOADING);
            const content = await readCSV(file);
            const data = await csvToJson().fromString(content);

            const removeUsers: any = [];

            data.forEach((element, index) => {
                const tenantId = getKeyByValue(tenantIdNameMapping, element.tenant);
                const buildingId = getKeyByValue(buildingIdNameMapping, element.building);
                if (!tenantId || !buildingId || !element.firstName || !element.lastName || !element.email || !element.contactNumber || !element.countryDialingCode || !validateEmail(element.email)) {
                    removeUsers.push(element);
                    element.validationMessage = getValidationError(tenantId, buildingId, element.firstName,
                        element.lastName, element.email, element.contactNumber, element.countryDialingCode);
                }
                else {
                    element.tenantId = tenantId;
                    element.buildingId = buildingId;
                    element.countryDialingCode = (!element.countryDialingCode.startsWith('+') ? `+${element.countryDialingCode}` : element.countryDialingCode);
                    element.countryCode = getCountryCode(element.countryDialingCode);
                    element.validationMessage = 'Valid record';
                }
            });

            if (removeUsers.length > 0) {
                setUsers(data);
                setUploadState(UPLOAD_STATE.VALIDATION_FAILED);
            } else {
                setUsers(data);
                setUploadState(UPLOAD_STATE.SUCCESS);
            }

        } catch (e) {
            console.error(e);
            setUploadState(UPLOAD_STATE.INVALID_TEMPLATE);
        }
    }

    const csvBeforeUploadProps = {
        name: 'file',
        multiple: false,
        beforeUpload: (file: File, fileList: any) => {
            const fileSize = file.size / 1000000;
            if (fileSize > 100) {
                notifyWarning("File size exceeded!");
                return false;
            }
            const ext = getFileExtension(file.name);
            if (!ext || !CSV_EXTENTION_STRING.includes(ext.toLowerCase())) {
                notifyWarning("incorrect file type!");
                return false;
            }
            onBeforeUpload(file);
            return false;
        },
        showUploadList: false
    }

    const onBeforeUpload = (file: File) => {
        setTimeout(() => onUpload(file), 50);
        return false;
    }

    const onReupload = () => {
        setUiState(UI_STATE.SELECT);
        setUploadState(UPLOAD_STATE.UPLOADING);
    }

    const onBulkUserAdd = async (users: any[]) => {

        const removeUsers: any = [];

        await notifiableAPICall(async () => {
            const proms = users.map(async (user) => {
                try {
                    await onUserAdd(user);
                    return true;
                } catch (e) {
                    console.error(e);
                    let errMessage = "Unexpected error occured. Please retry.";
                    if (isUserExistError(e))
                        errMessage = "Email address is already occupied.";
                    user.validationMessage = errMessage;
                    removeUsers.push(user);
                    return false;
                }
            });

            await Promise.all(proms);

            if (removeUsers.length > 0) {
                setUsers(removeUsers);
                setUploadState(UPLOAD_STATE.INSERT_FAILED);
                throw Error("user insert failed");
            } else {
                onBulkUserAddSucess();
            }

        },
            "user-bulk-add",
            "Please wait...",
            "Added user(s) successfully.",
            "Something went wrong while adding one or more user(s). Please retry."
        );
    }

    const onUsersAdd = () => {
        const uploadData = users;
        const removeUsers: any = [];
        uploadData.forEach((element, index) => {

            if (!element.tenantId || !element.buildingId || !element.firstName || !element.lastName || !element.email || !element.contactNumber || !element.countryDialingCode) {
                removeUsers.push(element);
                uploadData.splice(index, 1);
            }
        });

        if (removeUsers.length === 0) {
            onBulkUserAdd(users);
        }
    }

    const status = () => {
        switch (uploadState) {
            case UPLOAD_STATE.UPLOADING:
                return (<span className="new-user-status-wrp"><LoadingOutlined className="new-user-status" /> Uploading...</span>);
            case UPLOAD_STATE.SUCCESS:
                return (<span className="new-user-status-wrp success" ><CheckCircleOutlined className="new-user-status success" /> Success</span>);
            case UPLOAD_STATE.VALIDATION_FAILED:
                return (<span className="new-user-status-wrp error" ><CloseCircleOutlined className="new-user-status error" /> Failed</span>);
            case UPLOAD_STATE.INSERT_FAILED:
                return (<span className="new-user-status-wrp error" ><CloseCircleOutlined className="new-user-status error" /> Failed</span>);
            case UPLOAD_STATE.INVALID_TEMPLATE:
                return (<span className="new-user-status-wrp error" ><CloseCircleOutlined className="new-user-status error" /> Failed</span>);

        }
        return null;
    }

    const opts = { fields: CSV_ERROR_DOWNLOAD_FIELDS };

    const downloadErrorCSV = () => {
        try {
            const parser = new Parser(opts);
            const csv = parser.parse(users);
            const file = new Blob([csv], { type: 'text/csv' });
            download(file, 'Users-Errors.csv');
        } catch (err) {
            console.error(err);
        }
    }

    const body = () => {
        switch (uiState) {
            case UI_STATE.UPLOAD:
                return (<>
                    <h2 className="new-user-title">Upload User(s)</h2>
                    <div className="new-user-upload-progress-wrp">
                        <div className="new-user-upload-progress">
                            <span className="new-user-upload-label">
                                <FileExcelFilled className="new-user-upload-file" />
                                <span className="new-user-file-name">{csvFile?.name}</span>
                            </span>
                            <span className="new-user-file-progress">
                                {status()}
                            </span>
                        </div>
                        {
                            uploadState === UPLOAD_STATE.VALIDATION_FAILED &&
                            <div>
                                <p>Some Validation Errors found in the uploaded data set(s). Please fix them and reupload. </p>
                                <Button type='link' style={{ color: '#104EA1', fontSize: 12, fontWeight: 600 }} icon={<ExportOutlined style={{ color: '#104EA1', fontWeight: 600 }} />} onClick={downloadErrorCSV} >Validation errors</Button>
                                <div className="new-user-repload-wrp">
                                    <Button type="link" onClick={onReupload}>Reupload</Button>
                                </div>
                            </div>
                        }
                        {
                            uploadState === UPLOAD_STATE.INSERT_FAILED &&
                            <div>
                                <p>Some errors occurred in the uploaded data set(s). Please verify them and reupload. </p>
                                <Button type='link' style={{ color: '#104EA1', fontSize: 12, fontWeight: 600 }} icon={<ExportOutlined style={{ color: '#104EA1', fontWeight: 600 }} />} onClick={downloadErrorCSV} >Upload errors</Button>
                                <div className="new-user-repload-wrp">
                                    <Button type="link" onClick={onReupload}>Reupload</Button>
                                </div>
                            </div>
                        }
                        {
                            uploadState === UPLOAD_STATE.INVALID_TEMPLATE &&
                            <div>
                                <p>Some errors occurred in the uploaded data set(s). Please verify it is in the correct template. </p>
                                <Button type='link' style={{ color: '#104EA1', fontSize: 12, fontWeight: 600 }} icon={<ExportOutlined style={{ color: '#104EA1', fontWeight: 600 }} />} onClick={downloadErrorCSV} >Upload errors</Button>
                                <div className="new-user-repload-wrp">
                                    <Button type="link" onClick={onReupload}>Reupload</Button>
                                </div>
                            </div>
                        }
                        <Button disabled={uploadState !== UPLOAD_STATE.SUCCESS} className="btn btn--primary new-user-manual-btn" onClick={onUsersAdd}>Add Multiple Users</Button>
                    </div>
                </>);
            case UI_STATE.SELECT:
                return (
                    <>
                        <h2 className="new-user-title">Add New User(s)</h2>
                        <div className="new-user-help-tip">You can upload user(s) automatically with your .CSV file or
                            <Button type="link" href="/templates/users-upload-template.csv">download the .CSV template</Button>to start.
                        </div>
                        <div className="new-user-import">
                            <Dragger {...csvBeforeUploadProps} accept=".csv">
                                <p className="ant-upload-drag-icon">
                                    <Icon icon="upload-cloud" width="50px" height="50px" />
                                </p>
                                <p className="ant-upload-text">Drag & Drop file to upload or</p>
                                <p className="new-user-upload-hint">browse from your device</p>
                                <p className="ant-upload-hint">Only .csv formats with max size of 5MB</p>
                            </Dragger>
                            <span className="new-user-seperator">or</span>
                            <Button className="btn btn--primary new-user-manual-btn" onClick={() => onUserAddManually()}>Add New User Manually</Button>
                        </div>
                    </>
                );
        }
        return null;
    }

    return (
        <>
            <Modal
                visible={true}
                footer={null}
                onCancel={() => onClose()}
                wrapClassName="new-user-modal"
                maskClosable={false}
            >
                <div className="new-user-title-wrapper">
                    {body()}
                </div>
            </Modal>
        </>
    )
}

export default ImportUserModal;