import { Modal, Row, Col, Space, Form, Descriptions, Image, Select, Button, Input, Comment, Tooltip, List, Tabs, Typography } from 'antd';
import moment from 'moment';
import { useContext, useState } from 'react';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { toDateString } from '../utils/date-time';
import { notifiableAPICall, notifyWarning } from '../utils/notification';
import { addComment, changeIssueStatus } from './actions';
const { Text } = Typography;

export const IssueDetailsModal = ({ onClose, statusList, issue, categories, onUpdate }: any) => {
    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const tenants: any = state.get(SESSION_ATTRS.TENANTS);
    const [status, setStatus] = useState(issue.currentStatus);
    const [form] = Form.useForm();
    const [statusForm] = Form.useForm();
    const [addingComment, setAddingComment] = useState(false);
    const [updatingStatus, setUpdatingStatus] = useState(false);

    const onAddComment = async () => {
        try {
            let { comment } = await form.validateFields();
            setAddingComment(true);
            let { success } = await notifiableAPICall(async () => await addComment(issue.buildingId, issue.issueId, comment),
                'add-issue',
                'Adding comment...',
                'Comment added successfully',
                'something went wrong!'
            );
            if (success) {
                form.setFieldsValue({ comment: '' });
                onUpdate(issue.issueId);
            }
        } catch (err) {
            console.error(err);
        }
        setAddingComment(false);
    }

    const onStatusUpdate = async () => {
        try {
            if (status === issue.currentStatus) {
                notifyWarning('Issue status is not changed');
                return;
            }
            setUpdatingStatus(true);
            const oldStatus = statusList.find((s: any) => s.statusId === issue.currentStatus);
            const newStatus = statusList.find((s: any) => s.statusId === status);
            let { success } = await notifiableAPICall(async () => await changeIssueStatus(issue.buildingId, issue.issueId, oldStatus, newStatus),
                'add-issue',
                'Updating status...',
                'Status updated successfully',
                'something went wrong!'
            );
            if (success) {
                const statusComment = statusForm.getFieldValue('cmnt');
                if (statusComment) {
                    let { success: scss } = await notifiableAPICall(async () => await addComment(issue.buildingId, issue.issueId, statusComment),
                        'add-issue',
                        'Adding comment...',
                        'Status updated successfully',
                        'something went wrong!'
                    );
                    if (scss) {
                        statusForm.setFieldsValue({ cmnt: '' });
                    }
                }
                onUpdate(issue.issueId);
            }
        } catch (err) {
            console.error(err);
        }
        setUpdatingStatus(false);
    }
    return (
        <Modal
            visible={true} onCancel={onClose} footer={null} width={890}
            bodyStyle={{ padding: 0 }} style={{ borderRadius: 10, overflow: 'hidden' }}
            className='intnnt-mdl bs-add-space-mdl' centered
        >
            <Row className='intnnt-rw'>
                <Col className='intnnt-clmn intnnt-clmn-left' style={{ marginBottom: '40px' }}>
                    <Space direction='vertical' align='baseline' className='intnnt-spc intnnt-spc-wdth'>
                        <Descriptions title="Issue Details" bordered column={1} labelStyle={{ width: '140px' }} contentStyle={{ padding: '16px 0px 16px 24px' }}>
                            <Descriptions.Item label="Issue">{issue.description}</Descriptions.Item>
                            <Descriptions.Item label="Category">{categories.find((c: any) => c.categoryId === issue.categoryId)?.categoryName}</Descriptions.Item>
                            <Descriptions.Item label="Notes">{issue.notes}</Descriptions.Item>
                            <Descriptions.Item label="Building">{buildings.find((b: any) => b.buildingId === issue.buildingId)?.name}</Descriptions.Item>
                            <Descriptions.Item label="Tenant">{tenants.find((b: any) => b.tenantId === issue.tenant)?.name}</Descriptions.Item>
                            <Descriptions.Item label="Raised By">{issue.createdBy.name}</Descriptions.Item>
                            <Descriptions.Item label="Created Date">{toDateString(issue.createdAt)}</Descriptions.Item>
                        </Descriptions>
                    </Space>
                </Col>
                <Col className='intnnt-clmn intnnt-clmn-right intnnt-drk-10'>
                    <Space direction='vertical' className='intnnt-spc img-upload' size={20}>
                        <Row><p className='intnnt-sbttl'>Photo</p></Row>
                        {!issue.imageLinks?.length && <Row>No Images Attached</Row>}
                        <Row >
                            {issue.imageLinks?.length ? <Image src={issue.imageLinks[0]} style={{ maxHeight: '300px' }} /> :
                                <Image style={{ maxHeight: '300px', objectFit: 'cover' }} height={200} width="100%" src="/images/issues.jpg" />}
                        </Row>

                        <Form form={statusForm} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                            <Form.Item name='statusId' label="Status">
                                <Select placeholder='Select a status' className='intnnt-npt' defaultValue={status} onChange={setStatus}>
                                    {statusList.filter(({ isArchived, statusId }: any) => !isArchived || status === statusId).map((st: any) => <Select.Option key={st.statusId} value={st.statusId}>{st.statusName}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            {status !== issue.currentStatus && <Form.Item name='cmnt' label="Comment">
                                <Input.TextArea rows={2} />
                            </Form.Item>}
                        </Form>

                        <Row justify='end'>
                            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                                <Button
                                    className='btn btn--o-primary'
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                                <Button style={{ paddingLeft: 10, paddingRight: 10 }} className='btn btn--primary' onClick={onStatusUpdate} loading={updatingStatus}>
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </Space>
                </Col>
            </Row>
            <Row className='intnnt-rw' style={{ paddingBottom: '40px' }}>
                <Col className='intnnt-clmn' style={{ flex: 1 }}>
                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab='Activity' key="1">
                            <List
                                className="comment-list" itemLayout="horizontal" dataSource={issue.activities}
                                renderItem={(item: any) => (
                                    <Comment author={item.by.name} content={<><Text type="secondary" style={{ fontSize: '12px' }}>{moment(item.createdAt).format('YYYY-MM-DD hh:mm A')}:</Text> {item.description}</>}
                                        datetime={<Tooltip title={moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}><span>{moment(item.createdAt).fromNow()}</span></Tooltip>}
                                    />
                                )}
                                locale={{ emptyText: 'No comments' }}
                            />
                            <Form form={form}>
                                <Form.Item name='comment' rules={[{ required: true, message: 'Please enter comment!' }]}>
                                    <Input.TextArea rows={2} />
                                </Form.Item>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" onClick={onAddComment} loading={addingComment}>Add Comment</Button>
                                </Form.Item>
                            </Form>
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </Modal >
    );
};