export const BUILDING_CONTACTS_MODEL_TYPE = {
    CREATE: 'create',
    EDIT: 'edit'
};

export const ROLES_ARRAY = [
    {
        value: 'cleaning',
        name: 'Cleaning'
    },
    {
        value: 'maintenance',
        name: 'Maintenance'
    },
    {
        value: 'dockmaster',
        name: 'Dockmaster'
    },
    {
        value: 'taxi',
        name: 'Taxi'
    },
    {
        value: 'security',
        name: 'Security'
    },
    {
        value: 'facilities_manager',
        name: 'Facilities Manager'
    }
];

export const BUILDING_CONTACT_STATE = {
    ACTIVE: 1,
    INACTIVE: -1
};

export const BUILDING_CONTACT_STATUS = [
    { name: "Active", state: BUILDING_CONTACT_STATE.ACTIVE },
    { name: "Inactive", state: BUILDING_CONTACT_STATE.INACTIVE }
]

export const BUILDING_CONTACT_MODAL_TYPE = {
    CREATE: 'create',
    EDIT: 'edit'
};

export const CSV_EXPORT_FIELDS = ['Role', 'Access', 'ContactNo', 'Description', 'Status', 'LastUpdatedAt'];
