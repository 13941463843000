import { Layout, Row, Table, Button, Tooltip, Modal, Popover, DatePicker, Space, Col, Select, TimePicker } from 'antd';
import { useEffect, useState } from 'react';
import { stringSorter, defaultStartTime, defaultEndTime } from '../../utils/common-utils';
import { editIcon, redLocationPinIcon, greenLocationPinIcon, deleteIcon, mapIcon, greenPinIcon, redPinIcon, grayLocationPinIcon, grayPinIcon, amberLocationPinIcon, amberPinIcon } from '../svgIcons';
import { MoreOutlined } from '@ant-design/icons';
import { confirm, notifiableAPICall, notifyError, notifySuccess } from '../../utils/notification';
import { BookingDeskModal } from './deskModal/BookingDeskModal';
import { deleteFloor, getDeskListByFloorId } from '../actions';
import { DESK_STATUS, BOOKING_CHECKED_STATUS } from '../constants';
import { FloorModal } from "./floorModal/FloorModal";
import { isMobile } from "react-device-detect";
import moment from "moment";

const { Content } = Layout;
const ALL = "all";
const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'h:mm a';
export const TODAY = 'today';
export const TOMORROW = 'tomorrow';
export const CUSTOM = 'custom';

let onEditFloorAction: Function;
let onDeleteDeskAction: Function;

export const FloorManagement = ({ newDeskModalVisible, setNewDeskModalVisible, selectedBuilding, searchPhrase, floorList, buildings, loadFloors, deskList, loadDesks }: any) => {

    const [filteredList, setFilteredList] = useState<any[] | undefined>(undefined);
    const [editingFloor, setEditingFloor] = useState<Object | undefined>();
    const [showDeskLocation, deskLocationsVisibility] = useState<boolean>(false);
    const [locationFloor, setLocationFloor] = useState<any>(undefined);
    const [editingDesks, setEditingDesks] = useState<Object | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [floorDeskBookings, setFloorDeskBookings] = useState<any[]>([]);
    const [isImgLoad, setImgState] = useState<boolean>(false);
    const [floorSelectDateType, setFloorSelectDateType] = useState<string>(TODAY);
    const [fromTime, setFromTime] = useState<any>(defaultStartTime(moment())); 
    const [toTime, setToTime] = useState<any>(defaultEndTime(moment()));

    const checkAvailability = async () => {
        setIsLoading(true);
        const deskList = await getDeskListByFloorId(locationFloor.buildingId, locationFloor.floorId, fromTime.toISOString(), toTime.toISOString());
        setFloorDeskBookings(deskList);
        setIsLoading(false);
    };

    useEffect(() => {
        if(!locationFloor || !showDeskLocation) return;
        checkAvailability();
    }, [locationFloor, showDeskLocation]);

    onEditFloorAction = (floor: Object) => setEditingFloor(floor);
    onDeleteDeskAction = (floor: any) => {
        confirm("Delete floor", `Are you sure you want to delete this floor (${floor.name})?`,
            async () => {
                let { success, hasDesks } = await notifiableAPICall(async () => await deleteFloor(floor.buildingId, floor.floorId),
                    "delete-floor",
                    "Deleting floor...",
                    undefined,
                    "Something went wrong in deleting floor. Please retry."
                );
                if (success) {
                    notifySuccess('Floor deleted successfully');
                    loadFloors();
                }
                else if (hasDesks) notifyError("Cannot delete floor because desks are already assigned and bookings may exist");
            }, () => { });
    }

    const onCloseFloorModal = () => {
        setImgState(false)
        setFloorSelectDateType(TODAY);
        setFloorDeskBookings([]);
        deskLocationsVisibility(false);
    }

    const getFloorDesks = (id: string) => {
        return deskList ? deskList.filter((desk: any) => desk.floorId === id) : [];
    }

    const displayMapModal = (desk: any) => {
        const floor = floorList.find((floor: any)=>floor.floorId === desk.floorId);
        setLocationFloor(floor);
        deskLocationsVisibility(true);
    }

    const getBuildingName = (id: string) => {
        const building = buildings.filter((building: any) => building.buildingId === id);
        return building[0].name;
    }

    const getDeskCount = (floorId: string) => {
        const desks = deskList ? deskList.filter((desk: any) => desk.floorId === floorId) : [];
        return desks.length;
    }

    const onEditDesk = (desk: Object) => {
        deskLocationsVisibility(false);
        setEditingDesks(desk);
    }

    const setDateType = (value: any) => {
        let from;
        let to;
        if (value === TOMORROW) {
            from = defaultStartTime(moment().add(1, 'days'));
            to = defaultEndTime(moment().add(1, 'days'));
        }
        else {
            from = defaultStartTime(moment());
            to = defaultEndTime(moment());
        }
        setFromTime(from);
        setToTime(to);
        setFloorSelectDateType(value);
    }

    const setTime = (time:any) => {
        const date = moment(fromTime).format(DATE_FORMAT);
        const startTime = moment(time[0]).format(TIME_FORMAT);
        const endTime = moment(time[1]).format(TIME_FORMAT);
        setFromTime(moment(date + ' ' + startTime, `${DATE_FORMAT} ${TIME_FORMAT}`));
        setToTime(moment(date + ' ' + endTime, `${DATE_FORMAT} ${TIME_FORMAT}`));
    }

    const setDate = (selectedDate:any) => {
        const date = moment(selectedDate).format(DATE_FORMAT);
        const startTime = moment(fromTime).format(TIME_FORMAT);
        const endTime = moment(toTime).format(TIME_FORMAT);
        setFromTime(moment(date + ' ' + startTime, `${DATE_FORMAT} ${TIME_FORMAT}`));
        setToTime(moment(date + ' ' + endTime, `${DATE_FORMAT} ${TIME_FORMAT}`));
    }

    const deskMap = () => {
        const floorDesks = getFloorDesks(locationFloor?.floorId);
        return(
            <Modal 
                title="Floor Map" 
                visible={true} 
                width={700}
                onCancel = {()=>onCloseFloorModal()}
                footer={null}
                style={{
                    borderRadius: 10,
                    overflow: 'hidden'
                }}
        >
            <div style={{overflow: isMobile ? 'scroll' : ''}}>
                <Space className="grid-options--search">
                    <Select style={{ width: 180 }} value={floorSelectDateType} onChange={(e)=>setDateType(e)}>
                        <Select.Option value={TODAY}>Today</Select.Option>
                        <Select.Option value={TOMORROW}>Tomorrow</Select.Option>
                        <Select.Option value={CUSTOM}>Custom Date</Select.Option>
                    </Select>
                    {floorSelectDateType === CUSTOM &&
                        <DatePicker allowClear={false} value={moment(fromTime, DATE_FORMAT)}
                            onChange={setDate} style={{ width: 250 }} format={DATE_FORMAT} />
                    }
                    <TimePicker.RangePicker onChange={setTime} value={[moment(fromTime, TIME_FORMAT), moment(toTime, TIME_FORMAT)]} format={TIME_FORMAT} />
                </Space>

                <Row>
                    <Button loading={isLoading} disabled={false} shape="round" type="primary" className="db-available-button" onClick={()=>checkAvailability()}> Check Availability </Button>
                </Row>

                <Row style={{ marginBottom: 20, marginTop: 20 }}>
                    <Col className='db-map-icon' span={isMobile ? 2 : 1}>{greenPinIcon}</Col>
                    <Col className='db-map-legend' span={isMobile ? 5 : 3} offset={1}>Available</Col>
                    <Col className='db-map-icon' span={isMobile ? 2 : 1}>{grayPinIcon}</Col>
                    <Col className='db-map-legend' span={isMobile ? 5 : 4} offset={1}>Unavailable</Col>
                    <Col className='db-map-icon' span={isMobile ? 2 : 1}>{amberPinIcon}</Col>
                    <Col className='db-map-legend' span={isMobile ? 5 : 3} offset={1}>Booked</Col>
                    <Col className='db-map-icon' span={isMobile ? 2 : 1}>{redPinIcon}</Col>
                    <Col className='db-map-legend' span={isMobile ? 5 : 3} offset={1}>Occupied</Col>
                </Row>
                <div className='db-map-resize'>
                    <div className='db-floor-img-wrapper' id = 'db-desk-img' style={{width:'650px'}}>
                        <img className='db-desk-img' alt='' onLoad = {()=>setImgState(true)} src={locationFloor?.imageLink} width={650}/>
                        {isImgLoad && floorDesks.map((desk: any)=> {
                            let bookings = floorDeskBookings.filter(el=>el.deskId === desk.deskId);
                            let checkedInBookings = bookings.filter(el=> el.checkedStatus === BOOKING_CHECKED_STATUS.CHECKED_IN);
                            return(
                                <div className='db-desk-marker' onClick={()=>onEditDesk(desk)} style={{top:desk.location.top, left:desk.location.left, cursor:'pointer'}}>
                                    <Tooltip title={desk.name}>{desk.deskStatus === DESK_STATUS.NOT_AVAILABLE.value ?
                                        grayLocationPinIcon : (bookings.length ? (checkedInBookings.length ? redLocationPinIcon : amberLocationPinIcon) : greenLocationPinIcon)}</Tooltip>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Modal>
        );
    };

    const columns: Array<any> = [
        {
            title: 'Floor',
            dataIndex: 'name',
            width: 200,
            sorter: (a: any, b: any) => stringSorter(a.name, b.name),
            render: (name: string, floor: object) => (
                <a onClick={() => displayMapModal(floor)} href = "#">{name}</a>
            )
        },
        {
            title: 'Building',
            dataIndex: 'buildingId',
            width: 250,
            render: (a: any, b: any) => (<div>{getBuildingName(a)}</div>),
        },
        {
            title: 'Associate Desks',
            dataIndex: 'floorId',
            render: (a: any, b: any) => (<div>{getDeskCount(a)}</div>),
        },
        {
            title: "",
            dataIndex: 'floorId',
            key: 'floorId',
            fixed: 'right',
            width: 150,
            render: (floorId: string, floor: any) => (
                <Row style={{flexFlow:'nowrap'}}>
                    <Button icon={editIcon} type='text' onClick={() => onEditFloorAction(floor)} />
                    <Popover trigger='hover' placement='left' content={
                        <div className='intnnt-ppvr'>
                            <Button className='intnnt-ppvr-btn' type='text' danger icon={deleteIcon} onClick={() => onDeleteDeskAction(floor)}>Delete floor</Button>
                            <Button type='text' icon={mapIcon} onClick={()=>displayMapModal(floor)}>Floor Map</Button>
                        </div>}>
                        <div className="ind-more"><MoreOutlined className="more-settings" /></div>
                    </Popover>
                </Row>
            )
        }
    ];

    const filterFloors = () => {
        let filtered;
        if (searchPhrase) {
            const searchingText = searchPhrase.toLowerCase();
            filtered = floorList.filter((i: any) => i.name.toLowerCase().includes(searchingText));
        } else {
            filtered = floorList;
        }
        if (selectedBuilding === ALL) return filtered;
        return filtered.filter((i: any) => i.buildingId === selectedBuilding);
    };

    useEffect(() => {
        if (floorList !== undefined) {
            setFilteredList(undefined);
            setFilteredList(filterFloors());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchPhrase, floorList, selectedBuilding]);

    return (
        <>
            {newDeskModalVisible && <FloorModal onClose={() => setNewDeskModalVisible(false)} onAdd={() => { loadFloors(); setNewDeskModalVisible(false); }} />}
            {editingFloor && <FloorModal floor={editingFloor} onClose={() => {setEditingFloor(undefined); loadFloors();}} onSave={() => { loadFloors(); setEditingFloor(undefined); }} />}
            {editingDesks && <BookingDeskModal deskList = {deskList} desk={editingDesks} floorList={floorList} onClose={() => {setEditingDesks(undefined); loadDesks();}} onSave={() => { loadDesks(); setEditingDesks(undefined); }} />}
            {showDeskLocation && deskMap()}
            <Content className="table-wrapper">
                <Table rowKey="floorId"
                    columns={columns}
                    dataSource={filteredList || []}
                    loading={filteredList === undefined}
                    pagination={{
                        total: filteredList?.length,
                        showSizeChanger: true,
                        showTotal: (total, range) => `showing ${range[0]}-${range[1]} out of ${total} floors`
                    }} />
            </Content>
        </>
    )
};
