import { Button, Row, Space, Upload, Col, Modal, Image as AntdImage } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { notifyConfirmation, confirm, notifiableAPICall, notifyError, notifySuccess } from '../../../utils/notification';
import { useState } from 'react';
import { DragUploadBox } from '../common/UploadBoxes';
import {checkFloorDeskAvailability, deleteFloor} from '../../actions';
import { getBase64 } from '../../../utils/img-utils';

export const FloorImageUploadView = ({ imageList, setImageList, btnDisable, onCreate, onCancel, floor, onSave }: any) => {
    const [onImageLoad, setOnImageLoad] = useState<boolean>(false);
    let isEditView = floor !== undefined;
    const [previewImg, setPreviewImg] = useState<any>();

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImg({ url: file.url || file.preview, name: file.name });
    };

    const onDeleteFloorAction = (floor: any) => {
        confirm("Delete Floor", `Are you sure you want to delete this floor (${floor.name})?`,
            async () => {
                let { success, hasDesks } = await notifiableAPICall(async () => await deleteFloor(floor.buildingId, floor.floorId),
                    "delete-floor",
                    "Deleting floor...",
                    undefined,
                    "Something went wrong in deleting floor. Please retry."
                );
                if (success) {
                    notifySuccess('Floor deleted successfully');
                    onCancel();
                }
                else if (hasDesks) notifyError("Cannot delete floor because desks are already assigned and bookings may exist");
            }, () => { });
    }

    const onImageRemove = async () => {
        if(!floor) {
            setImageList([]);
            return;
        }
        const { hasDesks } = await checkFloorDeskAvailability(floor.buildingId, floor.floorId);
        if (hasDesks) {
            notifyConfirmation('Deleting this floor plan is possible but existing desk positions will become invalid.', 'Continue', () => setImageList([]))
        } else {
            setImageList([]);
        }
    }

    return (
        <Space direction='vertical' className='intnnt-spc img-upload' style={{ height: 450 }}>
            <Row>
                <p className='intnnt-sbttl'>Upload floor plan</p>
                {onImageLoad && <Button onClick={() => onImageRemove()} icon={<DeleteOutlined color='#E83034' />} type='text' style={{ color: '#E83034' }} >Remove</Button>}
            </Row>
            <Row>
                {!imageList.length && 
                    <Upload
                        listType={imageList.length > 0 ? "picture-card" : undefined}
                        fileList={imageList}
                        onPreview={handlePreview}
                        accept="image/*"
                        beforeUpload={(file: any) => {
                            getBase64(file).then(base64 => { file.url = base64; setImageList([...imageList, file]) });
                            return false;
                        }}
                        onRemove={(file: any) => { setImageList(imageList.filter((item: any) => item.uid !== file.uid)); return true; }}
                    >
                        {imageList.length === 0 && <DragUploadBox />}
                    </Upload> }
                {imageList[0] && imageList[0].url &&
                    <div style={{ textAlign: 'center' }} className='db-floor-resize'>
                        <AntdImage alt="preview" style={{ width: '100%', padding: 15 }} src={imageList[0].url} onLoad={() => setOnImageLoad(true)} />
                    </div>
                }
                {previewImg &&
                    <Modal visible={true} title={previewImg.name} footer={null} onCancel={() => setPreviewImg(undefined)} >
                        <img alt="preview" style={{ width: '100%', padding: 15 }} src={previewImg.url} />
                    </Modal>
                }
            </Row>
            <Row className='intnnt-btn-rw'>
                <Col span={8} style={{ alignSelf: 'center' }}>
                    <Button
                        type='text'
                        icon={<DeleteOutlined className='intnnt-btn-dngr' />}
                        className='link-btn link-btn--danger'
                        disabled={btnDisable}
                        onClick={() => isEditView ? onDeleteFloorAction(floor) : notifyConfirmation('Continuing this action will discard your changes', 'Discard', onCancel)}
                    >
                        {isEditView ? 'Delete' : 'Discard'}
                    </Button>
                </Col>
                <Col span={12} pull={4} style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                    {isEditView ?
                        <Button
                            className='btn btn--o-primary'
                            onClick={() => notifyConfirmation('Continuing this action will discard your changes', 'Discard', onCancel)}
                            disabled={btnDisable}
                        >
                            Cancel
                        </Button>
                        : null
                    }
                    <Button style={{ paddingLeft: 10, paddingRight: 10 }} className='btn btn--primary' onClick={isEditView ? onSave : onCreate} disabled={btnDisable} >
                        {isEditView ? 'Save changes' : 'Create new floor'}
                    </Button>
                </Col>
            </Row>
        </Space>
    );
}
