import { useContext } from 'react';
import { Form, FormInstance, Radio, Select } from 'antd';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { NEWS_CATEGORIES_ARRAY } from './constants';
import { getBuildingTenants } from '../utils/common-utils';

const { Option } = Select;

type PropsType = {
    isPublish: boolean;
    isEditing: boolean;
    buildingId?: string;
    form: FormInstance;
}

export const NewsFormView = ({ buildingId, isPublish, isEditing, form }: PropsType) => {
    const { state } = useContext(sessionStore);
    const buildings: any = state.get(SESSION_ATTRS.BUILDINGS);
    const tenants: any = getBuildingTenants(state.get(SESSION_ATTRS.TENANTS), buildingId || buildings[0].buildingId);

    return (
        <div className='intnnt-spc intnnt-spc-wdth'>
            <Form.Item name='categories' label='Category' rules={[{ required: isPublish, message: 'Please select a category!' }]}>
                <Select placeholder='Select a category' className='intnnt-npt' >
                    {NEWS_CATEGORIES_ARRAY.map(category => <Option key={category.value} value={category.value}>{category.name}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item
                initialValue={1}
                hidden //temparory hidden
                name='template'
                label='Template' //change required beacuse hidden
                rules={[{ required: false, message: 'Please select a template' }]}>
                <Radio.Group>
                    <Radio value={1}>Template 1</Radio>
                    <Radio value={2}>Template 2</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name='buildingId' label='Access' rules={[{ required: true, message: 'Please select a building!' }]} >
                <Select placeholder='Select a building' className='intnnt-npt' disabled={isEditing} >
                    {buildings.map((building: any) => {
                        return (
                            <Option key={building.buildingId} value={building.buildingId}>
                                <div>
                                    <p className='intnnt-bldng-nm'>{building.name}</p>
                                    <p className='intnnt-bldng-lctn'>{building.geolocation}</p>
                                </div>
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <Form.Item name='tenants' label='Tenant' rules={[{ required: isPublish, message: 'Please select companies!' }]} >
                <Select
                    showSearch
                    className='intnnt-npt'
                    // disabled={!buildingId}
                    mode='multiple'
                    style={{ width: '100%' }}
                    options={[{ label: 'All Tenants', value: 'all' }, ...tenants.map((tenant: any) => { return { label: tenant.name, value: tenant.tenantId } })]}
                    placeholder='Select companies'
                    maxTagCount='responsive'
                    onSelect={(val: any) => val === 'all' ? form.setFieldsValue({ tenants: tenants.map(({ tenantId }: any) => tenantId) }) : undefined}
                />
            </Form.Item>
        </div>
    );
}
