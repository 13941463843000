import { Modal, Row, Col, Spin, Form } from 'antd';
import { IssueFormView } from './IssueFormView';
import { ImageUploadView } from './ImageUploadView';
import { useState } from 'react';
import { ulid } from 'ulid';
import { notifiableAPICall } from '../../utils/notification';
import { addIssue, uploadImage } from '../actions';

export const IssueModal = ({ onClose, statusList, onAdd, categories }: any) => {
    const [thumbnailImg, setThumbnailImg] = useState<any>();
    const [issueImg, setIssueImg] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();

    const onCreateIssue = async () => {
        try {
            let issue = await form.validateFields();
            issue.categoryName = categories.find((cat: any) => cat.categoryId === issue.categoryId).categoryName
            setIsLoading(true);
            issue.issueId = ulid();
            if (issueImg) {
                issue.thumbnailFileName = await notifiableAPICall(async () => await uploadImage(thumbnailImg, issue.issueId, true),
                    'thumb-upload',
                    'Uploading thumbnail...',
                    'Thumbnail uploaded successfully',
                    'something went wrong!'
                );
                const imageId = await await notifiableAPICall(async () => await uploadImage(issueImg.file, issue.issueId, false),
                    'img-upload',
                    'Uploading image...',
                    'Image uploaded successfully',
                    'something went wrong!'
                );
                issue.imageIds = [imageId];
            } else {
                issue.imageIds = [];
            }
            let { success } = await notifiableAPICall(async () => await addIssue(issue),
                'add-issue',
                'Adding issue...',
                'Issue added successfully',
                'something went wrong!'
            );
            if (success) {
                onAdd();
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    }

    return (
        <Modal
            visible={true}
            onCancel={onClose}
            closable={false}
            footer={null}
            width={890}
            bodyStyle={{
                padding: 0
            }}
            style={{
                borderRadius: 10,
                overflow: 'hidden'
            }}
            className='intnnt-mdl bs-add-space-mdl'
            centered
            maskClosable={false}
        >
            <Spin spinning={isLoading} size={'large'}>
                <Row className='intnnt-rw'>
                    <Col className='intnnt-clmn intnnt-clmn-left'>
                        <IssueFormView form={form} categories={categories} />
                    </Col>
                    <Col className='intnnt-clmn intnnt-clmn-right intnnt-drk-10'>
                        <ImageUploadView
                            thumbnailImg={thumbnailImg}
                            setThumbnailImg={setThumbnailImg}
                            issueImg={issueImg}
                            setIssueImg={setIssueImg}
                            onCreate={onCreateIssue}
                            onCancel={onClose}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
};