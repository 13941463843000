import { useState } from "react";
import { Row, Button, Card, Modal, DatePicker } from 'antd';
import moment from "moment";

const { RangePicker } = DatePicker;

const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}
const disabledDateTime = () => {
    return {
        disabledMinutes: () => [...range(1, 30), ...range(31, 60)],
        disabledSeconds: () => [55, 56],
    };
}

export const DateRangePopup = ({ title, okButtontext, onCancel, onConfirm, from, to }: any) => {
    const [range, setRange] = useState<any>([from, to]);
    const isValidTime = () => {
        if (!range || !range[0].isValid() || !range[1].isValid() || range[0].minute % 30 !== 0 || range[1].minute % 30 !== 0 || range[0] > range[1] || range[0] < moment()) {
            return false;
        }
        return true;
    }
    return (
        <Modal
            visible={true}
            footer={null}
            onCancel={onCancel}
            closable={false}
            width={500}
            centered
            maskStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
            maskClosable={false}
        >
            <Card bordered={false} bodyStyle={{ paddingBottom: 0 }}>
                <Row className='bs-dcln-rw'>
                    <h2 className='bs-dcln-mdl-ttl'>{title}</h2>
                </Row>
                <Row>
                    <RangePicker defaultValue={range} onChange={setRange} style={{ margin: 'auto' }} showTime format="YYYY-MM-DD hh:mm A" disabledTime={disabledDateTime} disabledDate={current => current < moment()} />
                </Row>
                <Row className='bs-dcln-btn-rw'>
                    <div>
                        <Button className='btn btn--o-primary' onClick={onCancel}>Cancel</Button>
                        <Button className='btn btn--primary' onClick={async () => await onConfirm(range[0], range[1])} disabled={isValidTime()}>{okButtontext}</Button>
                    </div>
                </Row>
            </Card>
        </Modal>
    )
}