import { useEffect, useState, useContext } from 'react';
import { Layout, Row, Space, Input, Button, Typography, Card, Checkbox, Upload, Image, Form, DatePicker, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';
import 'react-quill/dist/quill.snow.css';

import { addNewPost, getNewsSignedUrl, getSingleNews, downloadHtml, uploadImage } from './actions';
import { S3_METHODS, uploadFile } from '../utils/api-utils';
import { getBase64, isValidImage, resizeImageFile, THUMBNAIL_ASPECT_RATIO, THUMBNAIL_MAX_HEIGHT, THUMBNAIL_MAX_WIDTH } from '../utils/img-utils'
import { DATE_TIME_DISPLAY_FORMAT } from '../utils/date-time'
import { notifiableAPICall, notifyConfirmation, notifyError, notifyWarning } from '../utils/notification';
import { sessionStore, SESSION_ATTRS } from '../store/session';
import { extractContent, getUserObj } from '../utils/common-utils';
import Icon from '../components/Icon';
import { HtmlEditor } from './HtmlEditor';
import { NewsFormView } from './NewsFormView';
import ImgCrop from 'antd-img-crop';
import { ulid } from 'ulid';
import { NEWS_FEEDS_TYPES } from './constants';

const { Content, Sider } = Layout;
const { Title, Text } = Typography;
const { Dragger } = Upload;

type Props = {
    newsId?: string,
    buildingId?: string,
}

export const NewPost = ({ newsId, buildingId }: Props) => {
    console.log({ newsId, buildingId })
    let isEditView = newsId !== undefined;

    const [form] = Form.useForm();
    let history = useHistory();
    const [newsItem, setNewsItem] = useState<any>();
    const [newsBody, setNewsBody] = useState<string>();
    const [isValidResolution, setIsValidResolution] = useState(true);
    const [isPublish, setIsPublish] = useState(true);
    const [featureImg, setFeatureImg] = useState<any>();
    const [featureThumb, setFeatureThumb] = useState<any>();
    const [isScheduled, setIsScheduled] = useState(false);

    const { state } = useContext(sessionStore);
    const currentUser: any = state.get(SESSION_ATTRS.LOGGED_USER);

    useEffect(() => {
        if (buildingId && newsId) getSingleNews(buildingId, newsId).then(news => {
            setNewsItem({ ...news, scheduledTime: moment(news.scheduledTime), categories: news.categories?.length ? news.categories[0] : undefined });
            setIsScheduled(news.isScheduled);
            downloadHtml(news.newsContentKey).then(setNewsBody);
            if (news.featuredImageKey) {
                getNewsSignedUrl(news.featuredImageKey, undefined, S3_METHODS.GET, 1800).then(url => setFeatureImg({ url }));
            }
        });
    }, [buildingId, newsId]);

    if (isEditView && (newsItem === undefined || newsBody === undefined)) {
        return <div className="loading-wrapper"><Spin tip="Loading..." /></div>
    }

    const onSave = (isDraft: boolean) => {
        setIsPublish(!isDraft);
        form.validateFields()
            .then(values => {
                if (!isDraft) {
                    if (!newsBody) throw new Error("News Content Empty!");
                    if (!featureImg) throw new Error("Featured Image Empty!");
                }
                if (((isEditView && !newsItem.isPublished) || !isEditView) && isScheduled && moment(values.scheduledTime) < moment()) {
                    throw new Error("schedule time exceeded! please select again.");
                }
                saveNews(isDraft);
            })
            .catch((err) => {
                console.log(err)
                notifyError(err.errorFields ? "Some mandatory fields are empty!" : err.message);
            })
    }

    const saveNews = async (isDraft: boolean) => {
        let newsRecord: any = { newsId: newsId || ulid() };
        newsRecord.newsContentKey = await uploadNewsBody(newsRecord.newsId);
        if (featureImg.file) {
            newsRecord.featuredImageKey = await notifiableAPICall(async () => await uploadImage(featureImg.file, newsRecord.newsId, false),
                'feature-upload',
                'Uploading featured image...',
                'Featured image uploaded successfully',
                'something went wrong!'
            );
            newsRecord.thumbnailImageKey = await notifiableAPICall(async () => await uploadImage(featureThumb, newsRecord.newsId, true),
                'thumb-upload',
                'Uploading thumbnail...',
                'Thumbnail uploaded successfully',
                'something went wrong!'
            );
        } else {
            newsRecord.featuredImageKey = newsItem.featuredImageKey;
            newsRecord.thumbnailImageKey = newsItem.thumbnailImageKey;
        }
        await saveNewsRecord(newsRecord, isDraft);
        history.goBack();
    }

    const uploadNewsBody = async (newsId: string) => {
        let contentkey = newsId + '/content.html';
        const url = await getNewsSignedUrl(contentkey, 'text/html', S3_METHODS.PUT, 900);
        await notifiableAPICall(() => uploadFile(url, newsBody, 'text/html'),
            'content_uploading',
            'Please wait...',
            'Content uploaded!',
            'Something went wrong!',
            true
        );
        return contentkey;
    }

    const saveNewsRecord = async (newsRec: any, draft: boolean) => {// derive the shortDescription
        let { title, categories, buildingId, tenants, scheduledTime, isScheduled } = form.getFieldsValue();
        let newsRecord = {
            ...newsRec, title, categories: [categories], buildingId, tenants, scheduledTime, isScheduled,
            state: draft ? NEWS_FEEDS_TYPES.DRAFT : NEWS_FEEDS_TYPES.PUBLISH,
            shortDescription: extractContent(newsBody, 150),
            isPublished: isEditView ? newsItem.isPublished : !isScheduled,
            createdBy: isEditView ? newsItem.createdBy : getUserObj(currentUser),
            createdAt: isEditView ? newsItem.createdAt : undefined,
            updatedBy: isEditView ? getUserObj(currentUser) : undefined,
            isPublishable: !!newsBody && !!newsRec.featuredImageKey && !!categories && !!tenants
        }
        // save the post in db
        const successMessage = !isEditView ? "New News Post added successfully." : "News Post updated successfully.";
        await notifiableAPICall(() => addNewPost(newsRecord), 'add_news_post', 'Please wait!', successMessage, 'Something went wrong!', true);
    }

    return (
        <Form form={form} initialValues={isEditView ? newsItem : undefined} requiredMark={false} layout={'vertical'}>
            <Space direction='vertical' size='large'>
                <Title level={4} style={{ marginTop: 20 }}>
                    {isEditView ? 'Edit Post' : 'New Post'}
                    <Button icon={<DeleteOutlined color='#E83034' />} type='text' style={{ paddingRight: 10, color: '#E83034', float: 'right' }} onClick={() => notifyConfirmation('Continuing this action will discard your data', 'Discard', () => history.goBack())}>Discard</Button>
                </Title>
                <Layout className="new-post">
                    <Content>
                        <Form.Item name='title' rules={[{ required: true, message: 'News Title Empty!' }]}>
                            <Input placeholder='Enter title here' />
                        </Form.Item>
                        <HtmlEditor content={newsBody} setContent={setNewsBody} />
                    </Content>
                    <Sider style={{ background: 'none' }} >
                        <Card size="small" title="Publish" >
                            <Row style={{ flexDirection: "column" }}>
                                <Form.Item name="isScheduled" valuePropName="checked" style={{ margin: 'unset' }}>
                                    <Checkbox disabled={isEditView} onChange={e => setIsScheduled(e.target.checked)}>Schedule</Checkbox>
                                </Form.Item>
                            </Row>
                            {isScheduled && <Row>
                                <Text type="secondary" style={{ margin: '0.5rem 0', display: 'block' }}>Select a time and date in the future for your post to be published.</Text>
                                <Form.Item name='scheduledTime' rules={[{ required: true, message: 'Need to select schedule time!' }]}>
                                    <DatePicker disabled={isEditView && newsItem?.isPublished} showNow={false} format={DATE_TIME_DISPLAY_FORMAT} showTime use12Hours showSecond={false} style={{ marginTop: 10, marginBottom: 10 }} />
                                </Form.Item>
                            </Row>
                            }
                            <Row className="row-controls">
                                <Button className="link-btn link-btn--default" onClick={() => onSave(true)}>Save Draft</Button>
                                <div>
                                    <Button className="btn btn--o-primary" disabled>Preview</Button>
                                    <Button className="btn btn--primary" onClick={() => onSave(false)}>Publish</Button>
                                </div>
                            </Row>
                        </Card>
                        <Card size="small" title="Information">
                            <NewsFormView isEditing={isEditView} isPublish={isPublish} buildingId={buildingId} form={form} />
                        </Card>
                        <Card size="small" title="Featured Image" >
                            {!featureImg && <ImgCrop aspect={THUMBNAIL_ASPECT_RATIO} quality={1} beforeCrop={file => isValidImage(file, setIsValidResolution)}>
                                <Dragger
                                    name='file'
                                    multiple={false}
                                    beforeUpload={(file: File) => {
                                        if (!isValidResolution) {
                                            notifyWarning("Minimum resolution allowed is 640x480!");
                                        } else {
                                            getBase64(file).then(base64 => setFeatureImg({ file, url: base64 }));
                                            resizeImageFile(file, THUMBNAIL_MAX_WIDTH, THUMBNAIL_MAX_HEIGHT).then(newFile => setFeatureThumb(newFile));
                                        }
                                        return false;
                                    }}
                                    showUploadList={false}
                                    className='thumb-upload'
                                    accept="image/*"
                                >
                                    <p className="ant-upload-drag-icon">
                                        <Icon icon="upload-image" width="36px" height="36px" />
                                    </p>
                                    <p className="ant-upload-text">Upload Image</p>
                                </Dragger>
                            </ImgCrop>}
                            {featureImg && <>
                                <Image width={'100%'} src={featureImg.url} />
                                <div>
                                    <Button onClick={() => setFeatureImg(undefined)} icon={<DeleteOutlined color='#E83034' />} type='text' style={{ paddingRight: 0, color: '#E83034' }} >Remove</Button>
                                </div>
                            </>}
                        </Card>
                    </Sider>
                </Layout>
            </Space>
        </Form>
    )
    // }
};
